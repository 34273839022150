import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling
import "./VraScheduling.css";
import getAllVraShiftAPIS from "apis/panel/vraShifts/vraShifts.js";
import upload from "../../../../assets/images/rpHomePage/upload.gif";
import Context from "context/context";
import leftarrow from "assets/images/arrow-right.png";
// import { Box } from '@mui/material';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import vraShiftsAPI from 'apis/panel/vraShifts/vraShifts.js';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import "./VraScheduling.css"


import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import * as moment from "moment";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import "react-toastify/dist/ReactToastify.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Modal from "@mui/material/Modal";


const AddAffinity= () => {
  const navigate= useNavigate()
  const { buildAffinityAPI  } =vraShiftsAPI();
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
  const { state, dispatch } = useContext(Context);
  const [open, setOpen] = useState(false);



  const handleNavigation=()=>{
    navigate("/panel/settings")
  }


  const handleOpen = () => {
    setOpen(true);
};


const handleClose=()=>{
  setOpen(false);
}

  const handleBuildAffinity = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      const response = await buildAffinityAPI();
      dispatch({ type: "SET_LOADING", payload: false });
  
      if (response?.Status == 200) {
        handleClose()
        toast.success(response?.detail)
      } else {
        handleClose()
        // Handle failure
        toast.error(response?.detail);
      }
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.error("Error Building:", err);
      toast.error("Error Building Affinity.");
    }
  };
  

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <div onClick={handleNavigation} style={{ cursor: 'pointer', paddingRight: 8 }}>
        <img src={leftarrow} alt="Back" />
      </div>
      <Box sx={{ pt: 2, pb: 2 }} className="VraShiftsTitle">
      Build RAD VRA Affinity
      </Box>
    </div>
      
          {/* <Grid container spacing={4} sx={{ mt: 2, pl:4,pr:4 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            Start Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
            //   maxDate={exportObj?.endDate}
              value={exportObj?.startDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, startDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>  

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            End Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              sx={{ width: "100%" }}
              format = "dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={exportObj?.startDate}
              value={exportObj?.endDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, endDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>

          </Grid> */}

      <Grid container sx={{pr:4}}>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>
          <Button
            className="download-btn"
            onClick={handleOpen}
          >
            Click to Build RAD VRA Affinity
          </Button>
        </Grid>
      </Grid>


      <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ bgcolor: 'white', p: 4, borderRadius: 2, boxShadow: 24, width: 400, margin: 'auto', mt: '10%' }}>
                    <Typography className="warningheadvraAffinity" variant="h6"> <WarningAmberIcon/> Warning</Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure VRA Shift Report Data, VRA Roster and RAD Roster are up to date?
                    </Typography>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                        <Button   className="cancelButton"  onClick={handleClose}>Cancel</Button>
                        <Button   className="saveButton"  onClick={handleBuildAffinity}>Confirm</Button>
                    </Box>
                </Box>
            </Modal>
    </div>
  );
};

export default AddAffinity;

import React, { useState, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling
// import "./ImportSrf.css";
import getAllVraShiftAPIS from "apis/panel/vraShifts/vraShifts.js";
import upload from "../../../../assets/images/rpHomePage/upload.gif";
import Context from "context/context";
// import { Box } from '@mui/material';
import leftarrow from "assets/images/arrow-right.png";
import { useNavigate } from "react-router-dom";
import { Box } from '@mui/material';
// import Context from "context/context";
import reportAPI from 'apis/panel/reports/reports';
import Modal from '@mui/material/Modal';
import Tables from "components/table/table";
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: "100%",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  width: "60vw",
  // p: 4,
  borderRadius: "10px",
  maxHeight: "90vh",
  overflowY: "scroll",
  // overflowX: "scroll"
};



const ImportQagenda = () => {
  const navigate= useNavigate()
  const { fileUploadQgendaAPI } = getAllVraShiftAPIS();
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
  const { state, dispatch } = useContext(Context);
  const [radScheduleData, setRadScheduleData]= useState([]);
  const [open , setOpen] = useState(false)
  const [offset, setOffset] = useState(1);
  const [initial,setInitial]= useState(true)
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [missingData , setMissingData]= useState([])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleFileUpload(acceptedFiles[0]);
      } else {
        toast.error('Only Excel files are allowed.');
      }
    },
  });

  const handleFileUpload = async (file) => {
    const maxSize = 1024 * 1024 * 5; // 5MB
    if (file.size > maxSize) {
      // Show error message if file size exceeds the limit
      toast.error("File size exceeds the limit (5MB)", {
        toastId: "file-upload-maximum",
      });
      return;
    }

    
    try {
      dispatch({ type: "SET_LOADING", payload: true });

      const response= await fileUploadQgendaAPI(file)

      dispatch({ type: "SET_LOADING", payload: false });

      if (response?.status==200) {
        toast.success(response?.detail);
        setMissingData(response?.missing_rad_names)
        // console.log(missingData,"missingData")
        setUploadProgress(0); 
        setOpen(true)
        return response.detail;
      }else{
        toast.error(response?.detail);
        setUploadProgress(0); 
        return;
      }

      
      
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      
      console.error('Error uploading file:', err);
      toast.error('Error uploading file.');
    }
  };

  const handleClose = () => {
    setOpen(false);
  }
  
  const handleNavigation=()=>{
    navigate("/panel/rpHomePage")
  }


  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
  <div onClick={handleNavigation} style={{ cursor: 'pointer', paddingRight: 8 }}>
    <img src={leftarrow} alt="Back" />
  </div>
  <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">
  Import Qgenda Report
  </Box>
</div>
      {/* <Box sx={{ pt: 2, pb: 2, pl: 2 }} className="VraShiftsTitle">Import Qgenda Report</Box> */}
      <div className="containerforupload">
        <motion.div
          className="upload-area"
          {...getRootProps()}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <input {...getInputProps()} />
          <div className="instructions">
            {isDragActive ? (
              <img style={{ width: "40%" }} src={upload} alt="Upload indicator" />
            ) : (
              <>
                <img style={{ width: "40%" }} src={upload} alt="Upload indicator" />
                <p style={{ color: "lightgrey" }}>Drag and drop your Excel files here</p>
              </>
            )}
          </div>
        </motion.div>
        
        {/* Display progress bar */}
        {uploadProgress > 0 && (
          <div className="progress-container">
            <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>
              {uploadProgress}%
            </div>
          </div>
        )}
        <ToastContainer /> {/* Render ToastContainer to display notifications */}
      </div>

      
{missingData.length>0 ?
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
     
      


          <Tables
        title={ 
          <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">Missing RAD Names</Box>
        }
        // searchBar={true}
        // filter={roleCode === "R001"}
        setSearchValue={setSearchValue}
        create={    <IconButton
            onClick={handleClose}
            // sx={{  top: 3, right: 3 }}
          >
            <CloseIcon style={{ border: "2px solid grey", borderRadius: "5px" }} />
          </IconButton>}

        heading={[
       
          "S.No",
          // "Missing VRA'S",
          "Missing RAD Names"
         

        ]}
        data={missingData?.map((e, index) => [
          (offset - 1) * 20 + index + 1, // S.No
          e
          // missingData.missing_rad_names.length > 0 ? missingData.missing_rad_names[index] :""
       
         ])}
         offset={offset}
         setOffset={setOffset}
         count={count}
         placeholder="Search by  Name"
         limit={20}
        //  placeholder="Search by ID, VRA Name"
      />
    
        </Box>
      </Modal>
      : ""
        }
    </div>
  );
};

export default ImportQagenda;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import leftarrow from "assets/images/arrow-right.png";
import Tables from "components/table/table";
import Context from "context/context";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import 'react-calendar/dist/Calendar.css';
import * as moment from "moment"
import vraSubstitutionsAPIS from 'apis/panel/vraShifts/vraShifts'
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";


const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});


const VraSubstitutionHistory= () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode] = useState(state.role.roleCode);
  const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [substitutionsData,setSubstitutionsData]= useState([])
  const [count, setCount] = useState(0);
  const [emailSendToString,setEmailSendToString] = useState('')
  const [emailArray,setEmailArray]= useState([])
  const [searchValue, setSearchValue] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [open, setOpen] = useState(false);

  const {getAllVrasubstitutionsAPI} = vraSubstitutionsAPIS()

  
  

  const handleNavigation=()=>{
    navigate("/panel/vraSubstitutions")
  }
 
  useEffect(()=>{
    handleGetVraSubstitutions()
  },[])

  const handleGetVraSubstitutions= async ()=>{
    try {
       const res=await getAllVrasubstitutionsAPI()
       setSubstitutionsData(res?.data?.vraSubstitutions)
        setCount(res?.data?.totalCount)
        setEmailSendToString(res?.data?.vraSubstitutions?.email_send_to)
        console.log(res?.data?.vraSubstitutions?.email_send_to ,"sendto");
    } catch (error) {
        console.log(error);
        
    }
  }
  
  
useEffect(()=>{
  handleEmailArray()
},[emailSendToString])
console.log(emailSendToString,"email")

const handleEmailArray = () => {
  const cleanedString = emailSendToString?.replace(/"/g, ''); // Remove double quotes
  const emailArrayList = cleanedString?.split('++')
    .filter(email => email.trim() !== '')
    .map(email => email.replace(/[{}]/g, '').trim())
    .map(email => email.startsWith(',') ? email.slice(1).trim() : email);
  setEmailArray(emailArrayList);
};


console.log(emailArray);


  return (
    <div
      className="vraShiftsMediaQuery"
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      
      <Tables
  title={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div onClick={handleNavigation} style={{ cursor: 'pointer', paddingRight: 8 }}>
        <img src={leftarrow} alt="Back" />
      </div>
      <Box sx={{ pt: 2, pb: 2 }} className="VraShiftsTitle">
        VRA Substitution History
      </Box>
    </div>
  }
  searchBar={false}
  setSearchValue={setSearchValue}
  heading={[
    "S.No",
    "Substitution Date",
    "Actual VRA",
    "Substitute VRA",
    "RAD Associated",
    "Emails Send To",
    "Email Body"
  ]}
  data={substitutionsData?.map((e, index) => [
    (offset - 1) * 20 + index + 1,
    e.substitution_date ? moment(e.substitution_date).format("DD-MMM-YYYY") : e.substitution_date,
    e.actual_srf_name,
    e.substitute_srf_name,
    e.rad_srf_name,
    emailArray?.length > 0 ? (
      <ul  style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        {emailArray.map((email, index) => (
          <li key={index}  style={{ padding: '4px 0' }}>{email.trim()}</li>
        ))}
      </ul>
    ) : (
      <span>No emails</span>
    ),
    <CustomWidthTooltip
    title={
      <span 
        dangerouslySetInnerHTML={{ __html: e.email_body.replace(/\\n/g, '<br />') }} 
      />
    }
  >
    <RemoveRedEyeIcon style={{ color: "#FE6024" }} />
  </CustomWidthTooltip>
  ])}
  offset={offset}
  setOffset={setOffset}
  limit={20}
  count={count}
  placeholder="Search by VRA Name"
/>


    </div>
  )
}
export default VraSubstitutionHistory
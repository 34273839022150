import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";
import { toast } from "react-toastify";
import * as moment from "moment";

const vraShiftsAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllVraDetailsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/employees?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function updateVraShiftAPI(value) {
    try {
      const obj = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/vra/employee-shifts`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllRadiologistsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/radiologists`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllShiftsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/shifts`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getEditVraShiftDetailsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/employee-shifts?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getVraEmployeeFindone(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/vra/employee?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  // training

  async function getAllVraTrainingDetailsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/employee-trainings?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getAllVraEmployeesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/employees/master`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function CreateVraTrainingDetailsAPI(values) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values)
      };

      const res = await fetch(
        `${config.host}/panel/vra/employee-training`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getVraTrainingfindone(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/vra/employee-training?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function UpdateVRATrainingDta(value) {
    try {
      const obj = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/vra/employee-training`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  // import srf

  async function fileUploadQgendaAPI(file) {
    try {

      const formData = new FormData();
      formData.append("file", file);
      const obj = {
        method: "Post",
        credentials: "include",
        body: formData,
      };
      let res = await fetch(`${config.host}/panel/vra/upload-Qganda-files`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function fileUploadSrfAPI(file) {
    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append("file", file);

      // Configure the request
      const obj = {
        method: "POST", // HTTP method should be capitalized
        credentials: "include",
        body: formData, // Use FormData as the body
      };

      // Make the API request
      const res = await fetch(`${config.host}/panel/vra/upload-SRF-files`, obj);
      return await handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  // Reports
  async function getRPAllowanceReportAPI(body) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(`${config.host}/panel/vra/report/allowances`, obj);
      if (res.ok) {
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const json = await res.json();
          if (json.message === "No data found.") {
            toast.error("No data found", { toastId: "no-data-error" });
            return;
          }
        } else {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `RP Allowance Report ${moment(body.startDate).format("DD-MM-YYYY")} to ${moment(body.endDate).format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          toast.success("File Downloaded Successfully", {
            toastId: "file-success",
          });
          return;
        }
      } else {
        toast.error("File Not Found", { toastId: "file-error" });
        return;
      }
    } catch (err) {
      handleError(err);
    }
  }


  async function getRPAllowanceReportSummaryAPI(body) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(`${config.host}/panel/vra/report/allowances-summary`, obj);

      if (res.ok) {
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const json = await res.json();
          if (json.message === "No data found.") {
            toast.error("No data found", { toastId: "no-data-error" });
            return;
          }
        } else {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `RP Allowance Summary Report ${moment(body.startDate).format("DD-MM-YYYY")} to ${moment(body.endDate).format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          toast.success("File Downloaded Successfully", {
            toastId: "file-success",
          });
          return;
        }
      } else {
        toast.error("File Not Found", { toastId: "file-error" });
        return;
      }
    } catch (err) {
      handleError(err);
    }
  }


  // VRA substitutions

  async function getAllVRAAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/all-vra-details`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }
  async function vraSchedulingAPI(values) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };
      // const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/vra-scheduling`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function buildAffinityAPI(values) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(values),
      };
      // const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/build-affinity`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function deleteTableAPI(values) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/delete-radAffinity`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  //   return{
  //     getEditVraShiftDetailsAPI,
        
  //       updateVraShiftAPI,
  //       getAllShiftsAPI,
  //       getAllRadiologistsAPI,
  //       getAllVraDetailsAPI,
  //       getVraEmployeeFindone,

  //       getAllVraTrainingDetailsAPI,
  //       getAllVraEmployeesAPI,
  //       CreateVraTrainingDetailsAPI,
  //       getVraTrainingfindone,
  //       UpdateVRATrainingDta,

  //       fileUploadQgendaAPI,
  //       fileUploadSrfAPI,
        
  //   }
  // }

  async function getAllRADAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/all-rad-details`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAssociateRadAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };

      // Use URLSearchParams to format query parameters correctly
      const query = new URLSearchParams(values).toString();

      const res = await fetch(
        `${config.host}/panel/vra/scheduled-rad?${query}`,
        obj
      );

      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function createVraSubtitutionAPI(values) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values)
      };

      const res = await fetch(
        `${config.host}/panel/vra/vra-substitution`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }



  // Email Template Config

  async function getEmailTemplateAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await fetch(
        `${config.host}/panel/vra/emailTemplate`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function editEmailTemplateAPI(values) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values)
      };

      const res = await fetch(
        `${config.host}/panel/vra/editEmailTemplate`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  // VRA substitution History

  async function getAllVrasubstitutionsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/vra/all-vra-substitutions`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }



  return {
    getEditVraShiftDetailsAPI,

    updateVraShiftAPI,
    getAllShiftsAPI,
    getAllRadiologistsAPI,
    getAllVraDetailsAPI,
    getVraEmployeeFindone,

    getAllVraTrainingDetailsAPI,
    getAllVraEmployeesAPI,
    CreateVraTrainingDetailsAPI,
    getVraTrainingfindone,
    UpdateVRATrainingDta,

    fileUploadQgendaAPI,
    fileUploadSrfAPI,

    getRPAllowanceReportAPI,
    getRPAllowanceReportSummaryAPI,

    getAllVRAAPI,
    getAllRADAPI,
    getAssociateRadAPI,
    createVraSubtitutionAPI,

    getEmailTemplateAPI,
    editEmailTemplateAPI,

    getAllVrasubstitutionsAPI,

    getRPAllowanceReportAPI,
        getRPAllowanceReportSummaryAPI,
        vraSchedulingAPI,
        buildAffinityAPI,
        deleteTableAPI
  }
}
export default vraShiftsAPI;
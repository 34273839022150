import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// css

// mui
import Tables from "components/table/table";
import getAllVraShiftAPIS from "apis/panel/vraShifts/vraShifts.js";
import Context from "context/context";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Margin, Padding } from "../../../../../node_modules/@mui/icons-material/index";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import check from "../../../../assets/images/check.png";
import cross from "../../../../assets/images/remove.png";
import leftarrow from "assets/images/arrow-right.png";
import Autocomplete from '@mui/material/Autocomplete';



// react calendar
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Calendar from 'react-calendar'

const style = {
  position: 'absolute',
  top: '60%',
  left: '60%',
  transform: 'translate(-60%, -60%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
//   maxHeight: "90vh",
  overflowY: "scroll",
};

const TrainingSetup= () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode] = useState(state.role.roleCode);
  const navigate = useNavigate();
  const { getEditVraShiftDetailsAPI, getAllVraTrainingDetailsAPI, getVraTrainingfindone, UpdateVRATrainingDta} = getAllVraShiftAPIS();
  const [empDetails, setEmpDetails] = useState([]);
  const [shiftOptions, setShiftOptions]= useState([]);
  const [radOptions, setRadOptions]= useState([]);
  const [primaryRadiologist, setPrimaryRadiologist] = useState('');
  const [shiftValue , setShiftValue] = useState('')
  const [vraShiftDetails, setVraShiftDetails] = useState([])
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [vraShiftDetailsEditObj, setVraShiftDetailsEditObj] = useState([]);
  const [serialNumber, setSerialNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [findOneData, setFindoneData]= useState([])
  const [employeeCode, setEmployeeCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [status, setStatus] = useState("");
  const [batch, setBatch] = useState("");
  const [trainingStartDate, setTrainingStartDate] = useState("");
  const [trainingEndDate, setTrainingEndDate] = useState("");
  const [certificationDate, setCertificationDate] = useState("");
  const [isTrainingCompleted, setIsTrainingCompleted] = useState(false);

  const [ employeeTrainingId,setEmployeeTrainingId]= useState("")
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datesArray, setDatesArray] = useState([]);
  const handleNavigation=()=>{
    navigate("/panel/rpHomePage")
  }

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlefindonee = async (vraEmployeeTrainingId) => {
    setOpen(true)
    let values;
    values = {
       vraEmployeeTrainingId
    };
  
    setEmployeeTrainingId(vraEmployeeTrainingId)
    try {
        dispatch({ type: "SET_LOADING", payload: true });
        let details = await getVraTrainingfindone(values);
        console.log(details?.data)
       const fetcheddata=  details?.data?.employeeTrainingDetails[0] || {};
        setFindoneData(fetcheddata)
        console.log(findOneData,"findone")
         setEmployeeCode(fetcheddata.employeeCode || "");
         console.log(employeeCode, "emp")
      setFirstName(fetcheddata.firstName || "");
      setLastName(fetcheddata.lastName || "");
      setDateOfJoining(fetcheddata.dateOfJoining || "");
      let ActiveStatus=  fetcheddata.isActive == true ? 'Active' : "InActive"
      setStatus(ActiveStatus || "");
      setBatch(fetcheddata.batch || null);
      setTrainingStartDate(fetcheddata.trainingStartDate || "");
      setTrainingEndDate(fetcheddata.trainingEndDate || "");
      setCertificationDate(fetcheddata.certificationDate || "");
      setIsTrainingCompleted(fetcheddata.isTrainingCompleted || false);
      dispatch({ type: "SET_LOADING", payload: false });
    }
    catch (err) {
        dispatch({ type: "SET_LOADING", payload: false });
        
    }
};
   
const handleStatusChange = (event) => {

  setStatus(event.target.value)
}
  const handleNavigate = () => {
    navigate("/panel/addTraining")
  }
  const handleGetAllVraTrainingDetailsApi = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        offset: (offset - 1) * 20,
        limit: 20,
      };
      if (searchValue) {
        values = {
            ...values,
          searchValue,
        };
      }
      if (filterValue) {
        values = {
            ...values,
          filterValue,
        };
      }
      let res = await getAllVraTrainingDetailsAPI(values);

      setVraShiftDetails(res?.data?.employeeTrainings || []);
      setCount(res?.data?.count);
      setSerialNumber(offset)
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const handleSave= async()=>{
        try {
          dispatch({ type: "SET_LOADING", payload: true });
          let value;
          value = { 
      vraEmployeeTrainingId: employeeTrainingId,
      isActive: status == "Active" ? true : false,
      batch:batch,
      trainingStartDate:trainingStartDate,
      trainingEndDate:trainingEndDate,
      certificationDate:certificationDate,
          };
          let res = await UpdateVRATrainingDta(value);
          handleGetAllVraTrainingDetailsApi()
         
          dispatch({ type: "SET_LOADING", payload: false });
          setOpen(false)
        } catch (err) {
          dispatch({ type: "SET_LOADING", payload: false });
        }
      };

  useEffect(() => {
    handleGetAllVraTrainingDetailsApi()

  }, [offset, searchValue, filterValue])
  return (
    <div
      className="vraShiftsMediaQuery"
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Tables
        title={
          <Grid item xs={12} sm={12} md={12} lg={12}>      
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <div onClick={handleNavigation} style={{ cursor: 'pointer'}}>
                <img src={leftarrow} alt="Back" />
              </div>
          
            <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">Training setup</Box>
            </div>
            </Grid>
        }
        searchBar={true}
        filter={roleCode === "R001"}
           role={roleCode}
           create={
            //  (roleCode === "R001") &&
             <Button className="AddEmployerButton" onClick={handleNavigate}>
                Add Trainee
             </Button>
           }
        setSearchValue={setSearchValue}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterValues={[
          { label: "Employee Id", value: "" },
          { label: "VRA Name", value: "employeeName" },
          //  { label: "Employee Email", value: "employeeEmail" },
        ]}
        heading={[
          "S.No",
          "Employee Id",
          "First Name",
          "Last Name",
          "Training Start Date",
          "Training End Date",
          "Certification Date",
          "Batch",
          "status",
          "Certified",
          "Action"
        ]}
        data={vraShiftDetails?.map((e, index) => [
          index + 1 + (offset - 1) * 20,
              e.employeeCode,
              e.firstName,
              e.lastName,
           e.trainingStartDate? moment(e.trainingStartDate).format("DD-MMM-YYYY") : "",
           e.trainingEndDate? moment(e.trainingEndDate).format("DD-MMM-YYYY") :"",
            e.certificationDate?  moment(e.certificationDate).format("DD-MMM-YYYY"):"",
              e.batch,
            //   e.engagementType,
            e?.isActive === true ? (
                <img style={{height: "20px"}} src={check} alt=""/>
            ) : (
                <img  style={{height: "20px"}} src={cross} alt=""/>
            ),
            e.certificationDate? "True": "False",
                // <EditIcon style={{color:"#FE6024"}}  onClick={() => handleEdit(e.employeeId)}/>,
          <RemoveRedEyeIcon style={{color:"#FE6024"}} onClick={() => handlefindonee(e.vraEmployeeTrainingId)}/>,
         ])}
         offset={offset}
         setOffset={setOffset}
         count={count}
         limit={20}
         placeholder="Search by Employee ID"
      />

 <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>

        <Grid container className="editsetupmaincont" sx={{ pl: 3, pr: 3, mt: 2, mb: 1 }}>
        <Box className="addnewprojecthead">Training Setup Details</Box>
        <Box>
          {/* {!isEditMode &&
            <Button className="projsetupedit" onClick={toggleEditMode}>
              Edit
            </Button>
          } */}
        </Box>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 3, mt: 2 }} >
        {/* {isEditMode ? ( */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="label">Employee Id <span style={{ color: "red" }}>*</span></Box>
          <TextField
            id="employeeCode"
            placeholder="Enter the Employee Id"
            variant="outlined"
            disabled={true}
            fullWidth
            value={employeeCode}
            onChange={(e) => setEmployeeCode(e.target.value)}
          />
        </Grid>
      {/* // ) : (
      //   <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: "20px" }}>
      //     <Box className="editlabel">Employee Id</Box>
      //     <Box className="editvalue">{employeeCode}</Box>
      //   </Grid>
      // )} */}
  <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* {isEditMode ? ( */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Training Start Date <span style={{ color: "red" }}>*</span>
            </Box>
            <DatePicker
              sx={{width:"100%"}}
               value={trainingStartDate ? new Date(trainingStartDate) : null}
              onChange={(newValue) => setTrainingStartDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
        {/* ) : (
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: "20px" }}>
            <Box className="editlabel">Training Start Date</Box>
            <Box className="editvalue">{moment(trainingStartDate).format("DD-MM-YYYY")}</Box>
          </Grid>
        )} */}
{/* 
        {isEditMode ? ( */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Training End Date <span style={{ color: "red" }}>*</span>
            </Box>
            <DatePicker
            sx={{width:"100%"}}
            value={trainingEndDate ? new Date(trainingEndDate) : null}
              onChange={(newValue) => setTrainingEndDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
        {/* ) : (
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: "20px" }}>
            <Box className="editlabel">Training End Date</Box>
            <Box className="editvalue">{moment(trainingEndDate).format("DD-MM-YYYY")}</Box>
          </Grid>
        )} */}

        {/* {isEditMode ? ( */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Certification Date <span style={{ color: "red" }}>*</span>
            </Box>
            <DatePicker
              sx={{width:"100%"}}
              value={certificationDate ? new Date(certificationDate) : null}
              onChange={(newValue) => setCertificationDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
        {/* ) : (
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: "20px" }}>
            <Box className="editlabel">Certification Date</Box>
            <Box className="editvalue">{moment(certificationDate).format("DD-MM-YYYY")}</Box>
          </Grid>
        )} */}
      </LocalizationProvider>

{/* 
{isEditMode ? ( */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Batch <span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="projectCode"
              placeholder="Enter the Batch"
              variant="outlined"
            //   disabled={true}
              fullWidth 
              value={batch}
              onChange={(e) => setBatch(e.target.value)}
            //   onChange={handleprojectcodeChange}
            />
            {/* {errorFields.projectid && <span className="error-msg">This is required Field</span>} */}
          </Grid>
        {/* ) : (
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: "20px" }}>
            <Box className="editlabel">Batch</Box>
            <Box className="editvalue">{batch}</Box>
          </Grid>
        )} */}


{/* {isEditMode ? ( */}
<Grid item xs={12} sm={6} md={6} lg={6} >
                    <Box className="label">Status <span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>
                    
                        <Select
                            labelId="status"
                            id="status"


                            defaultValue=""
                            displayEmpty


                            name="status"
                            value={status}
                            onChange={handleStatusChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{ border: "2px solid #B2D4F8", color: "grey" }}
                        >
                            <MenuItem value="">
                                Select Status
                            </MenuItem>

                            <MenuItem value={'Active'}>Active</MenuItem>
                            {/* <MenuItem value={'Temporarily'}>Temporarily</MenuItem> */}
                            <MenuItem value={'InActive'}>In Active</MenuItem>




                        </Select>
                    </FormControl>
                    {/* <TextField sx={{ border: '1px solid #B2D4F8' }}id="role" value={Role}  name="role"  onChange={handleRoleChange} placeholder="Role" variant="outlined" fullWidth /> */}
                    {/* {errorFields.status && <span className="error-msg">This is required Field</span>} */}
                </Grid>
        {/* ) : (
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: "20px" }}>
            <Box className="editlabel">Status</Box>
            <Box className="editvalue">{status}</Box>
          </Grid>
        )} */}

{/* 
{isEditMode ? ( */}
          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Certified<span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="projectCode"
              placeholder="Certified Status"
              variant="outlined"
     
              fullWidth 
              value={isTrainingCompleted}
              onChange={(e) => setIsTrainingCompleted(e.target.value)}
    
            />
      
          </Grid> */}
        {/* ) : (
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: "20px" }}>
            <Box className="editlabel">Is Completed</Box>
            <Box className="editvalue">{isTrainingCompleted}</Box>
          </Grid>
        )} */}
      </Grid>

      {/* {isEditMode ? ( */}
      <Grid container spacing={2} sx={{mt:2}}>
        <Grid item xs={6} sm={6} lg={6} md={6} className="cancelButGridtrai"> <Button onClick={()=>setOpen(false)} className="cancelButtonForEdittraining">Cancel</Button></Grid>
        <Grid item xs={6} sm={6} lg={6} md={6}> <Button className="saveButtonForEdittraining" onClick={handleSave}> Save</Button></Grid>
      </Grid>
      {/* ):null} */}


        </Box>
      </Modal>

      
    </div>
  )
}


export default TrainingSetup
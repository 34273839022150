import React, { useState, useEffect } from 'react';
import "./AddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid"
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import personalInfo from "assets/images/color (1).png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PostInsurance from 'apis/panel/Insurance/insurance'
import getEmpRel from 'apis/panel/Insurance/insurance'
import getRel from 'apis/panel/Insurance/insurance'
import getAllGenderdrop from "apis/panel/AddEmployee/addEmployee";

const InsuranceInfo = () => {
  const [insuranceInfo, setInsuranceInfo] = useState([{ id: 1 }])
  const [InsuranceArr, setInsuranceArr] = useState([{ id: 1 }])
  const [EmployeeId] = useState(sessionStorage.getItem('EmployeeID'))
  const [EmployeeCode] = useState(sessionStorage.getItem('EmployeeCode'))
  const [RelationsData, setRelationsData] = useState([])
  const [genderOptions, setGenderOptions] = useState([]);
  const { getAllGenderAPI } = getAllGenderdrop();
  const { PostInsuranceApi } = PostInsurance();
  const { getEmployeeRelations } = getEmpRel();
  const { getRelations } = getRel();

  console.log(EmployeeCode,"empcode")

  const [showDateOfJoining, setShowDateOfJoining] = useState(false);



  useEffect(() => {
    findrelations()
  }, [])

  const selectedData = (e,id) => {
    let body = {
      relID: e,
      empID: EmployeeId
    }
    findEmpRelations(body,id)

    const selectedRelationship = RelationsData.find(rel => rel.relationshipId === e);
    setShowDateOfJoining(selectedRelationship?.relationshipName === "self");
    
  }
  const findEmpRelations = async (e,id) => {
    const data = await getEmployeeRelations(e)
    const updatedarr = InsuranceArr.map((p) =>
      p.id == id ? {...p,
        insuredName:data?.data?.firstName ,
        relationshipId:data.data.relationshipId
      }
      : p
    )
    setInsuranceArr(updatedarr)
  }

  const findrelations = async () => {
    const data = await getRelations(EmployeeId)
    setRelationsData(data.data)
  }

  const handleAddSection = () => {
    setInsuranceInfo(prevState => [...prevState, { id: prevState.length + 1 }]);
    setInsuranceArr(prev => [...prev, { id: prev.length + 1 }])
  };

  const handleRemoveSection = (indexToRemove) => {
    setInsuranceInfo(prevState => prevState.filter((_, index) => index !== indexToRemove));
  };

  const handleGetAllGendersApi = async () => {
    try {
      let res = await getAllGenderAPI();
      setGenderOptions(res?.data || []);
    }
    catch (err) { }
  };

  useEffect(() => {
    handleGetAllGendersApi()
  }, [])

  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleCancel = () => {
    setAccordionOpen(false); 
  };

  const [errors, setErrors] = useState({});
  const handleClick = async () => {
    const validationErrors = {};
    if (!InsuranceArr.every(item => item.relationshipId)) {
      validationErrors.relationshipId = true;
    }
    if (!InsuranceArr.every(item => item.insuredName)) {
      validationErrors.insuredName = true;
    }
    if (!InsuranceArr.every(item => item.dateOfBirth)) {
      validationErrors.dateOfBirth = true;
    }
    if (!InsuranceArr.every(item => item.dateOfJoining)) {
      validationErrors.dateOfJoining = true;
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      let body = {
        employeeId: EmployeeId,
        insuranceData: InsuranceArr
      }
      const data = await PostInsuranceApi(body)
      if (data.status === 'Ok') {
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
      } 
      else {
        return toast.error("Error saving Data", { toast: "Error saving Data" });
      }
    }
  }

  const maxDate = new Date();
  return (
    <Accordion expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)} sx={{ mt: 2, boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
      >
        <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" /></Box>
        <Box className="acc-title" sx={{ pl: 3 }}>Insurance Information</Box>

      </AccordionSummary>
      <AccordionDetails>
        {insuranceInfo.map((section, index) => (
          <>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} style={{ display: "flex", justifyContent: "space-between" }} >
              <Box className="subHeadingforinfos" sx={{ pl: 3 }}>Insurance Info {index + 1}</Box>
              {index > 0 ? (
                <Button className="removeSectionButton" onClick={() => handleRemoveSection(index)}>Remove Section</Button>
              ):(
                <Button className="addSectionButton" onClick={handleAddSection}>Add Section</Button>
              )}
            </Grid>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" >Relationship <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    placeholder="Select Date"
                    defaultValue=""
                    displayEmpty
                    onChange={(e) => {
                      selectedData(e.target.value, section.id)                      
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  >
                    <MenuItem value="">
                      Select Relationship
                    </MenuItem>
                    {
                      RelationsData?.map((i) => (
                        <MenuItem value={i.relationshipId}>{i.relationshipName}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {errors.relationshipId && <div className='error-message'>This field is mandatory.</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" >Employee id <span style={{ color: "red" }}>*</span></Box>
                <TextField id="idproof"
                  placeholder="Id Proof"
                  value={EmployeeCode}
                  disabled={true}
                  onChange={(e) => {
                    const updatedArr = InsuranceArr.map((p) =>
                      p.id == section.id
                        ? {
                          ...p,
                          IdProof: e.target.value
                        }
                        : p
                    );
                    setInsuranceArr(updatedArr);
                  }}
                  variant="outlined"
                  fullWidth />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" >Name of the insured<span style={{ color: "red" }}>*</span></Box>
                <TextField 
                  id="firstName"
                  name='firstName'
                  value={InsuranceArr[index].insuredName}
                  placeholder="Enter Name of insured"
                  variant="outlined" fullWidth 
                />
                {errors.insuredName && <div className='error-message'>This field is mandatory.</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" > Date of birth <span style={{ color: "red" }}>*</span></Box>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{width:"100%"}}
                    onChange={(e) => {
                      const updatedArr = InsuranceArr.map((p) =>
                        p.id == section.id
                          ? {
                            ...p,
                            dateOfBirth: e
                          }
                          : p
                      );
                      setInsuranceArr(updatedArr);
                    }}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    maxDate={maxDate}
                    fullWidth
                  />
                </LocalizationProvider>
                {errors.dateOfBirth && <div className='error-message'>This field is mandatory.</div>}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Gender<span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    // placeholder="Select Date"
                    defaultValue=""
                    onChange={(e) => {
                      const updatedArr = InsuranceArr.map((p) =>
                        p.id == section.id
                          ? {
                            ...p,
                            genderId: e.target.value
                          }
                          : p
                      );
                      setInsuranceArr(updatedArr);
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  >
                    <MenuItem value="">
                      Select Gender
                    </MenuItem>
                    {genderOptions.map((option) => (
                      <MenuItem key={option.id} value={option.genderId}>{option.genderName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.gender && <div className='error-message'>This field is mandatory.</div>}
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Date of Joining<span style={{ color: "red" }}>*</span></Box>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <DatePicker 
                    sx={{width:"100%"}} fullWidth
                    onChange={(e) => {
                      const updatedArr = InsuranceArr.map((p) =>
                        p.id == section.id
                          ? {
                            ...p,
                            dateOfJoining: e
                          }
                          : p
                      );
                      setInsuranceArr(updatedArr);
                    }}
                    slotProps={{
                      field: { clearable: true },
                    }}
                      maxDate={maxDate}
                  />
                </LocalizationProvider>
                {errors.dateOfJoining && <div className='error-message'>This field is mandatory.</div>}
              </Grid> */}
            </Grid>
          </>
        ))}
        <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button className="resetChanges">Reset Changes</Button>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button onClick={handleClick} className="saveButton">Save</Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>

  )
}

export default InsuranceInfo
import {React, useContext,useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import * as moment from "moment";

import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import "react-toastify/dist/ReactToastify.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Context from "context/context";
import reportAPI from 'apis/panel/reports/reports';
import Modal from '@mui/material/Modal';
import Tables from "components/table/table";

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    width: "90vw",
    
    borderRadius: "10px",
    maxHeight: "90vh",
    overflowY: "scroll",
   
  };

const EmployeeInfoReport = ({ setDisableDropdown }) => {
    const { dispatch } = useContext(Context);
    const [radScheduleData, setRadScheduleData]= useState([]);
    const [open , setOpen] = useState(false)
    const [offset, setOffset] = useState(1);
    const [initial,setInitial]= useState(true)
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const { getEmployeeInfoReportAPI , getEmployeeInfoReportViewAPI} = reportAPI();



      const handleClose = () => {
        setOpen(false);
      }

 useEffect(()=>{

  if(!initial){
    handleView()
  }
  
 },[offset,searchValue])

      const handleView = async () => {

      
        try {
          dispatch({ type: "SET_LOADING", payload: true });
         let values = {
            offset: (offset - 1) * 20,
            limit: 20,
          };
          if (searchValue) {
            values = {
                ...values,
              searchValue,
            };
          }
          let res = await getEmployeeInfoReportViewAPI(values);
          setCount(res?.data?.count)
          setRadScheduleData(res?.data?.data)
          setInitial(false)
          dispatch({ type: "SET_LOADING", payload: false });
          setOpen(true)
        } catch (err) {
          dispatch({ type: "SET_LOADING", payload: false });
          setOpen(false)
        }
      };

      const handleExport = async () => {
        dispatch({ type: "SET_LOADING", payload: true });
        setDisableDropdown(true);
        await getEmployeeInfoReportAPI();
        setDisableDropdown(false);
        dispatch({ type: "SET_LOADING", payload: false });
      };
    
  return (
    <div>
         
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>

        <Button
            className="download-btn"
            sx={{marginRight:'10px'}}
            startIcon={
              <RemoveRedEyeOutlinedIcon
                style={{ fontSize: 30, verticalAlign: "middle" ,pl:'2px'}}
              />
            }
            onClick={handleView}
          >
            View
          </Button>
          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
              />
            }
            onClick={handleExport}
          >
            Export
          </Button>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Tables
        title={
          <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">Employee Information Report View</Box>
        }
        searchBar={true}
        // filter={roleCode === "R001"}
        setSearchValue={setSearchValue}
        create={  <IconButton
          onClick={handleClose}
        >
          <CloseIcon style={{ border: "2px solid grey", borderRadius: "5px" }} />
        </IconButton>
}
        // filterValue={filterValue}
        // setFilterValue={setFilterValue}
        // filterValues={[
        //   { label: "Employee Id", value: "" },
        //   { label: "VRA Name", value: "employeeName" },
        // ]}
        heading={[
       
          "S.No",
          "Employee Code",
          "Title",
          "First Name",
          "Middle Name",
          "Last Name",
          "Father Name",
          "Mother Name",
          "Marital Status",
          "Spouse Name",
          "Business Email",
          "Personal Email",
          "Business Phone Number",
          "Personal Phone Number",
          "DOB",
          "Gender",
          "Blood Group",
          "Current House No",
          "Current Street",
          "Current Country",
          "Current State",
          "Current City",
          "Current Postal Code",
          "Permanent House No",
          "Permanent Street",
          "Permanent Country",
          "Permanent State",
          "Permanent City",
          "Permanent Postal Code",



       
        ]}
        data={radScheduleData?.map((e, index) => [
         (offset-1)*20 +index+1,
         e.employeeCode,
         e.title,
         e.FirstName,
         e.MiddleName,
         e.LastName,
       e?.FatherName ,
       e?.MotherName ,
       e?.MaritalStatus ,
       e?.SpouseName ,
       e?.BusinessMail,
       e?.PersonalEmail ,
       e?.BusinessPhoneNumber ,
       e?.PersonalPhoneNumber ,
       e?.DateOfBirth? moment(e.DateOfBirth).format("DD-MMM-YYYY"): "",
       e?.Gender ,
       e?.BloodGroup ,
       e?.CurrentHouseNo ,
       e?.CurrentStreet ,
       e?.CurrentCountry ,
       e?.CurrentState ,
       e?.CurrentCity ,
       e?.CurrentPostalCode ,
       e?.PermanentHouseNo ,
       e?.PermanentStreet ,
       e?.PermanentCountry ,
       e?.PermanentState ,
       e?.PermanentCity ,
       e?.PermanentPostalCode 
      
         ])}
         offset={offset}
         setOffset={setOffset}
         count={count}
         limit={20}
         placeholder="Search by Employee code"
      />
    
        </Box>
      </Modal>
    </div>
  )
}

export default EmployeeInfoReport
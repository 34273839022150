import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// css

// mui
import Tables from "components/table/table";
import { toast } from "react-toastify";
import getAllVraShiftAPIS from "apis/panel/vraShifts/vraShifts.js";
import Context from "context/context";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Margin, Padding } from "../../../../../node_modules/@mui/icons-material/index";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import check from "../../../../assets/images/check.png";
import cross from "../../../../assets/images/remove.png";
import leftarrow from "assets/images/arrow-right.png";
import Autocomplete from '@mui/material/Autocomplete';



// react calendar
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Calendar from 'react-calendar'

const style = {
  position: 'absolute',
  top: '60%',
  left: '60%',
  transform: 'translate(-60%, -60%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
//   maxHeight: "90vh",
  overflowY: "scroll",
};

const AddTrainee = () => {
    const { state, dispatch } = useContext(Context);
    const [roleCode] = useState(state.role.roleCode);
    const navigate = useNavigate();
    const { CreateVraTrainingDetailsAPI, getAllVraEmployeesAPI} = getAllVraShiftAPIS();
    const [empDetails, setEmpDetails] = useState([]);
    const [shiftOptions, setShiftOptions]= useState([]);
    const [radOptions, setRadOptions]= useState([]);
    const [primaryRadiologist, setPrimaryRadiologist] = useState('');
    const [shiftValue , setShiftValue] = useState('')
    const [vraShiftDetails, setVraShiftDetails] = useState([])
    const [offset, setOffset] = useState(1);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [vraShiftDetailsEditObj, setVraShiftDetailsEditObj] = useState([]);
    const [serialNumber, setSerialNumber] = useState("");
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [findOneData, setFindoneData]= useState([])
  
  
    const [employeeCode, setEmployeeCode] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfJoining, setDateOfJoining] = useState("");
    const [status, setStatus] = useState("");
    const [batch, setBatch] = useState("");
    const [trainingStartDate, setTrainingStartDate] = useState(new Date());
    const [trainingEndDate, setTrainingEndDate] = useState(new Date());
    const [certificationDate, setCertificationDate] = useState(new Date());
    const [isTrainingCompleted, setIsTrainingCompleted] = useState(false);

    const [vraEmployees, setVraEmployees]= useState([])
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(""); 
  
    const [ employeeTrainingId,setEmployeeTrainingId]= useState("")
    
  
  
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [datesArray, setDatesArray] = useState([]);
  
    const toggleEditMode = () => {
      setIsEditMode(!isEditMode);
    };

    const handleNavigation=()=>{
      navigate("/panel/trainingsetup")
    }
    
       
    const handleStatusChange = (event) => {

      setStatus(event.target.value)
  }

const handleGetAllVraEmployees = async () => {
    try {
        let res = await getAllVraEmployeesAPI();
        setVraEmployees(res?.data?.vraEmployeesMaster || []);
        console.log(vraEmployees,"vra")
        }
    catch (err) {}
  };

  useEffect(()=>{
    handleGetAllVraEmployees()
  },[])
  
  
  const handleClearData= async()=>{
    
    setStatus("")
    setBatch("")
    setTrainingStartDate(new Date())
    setTrainingEndDate(new Date())
    setCertificationDate(new Date())
    setIsTrainingCompleted(false)
    setSelectedEmployeeId("")
  }

    const handleSave= async()=>{
          try {
            if(selectedEmployeeId=='' || status=="" || batch=="" || trainingStartDate=="" || trainingEndDate=="" || certificationDate==""){
              return toast.error("Input fileds cannot be empty");
            }
            dispatch({ type: "SET_LOADING", payload: true });
            let value;
            value = { 
                vraEmployeeId:selectedEmployeeId,
                status: status,
                batch:batch,
                trainingStartDate:trainingStartDate,
                trainingEndDate:trainingEndDate,
                certificationDate:certificationDate,
            };
            
            
            let res = await CreateVraTrainingDetailsAPI(value)
            
            dispatch({ type: "SET_LOADING", payload: false });

            if(res.status=="Ok"){
              handleClearData()
              return toast.success("Trainee Added Successfully", { toast: "Trainee Added " });
             
            }else{
              return toast.error("Failed adding trainee");
            }
          
          } catch (err) {
            dispatch({ type: "SET_LOADING", payload: false });
          }
        };
  return (
    <div style={{backgroundColor:"white", borderRadius:"10px"}}>
     
         {/* <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"> */}
        {/* <Box sx={style}> */}
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{pt:1, pl:1}}>      
      <div style={{ display: 'flex', alignItems: 'center',gap: "1%" }}>
          <div onClick={handleNavigation} style={{ cursor: 'pointer'}}>
            <img src={leftarrow} alt="Back" />
          </div>
      
        <Box className="addnewprojecthead">Add Trainee</Box>
        </div>
        </Grid>

        <Grid container sx={{mt: 2, mb: 1,pl:4 }}>
       
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4,pr:5, mt: 2 }} >
      
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="label">Employee Id <span style={{ color: "red" }}>*</span></Box>
          {/* <Autocomplete
                         id="primary-radiologist-autocomplete"
                         getOptionLabel={(option) => option?.radUsername} 
                         options={vraEmployees}
                         value={vraEmployees.find(option => option.employeeCode === employeeCode) || null}
                         onChange={(event, newValue) => {
                           setEmployeeCode(newValue ? newValue.employeeCode : "");
                         }}
                            renderInput={(params) => (
                           <TextField
                             {...params}
                             fullWidth
                           />
                         )}
                       /> */}

         <Autocomplete
            id="primary-radiologist-autocomplete"
            options={vraEmployees}
            getOptionLabel={(option) => option.employeeCode || ""}
            renderInput={(params) => <TextField {...params} fullWidth />}
            isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
            onChange={(event, newValue) => {
              setSelectedEmployeeId(newValue ? newValue.employeeId : ""); // Store the employeeId when an option is selected
            }}
          />
{/* <Autocomplete
  id="primary-radiologist-autocomplete"
  options={vraEmployees}
  getOptionLabel={(option) => option.employeeCode || ""}
  value={vraEmployees.find(option => option.employeeId === selectedEmployeeId) || null} // Controlled value
  isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
  onChange={(event, newValue) => {
    setSelectedEmployeeId(newValue ? newValue.employeeId : ""); // Store the employeeId when an option is selected
  }}
  renderInput={(params) => <TextField {...params} fullWidth />}

/> */}
        </Grid>

        {/* <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="label">First Name <span style={{ color: "red" }}>*</span></Box>
          <TextField
            id="employeeCode"
            placeholder="Enter the Employee Id"
            variant="outlined"
            // disabled={true}
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>


        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="label">Last Name <span style={{ color: "red" }}>*</span></Box>
          <TextField
            id="employeeCode"
            placeholder="Enter the Employee Id"
            variant="outlined"
            // disabled={true}
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid> */}

  <LocalizationProvider dateAdapter={AdapterDateFns}>
      
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Training Start Date <span style={{ color: "red" }}>*</span>
            </Box>
            <DatePicker
              sx={{width:"100%"}}
              value={trainingStartDate}
              onChange={(newValue) => setTrainingStartDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Training End Date <span style={{ color: "red" }}>*</span>
            </Box>
            <DatePicker
              sx={{width:"100%"}}
              value={trainingEndDate}
              onChange={(newValue) => setTrainingEndDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
      
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Certification Date <span style={{ color: "red" }}>*</span>
            </Box>
            <DatePicker
              sx={{width:"100%"}}
              value={certificationDate}
              onChange={(newValue) => setCertificationDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
      
      </LocalizationProvider>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Batch <span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="projectCode"
              placeholder="Enter the Batch"
              variant="outlined"
            //   disabled={true}
              fullWidth 
              value={batch}
              onChange={(e) => setBatch(e.target.value)}
            //   onChange={handleprojectcodeChange}
            />
            {/* {errorFields.projectid && <span className="error-msg">This is required Field</span>} */}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label">Status <span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>
                    
                        <Select
                            labelId="status"
                            id="status"


                            defaultValue=""
                            displayEmpty


                            name="status"
                            value={status}
                            onChange={handleStatusChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{ border: "2px solid #B2D4F8", color: "grey" }}
                        >
                            <MenuItem value="">
                                Select Status
                            </MenuItem>

                            <MenuItem value={'Active'}>Active</MenuItem>
                            <MenuItem value={'InActive'}>In Active</MenuItem>
                         



                        </Select>
                    </FormControl>
             
                </Grid>
       

        
       
      </Grid>
      <Grid container spacing={2} sx={{mt:2, pb: 2}}>
        <Grid item xs={6} sm={6} lg={6} md={6} className="cancelButGridtrai"> <Button onClick={handleClearData} className="cancelButtonForEdittraining">Cancel</Button></Grid>
        <Grid item xs={6} sm={6} lg={6} md={6}> <Button className="saveButtonForEdittraining" onClick={handleSave}> Save</Button></Grid>
      </Grid>
        {/* </Box> */}
      {/* </Modal> */}

    </div>
  )
}

export default AddTrainee
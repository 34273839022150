import { toast } from "react-toastify";
import * as moment from "moment";

// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const reportsAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllManagerProjects() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/reports/timesheet-summary/projects`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllManagerEmployees() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/reports/timesheet-summary/employees`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getTimesheetSummaryReportAPI(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/reports/timesheet-summary`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `TimesheetSummary ${moment().format("YYYY-MM-DD h_mm_ss A")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }

  async function getTimesheetSummaryReportViewAPI(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/reports/timesheet-summary-view`,
        obj
      );
     
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getAllProjectNames() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/reports/weekly-report-projects`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllProjectTasks(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(
        `${config.host}/panel/reports/weekly-report-tasks?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getWeeklyReportAPI(body) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(`${config.host}/panel/reports/weekly-report`, obj);

      if (res.ok) {
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const json = await res.json();
          if (json.message === "No data found.") {
            toast.error("No data found", { toastId: "no-data-error" });
            return;
          }
        } else {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `WeeklyReport ${moment().format("YYYY-MM-DD h_mm_ss A")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          toast.success("File Downloaded Successfully", {
            toastId: "file-success",
          });
          return;
        }
      } else {
        toast.error("File Not Found", { toastId: "file-error" });
        return;
      }
    } catch (err) {
      handleError(err);
    }
  }

  async function getWeeklyReportView(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(
        `${config.host}/panel/reports/weekly-report-view?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


 


  /////////////////////// Non Reported hours summary report ////////////////

  async function getAllDepartmentsAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/reports/all-departments`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getNonReportedHoursSummaryReportAPI(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/reports/nonReported-hours-summary`,
        obj
      );
      if (res.ok) {
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const json = await res.json();
          if (json && json?.message) {
            toast.error(json.message, { toastId: "no-data-error" });
            return;
          }
        } else {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Non-Complaince Report  ${moment().format("YYYY-MM-DD h_mm_ss A")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          toast.success("File Downloaded Successfully", {
            toastId: "file-success",
          });
          return;
        }
      } else {
        toast.error("File Not Found", { toastId: "file-error" });
        return;
      }
    } catch (err) {
      handleError(err);
    }
  }



  

  /////////////////////// Vra Monthly Schedule Report ////////////////

  async function getVraMonthlySchedule(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/vra/report/vra-monthly-schedule`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `VRA Monthly Schedule Report ${moment(body.startDate).format("DD-MM-YYYY")} to ${moment(body.endDate).format("DD-MM-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }

  async function getVraMonthlyScheduleView(values) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };
      let res = await fetch(
     `${config.host}/panel/vra/report/vra-monthly-schedule-view`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getVraMonthlyScheduleSummaryView(values) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };
      let res = await fetch(
     `${config.host}/panel/vra/report/vra-monthly-schedule-forecast-summary-view`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getVraMonthlyScheduleSummaryExport(body) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/vra/report/vra-monthly-schedule-forecast-summary`,
           obj
         );

      if (res.ok) {
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const json = await res.json();
          if (json.message === "No data found.") {
            toast.error("No data found", { toastId: "no-data-error" });
            return;
          }
        } else {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `RP VRA Monthly Schedule Forecast Report ${moment().format("YYYY-MM-DD h_mm_ss A")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          toast.success("File Downloaded Successfully", {
            toastId: "file-success",
          });
          return;
        }
      } else {
        toast.error("File Not Found", { toastId: "file-error" });
        return;
      }
    } catch (err) {
      handleError(err);
    }
  }


  async function getRadScheduleForecast(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/vra/report/rad-schedule-forecast`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `RP RAD Schedule Forecast ${moment(body.startDate).format("DD-MM-YYYY")} to ${moment(body.endDate).format("DD-MM-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }


  async function getRadScheduleActual(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/vra/report/rad-schedule-actual`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `RP RAD Schedule Actual ${moment(body.startDate).format("DD-MM-YYYY")} to ${moment(body.endDate).format("DD-MM-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }

  async function getRadScheduleForecastView(values) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };
      let res = await fetch(
     `${config.host}/panel/vra/report/rad-schedule-forecast-view`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getRadScheduleActualView(values) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };
      let res = await fetch(
     `${config.host}/panel/vra/report/rad-schedule-actual-view`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getRadScheduleForecastArchive(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/vra/report/rad-schedule-forecast-archive`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `RP RAD Schedule Forecast Archive ${moment(body.startDate).format("DD-MM-YYYY")} to ${moment(body.endDate).format("DD-MM-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }

  async function getRadScheduleForecastArchiveView(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      };
      const query = JSON.stringify(values);
      let res = await fetch(
     `${config.host}/panel/vra/report/rad-schedule-forecast-archive-view?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getVraRadAffinity() {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      };
      let res = await fetch(
        `${config.host}/panel/vra/report/vra-rad-affinity`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `VRA RAD Affinity .xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }

  async function getVraRadAffinityView(value) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      };
      const query = JSON.stringify(value);
      let res = await fetch(
     `${config.host}/panel/vra/report/vra-rad-affinity-view?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getRadRosterAPI() {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      };
      let res = await fetch(
        `${config.host}/panel/rp/radRoster/report`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `RAD Roster .xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }

  async function RadReportViewAPI(values) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };
      let res = await fetch(
     `${config.host}/panel/rp/radRoster/report-view`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getVraRosterAPI(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/rp/vraRoster/report`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `VRA Roster.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }


  async function VraReportViewAPI(value) {
    console.log(value,"value")
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(
     `${config.host}/panel/rp/vraRoster/report-view`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getRPAllowanceReportViewAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      };
      const query = JSON.stringify(values);
      let res = await fetch(
     `${config.host}/panel/vra/report/allowances-view?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getRPAllowanceSummaryReportViewAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      };
      const query = JSON.stringify(values);
      let res = await fetch(
     `${config.host}/panel/vra/report/allowances-summary-view?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getRadScheduleForecastCalendar(body) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/vra/report/rad-schedule-forecast-calendar`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `RP RAD Schedule Forecast Calendar ${moment(body.startDate).format("DD-MM-YYYY")} to ${moment(body.endDate).format("DD-MM-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }

  async function getradScheduleForcastCalendarReportView(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      };
      const query = JSON.stringify(values);
      let res = await fetch(
     `${config.host}/panel/vra/report/rad-schedule-forecast-calendar-view?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getVraScheduleForecastCalendar(body) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        `${config.host}/panel/vra/report/vra-schedule-forecast-calendar`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `RP VRA Schedule Forecast Calendar ${moment(body.startDate).format("DD-MM-YYYY")} to ${moment(body.endDate).format("DD-MM-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();  
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }


  async function getVraScheduleForcastCalendarReportView(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      };
      const query = JSON.stringify(values);
      let res = await fetch(
     `${config.host}/panel/vra/report/vra-schedule-forecast-calendar-view?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }




  async function getEmployeeInfoReportAPI() {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      };
      let res = await fetch(
        `${config.host}/panel/reports/employeeInfo-report`,
        obj
      );
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Employee Information Report .xlsx`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.success("File Downloaded Successfully", {
          toastId: "file-success",
        });
        return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return;
    } catch (err) {
      handleError(err);
    }
  }

  async function getEmployeeInfoReportViewAPI(values) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
         body :JSON.stringify(values)
      };
      let res = await fetch(
     `${config.host}/panel/reports/employeeInfoReport-view`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getAllManagerProjects,
    getAllManagerEmployees,
    getTimesheetSummaryReportAPI,
    getTimesheetSummaryReportViewAPI,
    getAllProjectNames,
    getAllProjectTasks,
    getWeeklyReportAPI,
    getWeeklyReportView,
    
    getAllDepartmentsAPI,
    getNonReportedHoursSummaryReportAPI,
    getVraMonthlySchedule,
    getVraMonthlyScheduleView,
    getVraMonthlyScheduleSummaryView,
    getVraMonthlyScheduleSummaryExport,

    getRadScheduleForecast,
    getRadScheduleActual,
    getRadScheduleForecastView,
    getRadScheduleActualView,
    getRadRosterAPI,
    RadReportViewAPI,
    getVraRosterAPI,
    VraReportViewAPI,
    getRadScheduleForecastArchive,
    getRadScheduleForecastArchiveView,
    getVraRadAffinity,
    getVraRadAffinityView,

    getRPAllowanceReportViewAPI,
    getRPAllowanceSummaryReportViewAPI,
    getradScheduleForcastCalendarReportView,
    getRadScheduleForecastCalendar,
    getVraScheduleForecastCalendar,
    getVraScheduleForcastCalendarReportView,

    getEmployeeInfoReportAPI,
    getEmployeeInfoReportViewAPI
  };
};

export default reportsAPI;

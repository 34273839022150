import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import "./Project.css"
import Loader from "components/Loader";
import projectAPI from 'apis/panel/project/project'
import getEmpRel from 'apis/panel/Insurance/insurance'
import getRel from 'apis/panel/Insurance/insurance'
import ProjectOwnerTable from './ProjectOwnerTable';
import ShiftsTable from './ShiftsTable';
import TasksTable from './TasksTable';

const EditNewProject = () => {
  const navigate = useNavigate();
  const [loader2, setLoader2] = useState(false)
  const { getprojectfindone, getAllProjectstypeAPI, getprojectupadte} = projectAPI();
  
  let params = useParams();
  const projectId = params.id;
  const [isEditMode, setIsEditMode] = useState(false);
  const [projectcode, setprojectcode] = useState('');
  const [projectname, setprojectename] = useState('');
  const [accountname, setaccountname] = useState('');
  const [projecttypeid, setprojecttypeid] = useState('');
  const [projecttypename, setprojecttypename] = useState('');
  const [startdate, setstartdate] = useState(null);
  const [enddate, setendend] = useState(null);
  const [status, setstatus] = useState('');
  const [description, setdescription] = useState('');
  const [projecttypeoption, setprojecttypeoption] = useState([]);
  
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handlestartAccept = (date) => {
    setstartdate(moment(date).format("MM/DD/YYYY"));
  }

  const handleendAccept = (date) => {
    if (date === null) {
        setendend(null);
    }
    else{
      setendend(moment(date).format("MM/DD/YYYY"));

    }
    
    // Do not update the enddate state if the date is null
}

  const handleprojectnameChange = (e) => {
    setprojectename(e.target.value);
  };

  const handleaccountChange = (e) => {
    setaccountname(e.target.value);
  };

  const handlestatusChange = (e) => {
    setstatus(e.target.value);
  };

  const handledescriptionChange = (e) => {
    setdescription(e.target.value);
  };

  const handleprojectcodeChange = (e) => {
    setprojectcode(e.target.value);
  };

  const handleNavigate = (e) => {
    navigate("/panel/project");
  };

  useEffect(() => {
    handlefindonee(projectId)
  }, [])

  const handlefindonee = async (e) => {
    setLoader2(true)
    const data = await getprojectfindone(projectId);
    setLoader2(false);
    setprojectcode(data?.data?.projectCode);
    setprojectename(data?.data?.projectName);
    setaccountname(data?.data?.accountName);
    setstartdate(data?.data?.startDate);
    setendend(data?.data?.endDate);
    setstatus(data?.data?.status);
    setprojecttypeid(data?.data?.projectTypes?.projectTypeId);
    setprojecttypename(data?.data?.projectTypes?.projectTypeName);
    setdescription(data?.data?.projectDescription);
  }

  const [errorFields, setErrorFields] = useState({
    projectid: false,
    projectname: false,
    accountname: false,
    projecttype: false,
    startdate: false,
    status: false
  });

  const handleGetAllprojectype = async () => {
    try {
      let res = await getAllProjectstypeAPI();
      setprojecttypeoption(res?.data || []);
    }
    catch (err) { }
  };

  useEffect(() => {
    handleGetAllprojectype();
  }, [])

  const handleprojecttype = (e) => {
    const selectedprojecttype = projecttypeoption.find(option => option.projectTypeId === e.target.value);
    setprojecttypename(selectedprojecttype.projectTypeName);
    setprojecttypeid(selectedprojecttype.projectTypeId);
  };

  const handleprojectupdate = async (e) => {
    setLoader2(true)
    e.preventDefault();
    const errors = {};
    if (!projectcode) errors.projectid = true;
    if (!projectname) errors.projectname = true;
    if (!accountname) errors.accountname = true;
    if (!projecttypeid) errors.projecttype = true;
    if (!startdate) errors.startdate = true;
    if (!status) errors.status = true;
    setErrorFields(errors);
    if (Object.keys(errors).length === 0) {
      const values = {
        projectId: projectId,
        projectTypeId: projecttypeid,
        projectName: projectname,
        projectCode: projectcode,
        accountName: accountname,
        projectDescription: description,
        startDate: startdate,
        endDate: enddate,
        status: status
      };
      const data = await getprojectupadte(values);
      if (data.status === 'Ok') {
        navigate("/panel/project");
        setLoader2(false);
        return toast.success("Record updated successfully.", { toast: "Record updated successfully." });
      } 
      else {
        setLoader2(false);
        return toast.error("Error updating Data", { toast: "Error updating Data" });
      }
    }
    else {
      setLoader2(false);
      return toast.error("Error saving Data", { toast: "Error saving Data" });
    }
  }

  return (
    <div className="employeeRosterMediaQuerycont">
      <div className="ProjectCont">
      <Loader loading={loader2} />
      <Grid container className="editsetupmaincont" sx={{ pl: 3, pr: 2, mt: 2, mb: 1 }}>
        <Box className="addnewprojecthead">Projects</Box>
        <Box>
          {!isEditMode &&
            <Button className="projsetupeditOwner" onClick={toggleEditMode}>
              Edit
            </Button>
          }
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ pl: 3, pr: 3, mt: 2 }} >
        {isEditMode ? (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Project Id <span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="projectCode"
              placeholder="Enter the Project Id"
              variant="outlined"
              disabled={true}
              fullWidth
              value={projectcode}
              onChange={handleprojectcodeChange}
            />
            {errorFields.projectid && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">Project Id</Box>
            <Box className="editvalue">{projectcode}</Box>
          </Grid>
        )}

        {isEditMode ? (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Project Name <span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="projectname"
              placeholder="Enter the Project Name"
              variant="outlined"
              fullWidth
              value={projectname}
              onChange={handleprojectnameChange}
            />
            {errorFields.projectname && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">Project Name</Box>
            <Box className="editvalue">{projectname}</Box>
          </Grid>
        )}

        {isEditMode ? (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Account Name<span style={{ color: "red" }}>*</span></Box>
            <TextField
              id="accountname"
              placeholder="Enter the Account Name"
              variant="outlined"
              fullWidth
              value={accountname}
              onChange={handleaccountChange}
            />
            {errorFields.accountname && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">Account Name</Box>
            <Box className="editvalue">{accountname}</Box>
          </Grid>
        )}

        {isEditMode ? (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label"> Project Type <span style={{ color: "red" }}>*</span></Box>
            <FormControl fullWidth>
            <Select
                id="projecttypeid"
                defaultValue=""
                placeholder="Select Project Type"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                value={projecttypeid}
                onChange={handleprojecttype}
              >
                <MenuItem value="">Select Project Type</MenuItem>
                {projecttypeoption.map((option) => (
                  <MenuItem key={option.id} value={option.projectTypeId}>{option.projectTypeName}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorFields.projecttype && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">Project Type</Box>
            <Box className="editvalue">{projecttypename}</Box>
          </Grid>
        )}

        {isEditMode ? (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Start Date<span style={{ color: "red" }}>*</span></Box>
            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
              <DatePicker
                id="startdate"
                name="startdate"
                value={startdate ? new Date(startdate) : null}
                sx={{ width: "100%", border: "2px solid #B2D4F8" }}
                onAccept={handlestartAccept}
                fullWidth
                format="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            {errorFields.startdate && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">Start Date</Box>
            <Box className="editvalue">{startdate ? moment(startdate).format('DD/MM/YYYY') : ""}</Box>
          </Grid>
        )}

        {isEditMode ? (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">End Date</Box>
            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
              <DatePicker
                id="enddate"
                name="enddate"
                value={enddate ? new Date(enddate) : null}
                sx={{ width: "100%", border: "2px solid #B2D4F8" }}
                onChange={(e)=>{handleendAccept(e)}}
                fullWidth
                format="dd/MM/yyyy"
                slotProps={{
                  field: { clearable: true, onClear: () =>  setendend(null) },
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            {errorFields.enddate && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "20px" }}>
            <Box className="editlabel">End Date</Box>
            <Box className="editvalue">{enddate ? moment(enddate).format('DD/MM/YYYY') : ""}</Box>
          </Grid>
        )}


        {isEditMode ? (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Status<span style={{ color: "red" }}>*</span></Box>
            <FormControl fullWidth>
              <Select
                id="status"
                defaultValue=""
                placeholder="Select status"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                value={status}
                onChange={handlestatusChange}
              >
                <MenuItem value="">Select Status</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">InActive</MenuItem>
                <MenuItem value="Onhold">OnHold</MenuItem>
              </Select>
            </FormControl>
            {errorFields.status && <span className="error-msg">This is required Field</span>}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ pl: 3, mt: "20px" }}>
            <Box className="editlabel">Status</Box>
            <Box className="editvalue">{status}</Box>
          </Grid>
        )}
      </Grid>


      {isEditMode ? (
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ pl: 3, pr: 7, mt: "10px" }}>
          <Box className="label">Description</Box>
          <TextField
            id="description"
            placeholder="Enter Description"
            sx={{
              "& .MuiInputBase-input": {
                alignItems: "top",
                height: "90px",
                border: "1px solid #B2D4F8"
              },
            }}
            variant="outlined"
            fullWidth
            value={description}
            onChange={handledescriptionChange}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} sx={{ mt: "40px", pl:3, pr:3,  pb: 3 }}>
          <Box className="editlabel" > Project Description</Box>
          <Box className="editvalueDescnew" >{description}</Box>
        </Grid>
      )}


      {isEditMode ? (
        <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, pb: 5, justifyContent: "center" }} >
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="cancelButton" onClick={handleNavigate}>Cancel</Button>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button
              className="saveButton" onClick={handleprojectupdate}>Save</Button>
          </Grid>
        </Grid>
      ) : (
        ""
      )}

</div>
      <ProjectOwnerTable projectId= {projectId}/>
      <ShiftsTable projectId= {projectId}/>
      <TasksTable projectId= {projectId}/>
      
    </div> 

  )
}

export default EditNewProject;
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails, Switch } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './AppraisalCycle.css';
import config from "config/config";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Context from "context/context";
import Button from '@mui/material/Button';
import moment from 'moment';
import { ButtonGroup } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';


const AppraisalCycleTable = () => {
    const [isEditable, setIsEditable] = useState(false);
    const [appraisalData, setAppraisalData] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cycleDetails, setCycleDetails] = useState([]);
    const { state, dispatch } = useContext(Context);
    const [errorFields, setErrorFields] = useState({});


    const defaultCycles = ["Q1", "Q2", "Q3", "Q4", "Yearly"];

    const fetchAppraisalData = async (filterValue = "2024") => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            setLoading(true);
            const query = encodeURIComponent(
                JSON.stringify({
                    searchValue: filterValue,
                })
            );

            const url = `${config.host}/panel/pms/allAppraisalCycle?query=${query}`;

            console.log("Fetching Appraisal data with URL:", url);
            console.log("Using search value:", filterValue);


            const response = await fetch(url, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error response data:", errorData);
                throw new Error(`HTTP error! status: ${response.status}, ${errorData.message || ""}`);
            }

            const data = await response.json();

            if (data.status === "Ok" && Array.isArray(data.data.appraisalData)) {

                const formattedData = defaultCycles.map((cycle) => {
                    const matchingCycle = data.data.appraisalData.find((cycleData) => cycleData.cycleName === cycle);
                    if (matchingCycle) {
                        return [
                            matchingCycle.cycleName,
                            matchingCycle.cycleStartDate,
                            matchingCycle.cycleEndDate,
                            matchingCycle.isActive ? "Active" : "Inactive",
                            matchingCycle.cycleId
                        ];
                    }

                    return [cycle, null, null, "Inactive"];
                });

                setAppraisalData(formattedData);
                console.log("Fetched Appraisal Data:", formattedData);
            } else {
                console.error("Unexpected data format:", data);
                setAppraisalData([]);
            }
        } catch (error) {
            console.error("Error fetching appraisal data:", error);
        } finally {
            dispatch({ type: "SET_LOADING", payload: false });
        }
    };

    useEffect(() => {
        fetchAppraisalData();
    }, []);

    const handleAccordionChange = async (index, cycleId) => {

        if (expandedRow === index) {
            setExpandedRow(null);
            setCycleDetails(null);
            dispatch({ type: "SET_LOADING", payload: false });
            return;
        }


        setExpandedRow(index);
        dispatch({ type: "SET_LOADING", payload: true });

        try {

            const url = `${config.host}/panel/pms/appraisalCycle?appraisalId=${cycleId}`;
            const response = await fetch(url, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`Error fetching details for cycleId ${cycleId}`);
            }

            const data = await response.json();
            if (data.status === "Ok" && data.data.appraisalData.length > 0) {
                setCycleDetails(data.data.appraisalData[0]);
            } else {
                console.error("Unexpected data format:", data);
                setCycleDetails(null);
            }
        } catch (error) {
            console.error("Error fetching cycle details:", error);
            setCycleDetails(null);
        } finally {
            dispatch({ type: "SET_LOADING", payload: false });
        }
    };
    useEffect(() => {
        console.log(cycleDetails)
    }, [cycleDetails]);



    const updateStatus = async (cycleId, isActive) => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            const url = `${config.host}/panel/pms/updateStatus`;

            const response = await fetch(url, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cycleId,
                    isActive,
                }),
            });


            if (response.ok) {
                dispatch({ type: "SET_LOADING", payload: false });
                toast.success("Status updated successfully");
                console.log("Status updated successfully");
                fetchAppraisalData();
            } else {
                dispatch({ type: "SET_LOADING", payload: false });
                console.error("Failed to update status");
            }
        } catch (error) {
            dispatch({ type: "SET_LOADING", payload: false });
            toast.error("Failed to update status");
            console.error("Error updating status:", error);
        }
    };

    const handleStatusChange = (cycleId, checked) => {
        dispatch({ type: "SET_LOADING", payload: true });
        console.log(cycleId)
        setAppraisalData((prevData) =>
            prevData.map((cycle) =>
                cycle.cycleId === cycleId ? { ...cycle, isActive: checked } : cycle
            )
        );


        updateStatus(cycleId, checked);
    };


    const handleEditClick = () => {
        setIsEditable(true);
    };


    const updateAppraisalCycle = async (updatedDetails) => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            const url = `${config.host}/panel/pms/updateAppraisalCycle`;

            const response = await fetch(url, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedDetails),
            });

            const data = await response.json();
            if (response.ok && data.status === "Ok" && data.data.updated === 1) {
                dispatch({ type: "SET_LOADING", payload: false });
                toast.success("Changes updated successfully");
                console.log("Appraisal cycle updated successfully");
                fetchAppraisalData();
            } else {
                dispatch({ type: "SET_LOADING", payload: false });
                console.error("Failed to update appraisal cycle");
            }
        } catch (error) {
            dispatch({ type: "SET_LOADING", payload: false });
            toast.error("Failed to update");
            console.error("Error updating appraisal cycle:", error);
        }
    };

    const handleSaveClick = () => {
        dispatch({ type: "SET_LOADING", payload: true });
        if (validateDates()) {

            setIsEditable(false);



            const updatedDetails = {
                cycleId: cycleDetails.cycleId,
                cycleStartDate: cycleDetails.cycleStartDate,
                cycleEndDate: cycleDetails.cycleEndDate,
                reviewStartDate: cycleDetails.reviewStartDate,
                reviewEndDate: cycleDetails.reviewEndDate,
                goalSettingStart: cycleDetails.goalSettingStart,
                goalSettingEnd: cycleDetails.goalSettingEnd,
                assessmentStartDate: cycleDetails.assessmentStartDate,
                assessmentEndDate: cycleDetails.assessmentEndDate,

            };

            updateAppraisalCycle(updatedDetails);
        }
    };




    const handleCancelClick = () => {
        setIsEditable(false);
        setExpandedRow(null);


    };


    const validateDates = () => {
        const today = new Date();
        const { cycleStartDate, goalSettingStart } = cycleDetails;

        if (cycleStartDate && cycleStartDate < today) {
            // message.error("Cycle start date cannot be in the past.");
            return false;
        }
        if (goalSettingStart && goalSettingStart < cycleStartDate) {
            // message.error("Goal Setting Start Date cannot be before Cycle Start Date.");
            return false;
        }
        return true;
    };

    return (
        <>
            <div className="appraisalHeading">Details of appraisal cycle</div>

            <TableContainer className="appraisal-table-container">
                <Table className="appraisal-table">
                    <TableHead className="appraisal-table-header">

                        <TableRow>
                            <TableCell className="appraisal-table-heading " style={{ fontWeight: 500 }}>Cycle</TableCell>
                            <TableCell className="appraisal-table-heading">Start Date</TableCell>
                            <TableCell className="appraisal-table-heading">End Date</TableCell>
                            <TableCell className="appraisal-table-heading">Status</TableCell>
                            <TableCell className="appraisal-table-heading"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {appraisalData.map((row, index) => (
                            <React.Fragment key={index}>
                                <TableRow className="appraisal-table-body-row">
                                    <TableCell className="appraisal-table-cell">{row[0]}</TableCell>
                                    <TableCell className="appraisal-table-cell">{row[1] ? new Date(row[1]).toLocaleDateString() : "N/A"}</TableCell>
                                    <TableCell className="appraisal-table-cell">{row[2] ? new Date(row[2]).toLocaleDateString() : "N/A"}</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={row[3] === "Active"}
                                            onChange={(e) => handleStatusChange(row[4], e.target.checked)}
                                            name="statusToggle"
                                            color="primary"
                                            inputProps={{ 'aria-label': 'status toggle' }}
                                            className={`${row[3] === "Active" ? "switch-active switch-active-thumb" : "switch-inactive switch-inactive-thumb"
                                                }`}
                                        />
                                    </TableCell>                <TableCell className="expand-icon-cell">
                                        <Tooltip title="Click to view in details">
                                            <ArrowDropDownIcon
                                                className={expandedRow === index ? 'open' : ''}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleAccordionChange(index, row[4])}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                {expandedRow === index && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Accordion className="appraisal-accordion-root" expanded={expandedRow === index} onChange={() => handleAccordionChange(index, row[4])}>
                                                <AccordionSummary className="appraisal-accordion-summary" expandIcon={<ArrowDropDownIcon />}>
                                                    <div>{row[0]} Cycle Schedule</div>
                                                </AccordionSummary>
                                                <AccordionDetails className="appraisal-accordion-details">
                                                    <div className="accordion-details-content">
                                                        <Grid container spacing={2} sx={{ pl: 4, pr: 5 }}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                                                                <Box className="label">Cycle Start Date <span style={{ color: 'red' }}>*</span></Box>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        name="cycleStartDate"
                                                                        value={cycleDetails?.cycleStartDate ? new Date(cycleDetails.cycleStartDate) : null}
                                                                        onChange={(newValue) => setCycleDetails((prev) => ({ ...prev, cycleStartDate: newValue }))}
                                                                        minDate={new Date()}
                                                                        disabled={!isEditable} // Disable if not editable
                                                                        inputFormat="dd/MM/yyyy"
                                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                                    />
                                                                </LocalizationProvider>
                                                                {errorFields.cycleStartDate && (
                                                                    <Box sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                                                                        <span>{errorFields.cycleStartDate}</span>
                                                                    </Box>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                                                                <Box className="label">Cycle End Date <span style={{ color: 'red' }}>*</span></Box>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        name="cycleEndDate"
                                                                        value={cycleDetails?.cycleEndDate ? new Date(cycleDetails.cycleEndDate) : null}
                                                                        onChange={(newValue) => setCycleDetails((prev) => ({ ...prev, cycleEndDate: newValue }))}
                                                                        minDate={cycleDetails?.cycleStartDate ? new Date(cycleDetails.cycleStartDate) : null}
                                                                        disabled={!isEditable} // Disable if not editable
                                                                        inputFormat="dd/MM/yyyy"
                                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                                    />

                                                                </LocalizationProvider>
                                                                {errorFields.cycleEndDate && (
                                                                    <Box sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                                                                        <span>{errorFields.cycleEndDate}</span>
                                                                    </Box>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2} sx={{ pl: 4, pr: 5 }}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                                                                <Box className="label">Goal Setting Start Date <span style={{ color: 'red' }}>*</span></Box>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        name="goalSettingStart"
                                                                        value={cycleDetails?.goalSettingStart ? new Date(cycleDetails.goalSettingStart) : null}
                                                                        onChange={(newValue) => setCycleDetails((prev) => ({ ...prev, goalSettingStart: newValue }))}
                                                                        minDate={cycleDetails?.cycleStartDate ? new Date(cycleDetails.cycleStartDate) : null}
                                                                        maxDate={cycleDetails?.cycleEndDate ? new Date(cycleDetails.cycleEndDate) : null}
                                                                        disabled={!isEditable} // Disable if not editable
                                                                        inputFormat="dd/MM/yyyy"
                                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                                    />
                                                                </LocalizationProvider>
                                                                {errorFields.goalSettingStart && (
                                                                    <Box sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                                                                        <span>{errorFields.goalSettingStart}</span>
                                                                    </Box>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                                                                <Box className="label">Goal Setting End Date <span style={{ color: 'red' }}>*</span></Box>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        name="goalSettingEnd"
                                                                        value={cycleDetails?.goalSettingEnd ? new Date(cycleDetails.goalSettingEnd) : null}
                                                                        onChange={(newValue) => setCycleDetails((prev) => ({ ...prev, goalSettingEnd: newValue }))}
                                                                        minDate={cycleDetails?.goalSettingStart ? new Date(cycleDetails.goalSettingStart) : null}
                                                                        maxDate={cycleDetails?.cycleEndDate ? new Date(cycleDetails.cycleEndDate) : null}
                                                                        disabled={!isEditable}
                                                                        inputFormat="dd/MM/yyyy"
                                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                                    />
                                                                </LocalizationProvider>
                                                                {errorFields.goalSettingEnd && (
                                                                    <Box sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                                                                        <span>{errorFields.goalSettingEnd}</span>
                                                                    </Box>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2} sx={{ pl: 4, pr: 5 }}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                                                                <Box className="label">Self Assessment Start Date <span style={{ color: 'red' }}>*</span></Box>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        name="assessmentStartDate"
                                                                        value={cycleDetails?.assessmentStartDate ? new Date(cycleDetails.assessmentStartDate) : null}
                                                                        onChange={(newValue) => setCycleDetails((prev) => ({ ...prev, assessmentStartDate: newValue }))}
                                                                        minDate={cycleDetails?.goalSettingEnd ? new Date(cycleDetails.goalSettingEnd) : null}
                                                                        maxDate={cycleDetails?.cycleEndDate ? new Date(cycleDetails.cycleEndDate) : null}
                                                                        disabled={!isEditable} // Disable if not editable
                                                                        inputFormat="dd/MM/yyyy"
                                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                                    />
                                                                </LocalizationProvider>
                                                                {errorFields.assessmentStartDate && (
                                                                    <Box sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                                                                        <span>{errorFields.assessmentStartDate}</span>
                                                                    </Box>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                                                                <Box className="label">Self Assessment End Date <span style={{ color: 'red' }}>*</span></Box>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        name="assessmentEndDate"
                                                                        value={cycleDetails?.assessmentEndDate ? new Date(cycleDetails.assessmentEndDate) : null}
                                                                        onChange={(newValue) => setCycleDetails((prev) => ({ ...prev, assessmentEndDate: newValue }))}
                                                                        minDate={cycleDetails?.assessmentStartDate ? new Date(cycleDetails.assessmentStartDate) : null}
                                                                        maxDate={cycleDetails?.cycleEndDate ? new Date(cycleDetails.cycleEndDate) : null}
                                                                        disabled={!isEditable}
                                                                        inputFormat="dd/MM/yyyy"
                                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                                    />
                                                                </LocalizationProvider>
                                                                {errorFields.assessmentEndDate && (
                                                                    <Box sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                                                                        <span>{errorFields.assessmentEndDate}</span>
                                                                    </Box>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2} sx={{ pl: 4, pr: 5 }}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                                                                <Box className="label">Manager Review Start Date <span style={{ color: 'red' }}>*</span></Box>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        name="reviewStartDate"
                                                                        value={cycleDetails?.reviewStartDate ? new Date(cycleDetails.reviewStartDate) : null}
                                                                        onChange={(newValue) => setCycleDetails((prev) => ({ ...prev, reviewStartDate: newValue }))}
                                                                        minDate={cycleDetails?.assessmentEndDate ? new Date(cycleDetails.assessmentEndDate) : null}
                                                                        maxDate={cycleDetails?.cycleEndDate ? new Date(cycleDetails.cycleEndDate) : null}
                                                                        disabled={!isEditable}
                                                                        inputFormat="dd/MM/yyyy"
                                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                                    />
                                                                </LocalizationProvider>
                                                                {errorFields.reviewStartDate && (
                                                                    <Box sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                                                                        <span>{errorFields.reviewStartDate}</span>
                                                                    </Box>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                                                                <Box className="label">Manager Review End Date <span style={{ color: 'red' }}>*</span></Box>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        name="reviewEndDate"
                                                                        value={cycleDetails?.reviewEndDate ? new Date(cycleDetails.reviewEndDate) : null}
                                                                        onChange={(newValue) => setCycleDetails((prev) => ({ ...prev, reviewEndDate: newValue }))}
                                                                        minDate={cycleDetails?.reviewStartDate ? new Date(cycleDetails.reviewStartDate) : null}
                                                                        maxDate={cycleDetails?.cycleEndDate ? new Date(cycleDetails.cycleEndDate) : null}
                                                                        disabled={!isEditable}
                                                                        inputFormat="dd/MM/yyyy"
                                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                                    />
                                                                </LocalizationProvider>
                                                                {errorFields.reviewEndDate && (
                                                                    <Box sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                                                                        <span>{errorFields.reviewEndDate}</span>
                                                                    </Box>
                                                                )}
                                                            </Grid>
                                                        </Grid>


                                                        <Grid item xs={12} className="buttonContainermodal">
                                                            <ButtonGroup>
                                                                <Button
                                                                    variant="contained"
                                                                    className="saveButtonmodal"
                                                                    onClick={isEditable ? handleSaveClick : handleEditClick}
                                                                    color={isEditable ? "primary" : "secondary"}
                                                                >
                                                                    {isEditable ? 'Save' : 'Edit'}
                                                                </Button>

                                                                {isEditable && (
                                                                    <Button
                                                                        variant="contained"
                                                                        color="warning"
                                                                        onClick={handleCancelClick}
                                                                        className="cancelButtonmodal"
                                                                        style={{ marginLeft: "8px" }}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                )}
                                                            </ButtonGroup>
                                                        </Grid>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

};

export default AppraisalCycleTable;

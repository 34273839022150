import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import imports from "../../../../assets/images/rpHomePage/4022440.jpg";
import settings from "../../../../assets/images/settings.png"
import vra from "../../../../assets/images/rpHomePage/vra.png";
import vra2 from "../../../../assets/images/rpHomePage/vra2.jpg";
import caoch from "../../../../assets/images/rpHomePage/8294.jpg";
import report from "../../../../assets/images/rpHomePage/313.jpg";
import affinity from "../../../../assets/images/rpHomePage/aff.png"
import "./RpHome.css";
import substitution from "../../../../assets/images/rpHomePage/substitution.png";

const RpHomePage = () => {
    const navigate = useNavigate();

    const handlevraRosterNavigate = () => navigate("/panel/vraRoster");
    const handleCard1Navigate = () => navigate("/panel/radRoster");
    const handleCard2Navigate = () => navigate("/panel/importsrf");
    const handleCard3Navigate = () => navigate("/panel/rpreports");
    const handleCard4Navigate = () => navigate("/panel/trainingsetup");
    const handleCardQgendaNavigate = () => navigate("/panel/qagenda");
    const handleAffinityNavigate=()=> navigate("/panel/vraRadAffinity");
    const handleCardVRASchedulingNavigate=()=> navigate("/panel/settings");
    const handleVraSubstitutions=()=> navigate("/panel/vraSubstitutions")


    return (
        <div style={{
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "100vh"
        }}>
            <Box sx={{ pt: 2, pb: 2, pl: 3 }} className="VraShiftsTitle">Roster Management</Box>
            <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item xs={12} md={4}>
                    <Card onClick={handleCard1Navigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={vra2}
                                alt="RAD Roster"
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div  className='CardContentTitle'>
                                    RAD Roster
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card onClick={handlevraRosterNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={vra}
                                alt="VRA Roster"
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div  className='CardContentTitle'>
                                    VRA Roster
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card onClick={handleAffinityNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={affinity}
                                alt="RAD Roster"
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div  className='CardContentTitle'>
                                    Affinity Roster
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>

            <Box sx={{ pt: 2, pb: 2, pl: 3 }} className="VraShiftsTitle">Schedule Data Management</Box>
            <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item xs={12} md={4}>
                    <Card onClick={handleCard2Navigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={imports}
                                alt="Import SRF Data"
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitle'>
                                    Import SRF Data
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card onClick={handleCardQgendaNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={imports}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitle'>
                                    Import Qgenda Data
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card onClick={handleVraSubstitutions}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={substitution}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '200px',
                                    width: '90%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitle'>
                                 VRA Substitutions
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

             
            </Grid>

            <Box sx={{ pt: 2, pb: 2, pl: 3 }} className="VraShiftsTitle">Reports and Settings</Box>
            <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item xs={12} md={4}>
                    <Card onClick={handleCard3Navigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={report}
                                alt="Reports"
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitle'>
                                    Reports
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card onClick={handleCardVRASchedulingNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={settings}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitle'>
                                  Settings
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>



            <Box sx={{ pt: 2, pb: 2, pl: 3 }} className="VraShiftsTitle">Training Management</Box>
            <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item xs={12} md={4}>
                    <Card onClick={handleCard4Navigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={caoch}
                                alt="Training Setup"
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitle'>
                                    Training Setup
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default RpHomePage;

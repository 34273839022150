import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./EditAddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
// import contactInfo from './ContactInfo';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import personalInfo from "assets/images/Group (1).png";
import EditContactInfo from './EditContactInfo';
import EditEducationInfo from './EditEducationInfo';
import EditDocumentinfo from './EditDocumentinfo';
import EditNumberinfo from './EditNumberinfo';
import EditInsuranceinfo from './EditInsuranceinfo';
import EditProjectInfo from './EditProjectInfo';
import EditBankinfo from './EditBankinfo';
import EditEmployementinfo from './EditEmployementinfo';
import EditManagerInfo from './EditManagerInfo';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton } from '@mui/material';
import getAllGenderdrop from "apis/panel/AddEmployee/addEmployee";
import getAllTitlesdrop from "apis/panel/AddEmployee/addEmployee";
import getMaritalstatusAPIdrop from "apis/panel/AddEmployee/addEmployee"
import getcreateemployee from "apis/panel/AddEmployee/addEmployee"
import getAllblooddrop from "apis/panel/AddEmployee/addEmployee"
import getcreatefindone from "apis/panel/AddEmployee/addEmployee"
import * as moment from "moment";
import { toast } from "react-toastify";
import Context from "context/context";
import Loader from "components/Loader";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const EditAddEmployee = () => {
  let params = useParams();
  const employeeId = params.id;
  const { state, dispatch } = useContext(Context);
  const [roleCode, setRoleCode] = useState(state.role.roleCode);
  const [isEditMode, setIsEditMode] = useState(false);
  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const [employeeName, setEmployeeName] = useState('');
  const [FirstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [middleName, setmiddleName] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [motherName, setmotherName] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [spouseName, setSpouseName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState('');
  const [personalEmail, setPersonalEmail] = useState('');
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [bloodGroup, setBloodGroup] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [bloodgroupoption, setbloodgroupoption] = useState([]);
  const [maritaloption, setmaritaloption] = useState([]);
  const [bloodgroupid, setbloodgroupid] = useState('')
  const [maritalid, setmaritalid] = useState('')
  const { getAllBloodsAPI } = getAllblooddrop();
  const { getAllTitleAPI } = getAllTitlesdrop();
  const { getEmployeeCreateupdate } = getcreateemployee();
  const { getEmployeefindone } = getcreatefindone();
  const { getAllGenderAPI } = getAllGenderdrop()
  const { getMaritalStatusAPI } = getMaritalstatusAPIdrop();
  console.log(roleCode,"rolecode")

  const [spouseNameDisabled, setSpouseNameDisabled] = useState(false);
  const [title, setTitle] = useState('');
  const [titleOptions, setTitleOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
  const [titleId, setTitleId] = useState('');
  const [genderId, setgenderId] = useState('');
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false)


  const [errorFields, setErrorFields] = useState({
    title: false,
    firstName: false,
    lastName: false,
    maritalStatus: false,
    fathersName: false,
    mothersName: false,
    spouseName: false,

    businessMail: false,
    personalMail: false,
    personalPhone: false,
    businessPhone: false,
    dateOfBirth: false,
    gender: false,
    bloodgroup: false,

  });

  useEffect(() => {
    setLoader(true);
    handleGetAllTitles()
    handleGetAllMaritalStatus()
    handleGetAllGendersApi()
    handleGetAllbloodgroup()
    handleCreateLeave().then(() => {

      setLoader(false);
    });
  }, [])

  const handleGetAllTitles = async () => {
    try {
      let res = await getAllTitleAPI();
      setTitleOptions(res?.data || []);
    }
    catch (err) { }
  };

  const handleFirstNameChange = (event) => {
    const input = event.target.value;

    // Update the state with the new input value
    setFirstName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, firstName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, firstName: false });
    }
};
  

  const handlelastNameChange = (event) => {

    
  
    const input = event.target.value;

    // Update the state with the new input value
    setlastName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, lastName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, lastName: false });
    }
    
  };

  const handlemiddleNameChange = (e) => {
    setmiddleName(e.target.value);
  };

  const handlefatherNameChange = (event) => {
    const input = event.target.value;
  
    setFatherName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, fathersName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, fathersName: false });
    }
   
  };

  const handlemotherNameChange = (event) => {
    const input = event.target.value;
  
    setmotherName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, mothersName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, mothersName: false });
    }
    
  };

  const handlespouseNameChange = (event) => {
    const input = event.target.value;
  
    setSpouseName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, spouseName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, spouseName: false });
    }
   
  };

  // const handlebusinessmailNameChange = (e) => {
  //   setBusinessEmail(e.target.value);
  // };

  const handlebusinessmailNameChange = (e) => {
    
    const value = e.target.value.toLowerCase().replace(/\s/g, '');
    setBusinessEmail(value);
    if (!value) {
      setErrorFields({ ...errorFields, businessMail: true });
    } else {
      setErrorFields({ ...errorFields, businessMail: false });
    }
  };

  // const handlepersonalemailChange = (e) => {
  //   setPersonalEmail(e.target.value);
  // };
  const handlepersonalemailChange = (event) => {
    const value = event.target.value.toLowerCase().replace(/\s/g, '');
    setPersonalEmail(value); 

    if (!value) {
      setErrorFields({ ...errorFields, personalMail: true });
    } else {
      setErrorFields({ ...errorFields, personalMail: false });
    }
  };

  const handlebusinessphoneChange = (e) => {
    setBusinessPhoneNumber(e.target.value);
  };

  const handlepersonalphoneChange = (e) => {
    setPersonalPhoneNumber(e.target.value); 
  };



  const handleCreateLeave = async (e) => {

    const values = {
      relID: employeeId
    };

    const data = await getEmployeefindone(values);
    // const data = state?.employeeData;
    dispatch({ type: "SET_EMPLOYEE_DATA", payload: data?.data });

    setAllEmployeeData(data);
    if (data?.data?.title === null) {
      setTitle("")
      setTitleId("")
    }
    else {
      setTitle(data?.data?.titles?.titleName)
      setTitleId(data?.data?.titles?.titleId)
    }




    setFirstName(data?.data?.firstName)
    setlastName(data?.data?.lastName)
    if (data?.data?.middleName === null) {
      setmiddleName("")

    }
    else {
      setmiddleName(data?.data?.middleName)

    }
    if (data?.data?.maritalStatus === null) {
      console.log(data?.data?.maritalStatus,'jjjjj');
      
      setMaritalStatus("")
      setmaritalid("")

    }
    else {
      setMaritalStatus(data?.data?.maritalStatus?.maritalStatusName)
      setmaritalid(data?.data?.maritalStatus?.maritalStatusId)

    }


    setBusinessEmail(data?.data?.businessEmail)
    if (data?.data?.personalEmail === null) {
      setPersonalEmail("")

    }
    else {
      setPersonalEmail(data?.data?.personalEmail)

    }

    if (data?.data?.businessPhoneNumber === null) {
      setBusinessPhoneNumber("")

    }
    else {
      setBusinessPhoneNumber(data?.data?.businessPhoneNumber)

    }

    if (data?.data?.personalPhoneNumber === null) {
      setPersonalPhoneNumber("")


    }
    else {
      setPersonalPhoneNumber(data?.data?.personalPhoneNumber)

    }



    if (data?.data === null || data?.data?.dateOfBirth === null) {
      setDateOfBirth("")

    }
    else {
      setDateOfBirth(data?.data?.dateOfBirth)

    }

    if (data?.data?.genders === null) {
      setGender("")
      setgenderId("")

    }
    else {
      setGender(data?.data?.genders?.genderName)
      setgenderId(data?.data?.genders?.genderId)


    }
    if (data?.data?.bloodGroups === null) {
      setBloodGroup("")
      setbloodgroupid("")


    }
    else {
      setBloodGroup(data?.data?.bloodGroups?.bloodGroupName)
      setbloodgroupid(data?.data?.bloodGroups?.bloodGroupId)

    }

    if (data?.data?.relationship === null) {
      setFatherName("")
      setmotherName("")
      setSpouseName("")



    } else {


      let relationships = data?.data?.relationship;

      for (let index = 0; index < relationships?.length; index++) {
        const element = relationships[index];
        if (element.relationships.relationshipCode == 'FATHER') {
          setFatherName(element?.firstName)
        }
        if (element.relationships.relationshipCode == 'MOTHER') {
          setmotherName(element?.firstName)
        }
        if (element.relationships.relationshipCode == 'SPOUSE') {
          setSpouseName(element?.firstName)
        }
      }

    }

  }

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handleCreateupdate = async (e) => {
    setLoader2(true)
    const errors = {};
    if (!title) errors.title = true;
    if (!FirstName) errors.firstName = true;
    if (!lastName) errors.lastName = true;
    if (!fatherName) errors.fathersName = true;
    if (!motherName) errors.mothersName = true;
    if (!bloodGroup) errors.bloodgroup = true;
    if (!maritalStatus) errors.maritalStatus = true;
    if (maritalStatus === 'Married' && !spouseName) {
      errors.spouseName = true;
    }
    if (!businessEmail) errors.businessMail = true;
   
    if (!personalEmail) errors.personalMail = true;
    if (!personalPhoneNumber) errors.personalPhone = true;
    if (!dateOfBirth) errors.dateOfBirth = true;
    if (!gender) errors.gender = true;
    
    setErrorFields(errors);

    if (Object.keys(errors).length === 0) {
      const values = {
        employeeId: employeeId,
        titleId: titleId,
        firstName: FirstName,
        lastName: lastName,
        middleName: middleName,
        maritalStatusId: maritalid,
        fatherName: fatherName,
        motherName: motherName,
        spouseName: spouseName,
        dateOfBirth:  moment(dateOfBirth).format("YYYY-MM-DD"),
        businessEmail: businessEmail,
        personalEmail: personalEmail,
        businessPhoneNumber: businessPhoneNumber,
        personalPhoneNumber: personalPhoneNumber,
        bloodGroupId: bloodgroupid,
        genderId: genderId
      };

      console.log(values,"values")
      const data = await getEmployeeCreateupdate(values);
      if (data.status === 'Ok') {
        setLoader2(false)
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
      }
      else {
        setLoader2(false)
        return toast.error("Error saving Data", { toast: "Error saving Data" });
      }
    }
    else{
      setLoader2(false)
      return toast.error("Error saving Data", { toast: "Error saving Data" });
    }
  };

  const handleGetAllMaritalStatus = async () => {
    try {
      let res = await getMaritalStatusAPI();
      setmaritaloption(res?.data || []);
    }
    catch (err) { }
  };

  const handleGetAllGendersApi = async () => {
    try {
      let res = await getAllGenderAPI();
      setGenderOptions(res?.data || []);
    }
    catch (err) { }
  };

  const handleGetAllbloodgroup = async () => {
    try {
      let res = await getAllBloodsAPI();
      setbloodgroupoption(res?.data || []);
    }
    catch (err) { }
  };

  const handleTitleChange = (e) => {
    const selectedTitle = titleOptions.find(option => option.titleName === e.target.value);
    setTitle(selectedTitle.titleName); // Set the titleName
    setTitleId(selectedTitle.titleId); // Set the titleId
  };

  const handlebloodChange = (e) => {
    const selectedblood = bloodgroupoption.find(option => option.bloodGroupName === e.target.value);
    setBloodGroup(selectedblood.bloodGroupName); // Set the titleName
    setbloodgroupid(selectedblood.bloodGroupId); // Set the titleId
  };

  const handleGenderChange = (e) => {
    const selectedgender = genderOptions.find(option => option.genderName === e.target.value);
    setGender(selectedgender.genderName);
    setgenderId(selectedgender.genderId)
  };

  const handlemaritalChange = (e) => {
    if(e.target.value!=''){
      const selectedmarital = maritaloption.find(option => option.maritalStatusName === e.target.value);
      setMaritalStatus(selectedmarital?.maritalStatusName);
      setmaritalid(selectedmarital?.maritalStatusId)
      if (selectedmarital.maritalStatusName === 'Single') {
        setSpouseNameDisabled(true)
  
      }
      else {
        setSpouseNameDisabled(false)
  
      }
    }else{
      setMaritalStatus("")
      setmaritalid("")
    }
   
  };

  // const handlemaritalChange = (e) => {
  //   const selectedmarital = maritalStatusOptions.find(option => option.maritalStatusName === e.target.value);
  //   setMaritalStatus(selectedmarital.maritalStatusName);
  //   setmaritalid(selectedmarital.maritalStatusId);
  //   console.log(selectedmarital.maritalStatusName,"selectedmarital")
  //   console.log(maritalid, "123356")
  // if (selectedmarital.maritalStatusName === 'Unmarried') {

  //   setSpouseNameDisabled(true);
  // } else {
  //   setSpouseNameDisabled(false);
  // }
  // };

  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      // Accordion is not expanded, reset edit mode
      setIsEditMode(false);
    }
  };

  const maxDate = new Date(); // Today's date
  

  return (
    <div className='EditInfoCont' >
      <Loader loading={loader} />
      <Loader loading={loader2} />
      <Accordion sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
        >
          <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='editpersonalInfo-logo' src={personalInfo} alt="info" /></Box>
          <Box className="editacc-title" sx={{ pl: 3 }}>Personal Information</Box>

          <Box sx={{ ml: 45 }}>
            {expanded && (
              <button className="editButton" onClick={toggleEditMode}>
                Edit
                <Box sx={{ flexGrow: 1 }} />
                {isEditMode}
              </button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails onClick={(event) => event.stopPropagation()}>
          <Grid container spacing={2} sx={{ pl: 6, pr: 5, pb: 5, mt: 2 }} >
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Title <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="title-label"
                    id="title"
                    style={{ border: "2px solid #B2D6F8", color: "grey" }}
                    defaultValue=""
                    name="title"
                    value={title}
                    onChange={handleTitleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      Select Title
                    </MenuItem>
                    {titleOptions.map((option) => (
                      <MenuItem key={option.id} value={option.titleName}>{option.titleName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields.title && <span className="error-msg">Title is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "20px" }}>
                <Box className="editlabel"> Title</Box>
                <Box className="editvalue">{title}</Box>
              </Grid>
            )}

            {/* Father Name */}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">First Name <span style={{ color: "red" }}>*</span></Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="spouseName" name="spouseName" placeholder="First Name" variant="outlined" fullWidth value={FirstName} onChange={handleFirstNameChange} />
                {errorFields.firstName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "20px" }} >
                <Box className="editlabel">First Name</Box>
                <Box className="editvalue">{FirstName}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Middle Name </Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="LastName" name="LastName" placeholder="Middle Name" variant="outlined" fullWidth value={middleName} onChange={handlemiddleNameChange} />
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "20px" }}>
                <Box className="editlabel">Middle Name </Box>
                <Box className="editvalue">{middleName}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Last Name <span style={{ color: "red" }}>*</span></Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="LastName" name="LastName" placeholder="Last Name" variant="outlined" fullWidth value={lastName} onChange={handlelastNameChange} />
                {errorFields.lastName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }} >
                <Box className="editlabel">Last Name</Box>
                <Box className="editvalue">{lastName}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Father's Name <span style={{ color: "red" }}>*</span></Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="Father'sName" name="Father'sName" placeholder="Father's Name" variant="outlined" fullWidth value={fatherName} onChange={handlefatherNameChange} />
                {errorFields.fathersName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Father's Name</Box>
                <Box className="editvalue">{fatherName}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Mother's Name <span style={{ color: "red" }}>*</span></Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="Mother'sName" name="Mother'sName" placeholder="Mother's Name" variant="outlined" fullWidth value={motherName} onChange={handlemotherNameChange} />
                {errorFields.mothersName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Mother's Name</Box>
                <Box className="editvalue">{motherName}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Marital Status <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  {/* <InputLabel id="marital-status-label">Marital Status</InputLabel> */}
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    defaultValue=""
                    displayEmpty
                    name="maritalStatus"
                    value={maritalStatus}
                    // sx={{ border: '2px solid #B2D4F8' }}
                    onChange={handlemaritalChange}
                    style={{ border: "2px solid #B2D4F8", color: "grey" }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >

                    <MenuItem value="">Select Marital Status</MenuItem>
                    {maritaloption.map((option) => (
                      <MenuItem key={option.id} value={option.maritalStatusName}>{option.maritalStatusName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields.maritalStatus && <span className="error-msg">This is required Field</span>}

              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Marital Status</Box>
                <Box className="editvalue">{maritalStatus}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Spouse Name {!spouseNameDisabled?<span style={{ color: "red" }}>*</span>:""}</Box>
                <TextField sx={{ border: '1px solid #B2D4F8', backgroundColor: spouseNameDisabled ? '#B2D4F8' : '' }} id="spouseName" name="spouseName" placeholder="Spouse Name" variant="outlined" fullWidth value={spouseName} onChange={handlespouseNameChange} disabled={spouseNameDisabled} />
                {errorFields.spouseName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Spouse Name</Box>
                <Box className="editvalue">{spouseName}</Box>
              </Grid>
            )}

            {/* Gender */}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Business Mail <span style={{ color: "red" }}>*</span></Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="businessMail" name="businessMail" placeholder="Business Mail" variant="outlined" fullWidth value={businessEmail} onChange={handlebusinessmailNameChange}  disabled={roleCode !== 'R001'} />
                {errorFields.businessMail && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Business Mail</Box>
                <Box className="editvalue">{businessEmail}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Personal Email <span style={{ color: "red" }}>*</span></Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="businessMail" name="personalMail" placeholder="Personal Email" variant="outlined" fullWidth value={personalEmail} onChange={handlepersonalemailChange} />
                {errorFields.personalMail && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Personal Email</Box>
                <Box className="editvalue">{personalEmail}</Box>
              </Grid>
            )}

            {/* Blood group */}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Business Phone Number</Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="businessPhone" name="businessPhone"
                  //  onChange={handleBusinessNumberChange}
                  placeholder="Business Phone Number" variant="outlined" fullWidth value={businessPhoneNumber} onChange={handlebusinessphoneChange} />
                  

              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Business Phone Number</Box>
                <Box className="editvalue">{businessPhoneNumber}</Box>
              </Grid>
            )}



            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Personal Phone Number <span style={{ color: "red" }}>*</span></Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="personalPhone"
                  // value={personalPhone} 
                  name="personalPhoneNumber"
                  //  onChange={handlePersonalNumberChange} 
                  placeholder="Personal Phone Number" variant="outlined" fullWidth value={personalPhoneNumber} onChange={handlepersonalphoneChange} />
                   {errorFields.personalPhone && <span className="error-msg">This is required Field</span>}

              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Personal Phone</Box>
                <Box className="editvalue">{personalPhoneNumber}</Box>
              </Grid>
            )}



            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Date of Birth <span style={{ color: "red" }}>*</span></Box>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <DatePicker
                   sx={{width:"100%"}}
                    id="dateOfBirth"
                    name="dateOfBirth"
                    // value={new Date(dateOfBirth)}
                    value={dateOfBirth?new Date(dateOfBirth):""}
                    onChange={(date) => {
                      setDateOfBirth(date);
                    }}
                    maxDate={maxDate}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    format="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />                  
                </LocalizationProvider>
                {errorFields.dateOfBirth && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Date Of Birth</Box>
                <Box className="editvalue">{dateOfBirth?moment(dateOfBirth).format('DD/MM/YYYY'):""}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Gender <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    placeholder="Select Date"
                    defaultValue=""
                    displayEmpty
                    value={gender}
                    sx={{width:"100%"}}

                    // sx={{ border: '2px solid #B2D4F8' }}
                    name="gender"
                    // value={gender}
                    onChange={handleGenderChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "2px solid #B2D4F8", color: "grey" }}
                  >
                    <MenuItem value="">
                      Select Gender
                    </MenuItem>
                    {genderOptions.map((option) => (
                      <MenuItem key={option.id} value={option.genderName}>{option.genderName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields.gender && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Gender</Box>
                <Box className="editvalue">{gender}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">Blood Group <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    placeholder="Select Date"
                    defaultValue=""
                    displayEmpty
                    value={bloodGroup}
                    //  value={bloodGroup}

                    // sx={{ border: '2px solid #B2D4F8' }}
                    name="gender"
                    //  value={gender}
                    onChange={handlebloodChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "2px solid #B2D4F8", color: "grey" }}
                  >
                    <MenuItem value="">
                      Select Blood Group
                    </MenuItem>
                    {bloodgroupoption.map((option) => (
                      <MenuItem key={option.id} value={option.bloodGroupName}>{option.bloodGroupName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields.bloodgroup && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "40px" }}>
                <Box className="editlabel">Blood Group</Box>
                <Box className="editvalue">{bloodGroup}</Box>
              </Grid>
            )}

            {isEditMode && (
              <Grid container spacing={1} sx={{ pl: 6, pr: 5, mt: 5, justifyContent: "center" }} >
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Button className="cancelButton" onClick={toggleEditMode}>Cancel</Button>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Button className="saveButton" onClick={handleCreateupdate}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            )}

          </Grid>
        </AccordionDetails>
      </Accordion>
      <EditContactInfo />
      <EditEducationInfo />
      <EditDocumentinfo />
      <EditNumberinfo />
      <EditBankinfo />
      <EditInsuranceinfo />
      {/* {(roleCode == "R001") ? */}
      <div>
        <EditEmployementinfo />
        <EditManagerInfo />
        <EditProjectInfo />
      </div>
      {/* : ""} */}
    </div>
  )
}

export default EditAddEmployee;

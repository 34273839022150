import React, { useEffect, useRef, useState } from 'react';

import "./AddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/Frame (1).png";
import AttachFileIcon from '@mui/icons-material/AttachFile';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {  InputAdornment, IconButton } from '@mui/material';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import getAlldesignationdrop from "apis/panel/employmentInformation/employmentinfo"
import getAlldepartmentdrop from "apis/panel/employmentInformation/employmentinfo"
import getAllemployementtypedrop from "apis/panel/employmentInformation/employmentinfo"
import getAllworklocationdrop from "apis/panel/employmentInformation/employmentinfo"
import getAllexperiencesdrop from "apis/panel/employmentInformation/employmentinfo"
import getAllemploymentcreatedrop from "apis/panel/employmentInformation/employmentinfo"
import getAllrolesdrop from "apis/panel/employmentInformation/employmentinfo"


// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   fileInput: {
//     '& input[type="file"]': {
//       display: 'none',
//     },
//   },
//   textField: {
//     '& .MuiOutlinedInput-root': {
//       '&.Mui-focused fieldset': {
//         borderColor: theme.palette.primary.main,
//       },
//       '&:hover fieldset': {
//         borderColor: theme.palette.secondary.main,
//       },
//     },
//     '& .MuiFormLabel-root.Mui-focused': {
//       color: theme.palette.primary.main,
//     },
//   },
// }));



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const EmployementInfo = () => {
    const [file, setFile] = useState(null);
    const {getAlldesignationAPI} = getAlldesignationdrop();
    const[designationname,setdesignationname]=useState()
    const[designationid,setdesignationid]=useState()
    const[designationoption,setdesignationoption]=useState([])


    const {getAlldepartmentAPI} = getAlldepartmentdrop();
    const[departmentname,setdepartmentname]=useState()
    const[departmentid,setdepartmentid]=useState()
    const[departmentoption,setdepartmentoption]=useState([])

    const {getAllemployementtypeAPI} = getAllemployementtypedrop();
    const[employementtypename,setemployementtypename]=useState()
    const[employementtypeid,setemployementtypeid]=useState()
    const[employementtypeoption,setemployementtypeoption]=useState([])


    const {getAllworklocationAPI} = getAllworklocationdrop();
    const[worklocationname,setworklocationname]=useState()
    const[worklocationid,setworklocationid]=useState()
    const[worklocationoption,setworklocationoption]=useState([])


    const {getAllexperiencesAPI} = getAllexperiencesdrop();
    const[experiencesname,setexperiencesname]=useState()
    const[experiencesid,setexperiencesid]=useState()
    const[experiencesoption,setexperiencesoption]=useState([])


    const {getAllRolesAPI} = getAllrolesdrop();
    const[rolesname,setrolesname]=useState()
    const[rolesid,setrolesid]=useState()
    const[rolesoption,setrolesoption]=useState([])


    const[status,setstatus]=useState('Active')
    const[uan,setuan]=useState('')
    const [dateOfBirth, setDateOfBirth] = useState(null);

    const {getEmployementcreateAPI} = getAllemploymentcreatedrop();
    const EmployeeId = sessionStorage.getItem('EmployeeID')
    const EmployeeCode = sessionStorage.getItem('EmployeeCode')



    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
    };

    const [errorFields, setErrorFields] = useState({
      Rolename: false,
      Departmentname: false,
      designation:false,
      employementstatus: false,
      employeetype: false,
      dateofjoining:false,
      worklocation:false,
      experience:false,
      uan:false,
      employeeid:false

      
    
     
      
    });

    const handleGetdesignation = async () => {
      try {
          let res = await getAlldesignationAPI();
          setdesignationoption(res?.data || []);
      }
      catch (err) {}
    };

    const handleGetdepartment = async () => {
      try {
          let res = await getAlldepartmentAPI();
          setdepartmentoption(res?.data || []);
      }
      catch (err) {}
    };

    const handleGetemployementtype = async () => {
      try {
          let res = await getAllemployementtypeAPI();
          setemployementtypeoption(res?.data || []);
      }
      catch (err) {}
    };

    const handleGetworklocation = async () => {
      try {
          let res = await getAllworklocationAPI();
          setworklocationoption(res?.data || []);
      }
      catch (err) {}
    };

    const handleGetexperiences = async () => {
      try {
          let res = await getAllexperiencesAPI();
          setexperiencesoption(res?.data || []);
      }
      catch (err) {}
    };


    const handleGetallRoles = async () => {
      try {
          let res = await getAllRolesAPI();
          setrolesoption(res?.data || []);
      }
      catch (err) {}
    };
console.log('jkhkjhkj');
    const handlestatusChange = (e) => {
      console.log('haqi');
      setstatus(e.target.value);
      console.log(status,'status changed');
    };

    const handleDobAccept = (date) => {
      setDateOfBirth(date);
    };

    const handleuan = (e) => {
      setuan(e.target.value);
    };

    useEffect(()=>{
      handleGetdesignation()
      handleGetdepartment()
      handleGetemployementtype()
      handleGetworklocation()
      handleGetexperiences()
      handleGetallRoles()

    },[])

    const handledesignationChange = (e) => {
      const selecteddesignation = designationoption.find(option => option.designationName === e.target.value);
      setdesignationname(selecteddesignation.designationName); // Set the titleName
      setdesignationid(selecteddesignation.designationId); // Set the titleId
    };


    const handlerolesChange = (e) => {
      const selectedrole = rolesoption.find(option => option.roleName === e.target.value);
      setrolesname(selectedrole.roleName); 
      setrolesid(selectedrole.roleId); 
    };

    const handledepartmentChange = (e) => {
      const selecteddepartment = departmentoption.find(option => option.departmentName === e.target.value);
      setdepartmentname(selecteddepartment.departmentName); // Set the titleName
      setdepartmentid(selecteddepartment.departmentId); // Set the titleId
    };

    const handleemployementtypeChange = (e) => {
      const selectemployementtypement = employementtypeoption.find(option => option.employmentTypeName === e.target.value);
      setemployementtypename(selectemployementtypement.employmentTypeName); // Set the titleName
      setemployementtypeid(selectemployementtypement.employmentTypeId); // Set the titleId
    };

    const handleworklocationChange = (e) => {
      const selectworklocationment = worklocationoption.find(option => option.workLocationName === e.target.value);
      setworklocationname(selectworklocationment.workLocationName); // Set the titleName
      setworklocationid(selectworklocationment.workLocationId); // Set the titleId
    };

    const handleexperiencesChange = (e) => {
      const selectexperiences = experiencesoption.find(option => option.yearsOfExperience === e.target.value);
      setexperiencesname(selectexperiences.yearsOfExperience); // Set the titleName
      setexperiencesid(selectexperiences.experienceId); // Set the titleId
    };

    const handleCreateemplyement = async (e) => {
      e.preventDefault();
      // Validate form fields
      const errors = {};
      if (!rolesid) errors.Rolename = true;
      if (!designationid) errors.designation = true;
      if (!departmentid) errors.Departmentname = true;
      if (!status) errors.employementstatus = true;
      if (!employementtypeid) errors.employeetype = true;
      if (!dateOfBirth) errors.dateofjoining = true;
      if (!worklocationid) errors.worklocation = true;
      if (!experiencesid) errors.experience = true;
      if (!uan) errors.uan = true;
      if (!EmployeeId) errors.employeeid = true;
      
      
    
  
      setErrorFields(errors);
  
      // If no errors, proceed with form submission
      if (Object.keys(errors).length === 0) {

      const values = {
        employeeId: EmployeeId,
        roleId: rolesid,
        designationId: designationid,
        departmentId:departmentid,
        status: status,
        employmentTypeId:employementtypeid,
        dateOfJoining:moment(dateOfBirth).format("YYYY-MM-DD"),
        workLocationId:worklocationid,
        experienceId: experiencesid,
        uan:uan
       
       
      };
      
      const data = await getEmployementcreateAPI(values);
      if(data.status === 'Ok'){
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });

      }else{
        return toast.error("Error saving Data", { toast: "Error saving Data" });

      }
    }
  };

  const handleReset = () => {
    
    setdesignationid('')
    setdepartmentid('')
    setstatus('')
    setemployementtypeid('')
    setDateOfBirth('')
    setworklocationid('')
    setexperiencesid('')
    setuan('')


    

    

  }




  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleCancel = () => {
    setAccordionOpen(false); 
  };

  const maxDate = new Date();



 
  return (
    <Accordion expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)} sx={{ mt: 2, boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
    <AccordionSummary
      expandIcon={<ArrowDropDownIcon className="Accordianicon"/>}
      aria-controls="panel1-content"
      id="panel1-header"
      style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px", height:"70px"}}
    >
      <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" /></Box>
      <Box className="acc-title" sx={{ pl: 3 }}>Employment Information</Box>
    </AccordionSummary>
    <AccordionDetails>


      <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:2 }} >
        {/* <Box>Current Addres</Box> */}

        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label" >Employee Id <span style={{color:"red"}}>*</span></Box>
        <TextField id="firstName"  placeholder="Employee Id" variant="outlined" fullWidth value={EmployeeCode} sx={{border:"1.5px solid #B2D4F8"}}  disabled/>
        {errorFields. employeeid&& <span className="error-msg">Employee Id is required</span>}
          </Grid>
          </Grid>
          <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:2 }} >
          <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label" > Role <span style={{color:"red"}}>*</span></Box>
        <FormControl fullWidth>
                <Select
                  labelId="title-label"
                  id="title"
                  style={{border: "1px solid #B2D4F8",color:"grey"}}
                  defaultValue=""
                  name="title"
                  value={rolesname}
                  onChange={handlerolesChange}

                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                         <MenuItem value="">
               Select Role
          </MenuItem>
        
          {rolesoption.map((option) => (
          <MenuItem key={option.id} value={option.roleName}>{option.roleName}</MenuItem>
        ))}
                </Select>
              </FormControl>
              {errorFields. Rolename&& <span className="error-msg">Role is required</span>}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label" > Designation <span style={{color:"red"}}>*</span></Box>
        <FormControl fullWidth>
                <Select
                  labelId="title-label"
                  id="title"
                  style={{border: "1px solid #B2D4F8",color:"grey"}}
                  defaultValue=""
                  name="title"
                  value={designationname}
                  onChange={handledesignationChange}

                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                         <MenuItem value="">
               Select Designation
          </MenuItem>
        
          {designationoption.map((option) => (
          <MenuItem key={option.id} value={option.designationName}>{option.designationName}</MenuItem>
        ))}
                </Select>
              </FormControl>
              {errorFields. designation&& <span className="error-msg">Designation is required</span>}
        </Grid>
       </Grid>
       <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:2 }} >
        <Grid item xs={12} sm={6} md={6} lg={6}>
       
        <Box className="label" > Department <span style={{color:"red"}}>*</span></Box>
        <FormControl fullWidth>
                <Select
                  labelId="title-label"
                  id="title"
                  style={{border: "1px solid #B2D4F8",color:"grey"}}
                  defaultValue=""
                  name="title"
                  value={departmentname}
                  onChange={handledepartmentChange}

                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                         <MenuItem value="">
               Select Department
          </MenuItem>
        
          {departmentoption.map((option) => (
          <MenuItem key={option.id} value={option.departmentName}>{option.departmentName}</MenuItem>
        ))}
                </Select>
              </FormControl>
              {errorFields.Departmentname&& <span className="error-msg">Department is required</span>}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label" > Employment Status <span style={{color:"red"}}>*</span></Box>
        <FormControl fullWidth>
            {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
            <Select
              labelId="marital-status-label"
              id="maritalStatus"
              placeholder="Select Employement Status"
              defaultValue=""
              displayEmpty
              value={status}
              onChange={handlestatusChange}

              inputProps={{ 'aria-label': 'Without label' }}
                   style={{border:"1.5px solid #B2D4F8", color:"grey"}}
            //   label="Marital Status"
            >
                       <MenuItem value="">
               Select Status
          </MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
              {/* <MenuItem value="Divorced">Divorced</MenuItem> */}
            </Select>
          </FormControl>
          {errorFields. employementstatus&& <span className="error-msg">Employment status is required</span>}
        </Grid>
        </Grid>




      <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:2 }} >
        {/* <Box>Current Addres</Box> */}

        <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Employment Type<span style={{color:"red"}}>*</span></Box>
            <FormControl fullWidth>
            {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
            <Select
              labelId="marital-status-label"
              id="maritalStatus"
              placeholder="Select Employement Type"
              defaultValue=""
              displayEmpty
              value={employementtypename}
              onChange={handleemployementtypeChange}
              
              inputProps={{ 'aria-label': 'Without label' }}
                   style={{border:"1.5px solid #B2D4F8", color:"grey"}}
            //   label="Marital Status"
            >
                       <MenuItem value="">
               Select Employment Type
          </MenuItem>
          {employementtypeoption.map((option) => (
          <MenuItem key={option.id} value={option.employmentTypeName}>{option.employmentTypeName}</MenuItem>
        ))}
            </Select>
          </FormControl>
          {errorFields. employeetype&& <span className="error-msg">Employment Type is required</span>}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label">Date of Joining<span style={{color:"red"}}>*</span></Box>
        <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                          
                          <DatePicker 
                            sx={{width:"100%"}} fullWidth
                           onAccept={handleDobAccept}
                           slotProps={{
                            field: { clearable: true },
                          }}
                          maxDate={maxDate}
      

                             
                          />
                    
                  </LocalizationProvider>
                  {errorFields. dateofjoining&& <span className="error-msg">Date Of Joining is required</span>}
        </Grid>
       </Grid>
       <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:2 }} >
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label">Work Location<span style={{color:"red"}}>*</span></Box>
        <FormControl fullWidth>
            {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
            <Select
              labelId="marital-status-label"
              id="maritalStatus"
              placeholder="Select Work Location"
              defaultValue=""
              value={worklocationname}
              onChange={handleworklocationChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
                   style={{border:"1.5px solid #B2D4F8", color:"grey"}}
            //   label="Marital Status"
            >
                       <MenuItem value="">
               Select Location
          </MenuItem>


          {worklocationoption.map((option) => (
          <MenuItem key={option.id} value={option.workLocationName}>{option.workLocationName}</MenuItem>
        ))}
            </Select>
          </FormControl>
          {errorFields. worklocation&& <span className="error-msg">Work location is required</span>}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label">Years Of Experience<span style={{color:"red"}}>*</span></Box>
        <FormControl fullWidth>
            {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
            <Select
              labelId="marital-status-label"
              id="maritalStatus"
              defaultValue=""
              displayEmpty
              value={experiencesname}
              onChange={handleexperiencesChange}
              inputProps={{ 'aria-label': 'Without label' }}
                   style={{border:"1.5px solid #B2D4F8", color:"grey"}}
            //   label="Marital Status"
            >
                       <MenuItem value="">
               Select Experience
          </MenuItem>
          {experiencesoption.map((option) => (
          <MenuItem key={option.id} value={option.yearsOfExperience}>{option.yearsOfExperience}</MenuItem>
        ))}
            </Select>
          </FormControl>
          {errorFields. experience&& <span className="error-msg">Experience is required</span>}
        </Grid>

        </Grid>

        <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:2 }} >
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label">UAN<span style={{color:"red"}}>*</span></Box>
        <TextField id="firstName"  placeholder="Enter UAN" variant="outlined" fullWidth onChange={handleuan} />
        {errorFields. uan&& <span className="error-msg">UAN is required</span>}
        </Grid>
    
  
        </Grid>



        <Grid container spacing={1}  sx={{ pl: 4, pr:5, mt:5 , justifyContent:"center"}} >
        <Grid item xs={12} sm={3} md={3} lg={3}>
        <Button className="resetChanges" onClick={handleReset}>Reset Changes</Button>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="saveButton" onClick={handleCreateemplyement}>Save</Button>
        </Grid>

        </Grid>
    </AccordionDetails>
  </Accordion>
   
  )
}

export default EmployementInfo
// npm packages
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";

// custom pages
import "./AddEmployee.css";
import FileHandler from "apis/panel/fileHandler/fileHandler";
import getAllDegreesAPIdrop from "apis/panel/Education/education";
import postApi from "apis/panel/Education/education";
import Context from "context/context";
import ReusableFunction from "utils/reusablefunction";

// custom images
import personalInfo from "assets/images/Capa_1 (1).png";

// material ui
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { InputAdornment, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ClearIcon from "@mui/icons-material/Clear";
import * as moment from "moment"

const EducationInfo = () => {
  const { dispatch } = useContext(Context);
  const [degree, setDegree] = useState("");
  const [otherDegree, setOtherDegree] = useState("");
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [SSCmarksheet, setSSCmarksheet] = useState(null);
  const [hscMarksheet, sethscMarksheet] = useState(null);
  const [ugConsolidated, setugConsolidated] = useState(null);
  const [ugDegree, setugDegree] = useState(null);
  const [pgConsolidated, setpgConsolidated] = useState(null);
  const [pgDegree, setpgDegree] = useState(null);
  const [PassportPhoto, setPassportPhoto] = useState(null);
  const [Specialization, setSpecialization] = useState("");
  const [InstituitionName, setInstituitionName] = useState("");
  const [GraduationDate, setGraduationDate] = useState("");
  const [degreeId, setDegreeId] = useState("");
  const { getAllDegreesAPI } = getAllDegreesAPIdrop();
  const { fileUploadAPI } = FileHandler();
  const [filePaths, setFilePaths] = useState({
    sscMarkSheet: "",
    hscMarkSheet: "",
    ugMarkSheet: "",
    ugDegree: "",
    pgMarkSheet: "",
    pgDegree: "",
    passportSizePhoto: "",
  });

  const EmployeeId = sessionStorage.getItem("EmployeeID");

  // error
  const [degreeError, setDegreeError] = useState(false);
  const [specializationError, setSpecializationError] = useState(false);
  const [instituitionNameError, setInstituitionNameError] = useState(false);
  const [graduationDateError, setGraduationDateError] = useState(false);
  const [SSCmarksheetError, setSSCmarksheetError] = useState(false);
  const [hscMarksheetError, setHscMarksheetError] = useState(false);
  const [passportPhotoError, setPassportPhotoError] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleCancel = () => {
    setAccordionOpen(false);
  };

  const { PostEducationApi } = postApi();
  const { handleFileInputReselect } = ReusableFunction();

  const handleDegreeChange = (e) => {
    const selectedgender = degreeOptions.find(
      (option) => option.degreeName === e.target.value
    );
    setDegree(selectedgender.degreeName);
    setDegreeId(selectedgender.degreeId);

    if (selectedgender.degreeName === "Others") {
      setOtherDegree("");
    }
  };

  useEffect(() => {
    handleGetAllDegreesApi();
  }, []);

  const handleGetAllDegreesApi = async () => {
    try {
      let res = await getAllDegreesAPI();
      setDegreeOptions(res?.data || []);
    } catch (err) {}
  };

  const handleFileUpload = async (file, type) => {
    const maxSize = 1024 * 1024 * 5;
    if (file.size > maxSize) {
      toast.error("File size exceeds the limit (5MB)", {
        toastId: "file-upload-maximum",
      });
      return false;
    }
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let input = new FormData();
      if (type === "PROFILE") {
        input.append("type", "employee_photos");
      } else {
        input.append("type", "employee_certificates");
      }
      input.append("employeeId", EmployeeId);
      input.append("file", file);
      const res = await fileUploadAPI(input);
      dispatch({ type: "SET_LOADING", payload: false });
      if (res?.data?.location) {
        return res?.data?.location;
      }
      return false;
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.log({ err });
    }
  };

  const handleFileChangeSSC = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) {
      setFilePaths({ ...filePaths, sscMarkSheet: location });
      setSSCmarksheet(file);
    }
  };

  const handleFileChangeHSC = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) {
      setFilePaths({ ...filePaths, hscMarkSheet: location });
      sethscMarksheet(file);
    }
  };

  const handleFileChangeUg = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) {
      setFilePaths({ ...filePaths, ugMarkSheet: location });
      setugConsolidated(file);
    }
  };

  const handleFileChangeUgDegree = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) {
      setFilePaths({ ...filePaths, ugDegree: location });
      setugDegree(file);
    }
  };

  const handleFileChangePG = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) {
      setFilePaths({ ...filePaths, pgMarkSheet: location });
      setpgConsolidated(file);
    }
  };

  const handleFileChangePgDegree = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) {
      setFilePaths({ ...filePaths, pgDegree: location });
      setpgDegree(file);
    }
  };

  const handleFileChangePassportPhoto = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file, "PROFILE");
    if (location) {
      setFilePaths({ ...filePaths, passportSizePhoto: location });
      setPassportPhoto(file);
    }
  };

  const handleSave = async () => {
    let isError = false;

    if (!degree) {
      setDegreeError(true);
      isError = true;
    } else {
      setDegreeError(false);
    }

    if (!Specialization) {
      setSpecializationError(true);
      isError = true;
    } else {
      setSpecializationError(false);
    }

    if (!InstituitionName) {
      setInstituitionNameError(true);
      isError = true;
    } else {
      setInstituitionNameError(false);
    }

    if (!GraduationDate) {
      setGraduationDateError(true);
      isError = true;
    } else {
      setGraduationDateError(false);
    }

    if (!SSCmarksheet) {
      setSSCmarksheetError(true);
      isError = true;
    } else {
      setSSCmarksheetError(false);
    }

    if (!hscMarksheet) {
      setHscMarksheetError(true);
      isError = true;
    } else {
      setHscMarksheetError(false);
    }

    if (!PassportPhoto) {
      setPassportPhotoError(true);
      isError = true;
    } else {
      setPassportPhotoError(false);
    }

    if (isError) {
      return;
    }

    if (isError) {
      return;
    }

    if (SSCmarksheet && hscMarksheet) {
      let body = {
        employeeId: EmployeeId,
        degreeId: degreeId,
        specialisation: Specialization,
        institutionName: InstituitionName,
        graduationDate: moment(GraduationDate).format("YYYY-MM-DD"),
        ssc: filePaths.sscMarkSheet,
        hsc: filePaths.hscMarkSheet,
        ugConsolidated: filePaths.ugMarkSheet,
        ugDegree: filePaths.ugDegree,
        pgConsolidated: filePaths.pgMarkSheet,
        pgDegree: filePaths.pgDegree,
        photo: filePaths.passportSizePhoto,
      };

      const data = await PostEducationApi(body);
      if (data.status === "Ok") {
        return toast.success("Data Saved Successfully", {
          toast: "Data Saved Successfully",
        });
      } else {
        return toast.error("Error saving Data", { toast: "Error saving Data" });
      }
    } else {
    }
  };
  const handleReset = () => {
    setDegree("");
    setSpecialization("");
    setInstituitionName("");
    setGraduationDate("");
    setSSCmarksheet("");
    sethscMarksheet("");
    setugConsolidated("");
    setugDegree("");
    setpgConsolidated("");
    setpgDegree("");
    setPassportPhoto("");
  };

  const maxDate = new Date();

  const handlesscclear = () => {
    setSSCmarksheet("");
  };

  const handlehscclear = () => {
    sethscMarksheet("");
  };

  const handleugconclear = () => {
    setugConsolidated("");
  };

  const handleugclear = () => {
    setugDegree("");
  };

  const handlepgconclear = () => {
    setpgConsolidated("");
  };

  const handlepgclear = () => {
    setpgDegree("");
  };

  const handlepassportclear = () => {
    setpgDegree("");
  };

  return (
    <Accordion
      expanded={accordionOpen}
      onChange={() => setAccordionOpen(!accordionOpen)}
      sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
      >
        <Box sx={{ pl: 3 }}>
          {" "}
          <img
            sx={{ pl: 3 }}
            className="personalInfo-logo"
            src={personalInfo}
            alt="info"
          />
        </Box>
        <Box className="acc-title" sx={{ pl: 3 }}>
          Education Information
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
          <Box className="subHeadingforinfos" sx={{ pl: 3 }}>
            {" "}
            Education
          </Box>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          {/* <Box>Current Addres</Box> */}

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Degree <span style={{ color: "red" }}>*</span>
            </Box>
            <FormControl fullWidth>
              {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
              <Select
                labelId="marital-status-label"
                id="maritalStatus"
                defaultValue=""
                value={degree}
                onChange={handleDegreeChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                //   label="Marital Status"
              >
                <MenuItem value="">Select Degree</MenuItem>
                {degreeOptions.map((option) => (
                  <MenuItem key={option.id} value={option.degreeName}>
                    {option.degreeName}
                  </MenuItem>
                ))}
                <MenuItem value="Others">Others</MenuItem>
              </Select>
            </FormControl>
            {degreeError && (
              <div className="error-message">Please select a degree</div>
            )}

            {degree === "Others" && (
              <TextField
                style={{ border: "1.5px solid #B2D4F8" }}
                id="otherDegree"
                error
                placeholder="Enter Degree"
                variant="outlined"
                fullWidth
                sx={{ mt: 1 }}
                helperText="Incorrect entry"
                // variant="filled"
                value={otherDegree}
                onChange={(e) => setOtherDegree(e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              Specialization <span style={{ color: "red" }}>*</span>
            </Box>
            <TextField
              style={{ border: "1.5px solid #B2D4F8" }}
              id="Specialization"
              placeholder="Enter Specialization"
              variant="outlined"
              value={Specialization}
              onChange={(e) => setSpecialization(e.target.value)}
              fullWidth
            />
            {specializationError && (
              <div className="error-message">Please enter specialization</div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              Instituition Name <span style={{ color: "red" }}>*</span>
            </Box>
            <TextField
              style={{ border: "1.5px solid #B2D4F8" }}
              id="firstName"
              placeholder="Enter Instituition"
              value={InstituitionName}
              onChange={(e) => setInstituitionName(e.target.value)}
              variant="outlined"
              fullWidth
            />
            {instituitionNameError && (
              <div className="error-message">Please enter institution name</div>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              Graduation Date <span style={{ color: "red" }}>*</span>
            </Box>
            <LocalizationProvider
              fullWidth
              style={{ width: "100%", border: "1.5px solid #B2D4F8 " }}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                sx={{ width: "100%" }}
                fullWidth
                onAccept={(e) => setGraduationDate(e)}
                inputFormat="dd/MM/yyyy"
                slotProps={{
                  field: { clearable: true },
                }}
                maxDate={maxDate}
              />
            </LocalizationProvider>
            {graduationDateError && (
              <div className="error-message">Please select graduation date</div>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 4 }}>
          <Box className="subHeadingforinfos" sx={{ pl: 3 }}>
            Education Certificate{" "}
          </Box>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          {/* <Box>Current Addres</Box> */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              SSC mark sheet (JPEG, PNG, PDF)
              <span style={{ color: "red" }}>*</span>
            </Box>
            <TextField
              style={{ border: "1.5px solid #B2D4F8" }}
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={SSCmarksheet ? SSCmarksheet.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {SSCmarksheet && (
                      <IconButton
                        onClick={handlesscclear}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload-0"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangeSSC}
                    />
                    <label htmlFor="file-upload-0">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {SSCmarksheetError && (
              <div className="error-message">Please upload SSC mark sheet</div>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              HSC mark sheet (JPEG, PNG, PDF)
              <span style={{ color: "red" }}>*</span>
            </Box>
            <TextField
              style={{ border: "1.5px solid #B2D4F8" }}
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={hscMarksheet ? hscMarksheet.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {hscMarksheet && (
                      <IconButton
                        onClick={handlehscclear}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload1"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangeHSC}
                    />
                    <label htmlFor="file-upload1">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {hscMarksheetError && (
              <div className="error-message">Please upload HSC mark sheet</div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              UG consolidated mark sheet (JPEG, PNG, PDF)
            </Box>
            <TextField
              style={{ border: "1.5px solid #B2D4F8" }}
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={ugConsolidated ? ugConsolidated.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {ugConsolidated && (
                      <IconButton
                        onClick={handleugconclear}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload2"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangeUg}
                    />
                    <label htmlFor="file-upload2">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {/* {ugConsolidatedError && <div className="error-message">Please upload SSC mark sheet</div>} */}
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">UG Degree certificate (JPEG, PNG, PDF)</Box>
            <TextField
              style={{ border: "1.5px solid #B2D4F8" }}
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={ugDegree ? ugDegree.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {ugDegree && (
                      <IconButton
                        onClick={handleugclear}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload3"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangeUgDegree}
                    />
                    <label htmlFor="file-upload3">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {/* {ugDegreeError && <div className="error-message">Please upload SSC mark sheet</div>} */}
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              PG consolidated mark sheet (JPEG, PNG, PDF)
            </Box>
            <TextField
              style={{ border: "1.5px solid #B2D4F8" }}
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={pgConsolidated ? pgConsolidated.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {pgConsolidated && (
                      <IconButton
                        onClick={handlepgconclear}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload4"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangePG}
                    />
                    <label htmlFor="file-upload4">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {/* {pgConsolidatedError && <div className="error-message">Please upload SSC mark sheet</div>} */}
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">PG Degree certificate (JPEG, PNG, PDF)</Box>
            <TextField
              style={{ border: "1.5px solid #B2D4F8" }}
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={pgDegree ? pgDegree.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {pgDegree && (
                      <IconButton
                        onClick={handlepgclear}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload5"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangePgDegree}
                    />
                    <label htmlFor="file-upload5">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {/* {pgDegreeError && <div className="error-message">Please upload SSC mark sheet</div>} */}
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Passport size photographs (JPEG, PNG, PDF)
              <span style={{ color: "red" }}>*</span>
            </Box>
            <TextField
              style={{ border: "1.5px solid #B2D4F8" }}
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              type="text"
              value={PassportPhoto ? PassportPhoto.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {PassportPhoto && (
                      <IconButton
                        onClick={handlepassportclear}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload6"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangePassportPhoto}
                    />
                    <label htmlFor="file-upload6">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {passportPhotoError && (
              <div className="error-message">Please upload Passport</div>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }}
        >
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button onClick={handleReset} className="resetChanges">
              Reset Changes
            </Button>
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="cancelButton" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button onClick={handleSave} className="saveButton">
              Save
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EducationInfo;


import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts';
import context from "context/context";
import getAllTitle from "apis/panel/ConfigurationPage/configuration";
import getTotalLeavesTakenYearly from "apis/panel/ConfigurationPage/configuration";
import getTypesOfLeavesTaken from "apis/panel/ConfigurationPage/configuration";
import Grid from "@mui/material/Grid";
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import "./leaveManagement.css";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "./LeaveMain.css"
import leaveManagementAPI from 'apis/panel/leaveManagemnet/leaveManagement';
import { Padding } from '../../../../node_modules/@mui/icons-material/index';

moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});
const localizer = momentLocalizer(moment);

const LeaveMainBackup = ({selectedYear}) => {
    
    const { dispatch } = useContext(context);
    const { getAllTitleAPI } = getAllTitle();
    const { getTotalLeavesTakenYearlyAPI, getLeavesNumberWithType } = leaveManagementAPI();

    const Navigate = useNavigate();
    const [titleList, setTitleList] = useState([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [createObj, setCreateObj] = useState({
        titleId: "",
        titleName: "",
        isActive: "",
    });
    const [offset, setOffset] = useState(1);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const [dataSet, setDataSet] = useState([]);
    const [chart1Data, setChart1Data] = useState(null);
    const [chart2Data, setChart2Data] = useState(null);
    const [chart3Data, setChart3Data] = useState(null);
    const [chart4Data, setChart4Data] = useState(null);
   
    const [subtitle, setSubtitle] = useState([])

    // const monthNames = [
    //     "Jan", "Feb", "March", "April", "May", "June",
    //     "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    // ];

    const handleGetAllTitle = async () => {
        let values = {
            offset: (offset - 1) * 10,
            limit: 10,
        };
        if (searchValue) {
            values = {
                offset: 0,
                limit: 10,
                searchValue,
            };
        }

        try {
            setLoading(true);
            let res = await getAllTitleAPI(values);

            setTitleList(res?.data?.title || []);
            setCount(res?.data?.count);

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleGetAllTitle();
    }, [viewModalOpen, editModalOpen, searchValue, page, offset, itemsPerPage]);

    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;

    const chartSetting = {
        yAxis: [
            {
                label: 'Number of leaves',
            },
        ],
        width: 671,
        height: 320,
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${label}`}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ color: entry.color }}>
                            {`${entry.name} : ${entry.value}`}
                        </p>
                    ))}
                </div>
            );
        }

        return null;
    };




    const monthNames = [
        { month: "Jan", year: selectedYear.getFullYear() },
        { month: "Feb", year: selectedYear.getFullYear() },
        { month: "Mar", year: selectedYear.getFullYear() },
        { month: "Apr", year: selectedYear.getFullYear()},
        { month: "May", year: selectedYear.getFullYear() },
        { month: "Jun", year: selectedYear.getFullYear() },
        { month: "Jul", year: selectedYear.getFullYear() },
        { month: "Aug", year: selectedYear.getFullYear() },
        { month: "Sep", year: selectedYear.getFullYear() },
        { month: "Oct", year: selectedYear.getFullYear() },
        { month: "Nov", year: selectedYear.getFullYear() },
        { month: "Dec", year: selectedYear.getFullYear() }
    ];
    const CustomizedAxisTick = ({ x, y, payload, monthNames }) => {
        const { month, year } = monthNames[payload.index];
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                    {month}
                </text>
                <text x={0} y={20} dy={16} textAnchor="middle" fill="#666">
                    {year}
                </text>
            </g>
        );
    };

    const getTotalLeavesTaken = async () => {
        try {
            let values = {
                year: selectedYear
            };
        
            const res = await getTotalLeavesTakenYearlyAPI(values);

            if (res.status === 'Ok') {
              
                const rawDataSet = res?.data;
               
                
                const transformedDataSet = rawDataSet.map(data => ({
                    ...data,
                    month: monthNames[data.month - 1]
                }));
                setDataSet(transformedDataSet);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const mapLeaveTypeName = (leaveTypeName) => {
        return leaveTypeName
    };

    const calculateSubtitle = (data) => {
    
    if( data.leaveTypeName== 'Loss of Pay'){
        const subtitle = `${data.utilizedDays}`;
        setSubtitle(prevState => [...prevState, subtitle]);
        return `${data.utilizedDays}`;
    }else{
        if(data.availableDays>0){
            const subtitle = `${data.utilizedDays}/${data.availableDays}`;
            setSubtitle(prevState => [...prevState, subtitle]);
            return `${data.utilizedDays}/${data.availableDays}`;
        }else{
            return `${data.utilizedDays}`;
        }
       
    }
        
    };

    const prepareChartData = (data, color) => {
        const totalDays = data.availableDays;
        const utilizedDays = data.utilizedDays;
        const remainingDays = totalDays - utilizedDays;

        return {
            labels: [mapLeaveTypeName(data.leaveTypeName)],
            datasets: [
                {
                    data: [utilizedDays, remainingDays],
                    backgroundColor: [color, 'rgb(196, 184, 184)'], // Use grey for the remaining part
                    cutout: '70%',
                }
            ]
        };
    };

    const setPieChartData = (data) => {
        if (data.length > 0) {
            data.map((leavetype, i) => {
                if (leavetype.leaveTypeName == 'Privilege Leave') {
                    
                    setChart1Data({
                        data: prepareChartData(data[i], '#00B562'),
                        options: {
                            plugins: {
                                centerText: {
                                    text: calculateSubtitle(data[i]),
                                    color: '#000000', // adjust color as needed
                                    fontStyle: 'bold',
                                    sidePadding: 20, // padding around the center text
                                },
                                
                             
                            },
                            events: []  
                        }
                        
                    });
                    
                } else if (leavetype.leaveTypeName == 'Sick Leave') {
                    setChart2Data({
                        data: prepareChartData(data[i], '#009596'),
                        options: {
                            plugins: {
                                centerText: {
                                    text: calculateSubtitle(data[i]),
                                    color: 'black', // adjust color as needed
                                    fontStyle: 'bold',
                                    sidePadding: 20, // padding around the center text
                                }
                            },
                            events: []
                        }
                    });
                } else if (leavetype.leaveTypeName == 'Compensatory Leave') {
                    setChart3Data({
                        data: prepareChartData(data[i], '#f4c145'),
                        options: {
                            plugins: {
                                centerText: {
                                    text: calculateSubtitle(data[i]),
                                    color: 'black', // adjust color as needed
                                    fontStyle: 'bold',
                                    sidePadding: 20, // padding around the center text
                                }
                            },
                            events: []  
                        }
                    });
                }else if (leavetype.leaveTypeName == 'Loss of Pay') {
                   
                    setChart4Data({
                        data: prepareChartData(data[i], '#ba8e1d'),
                        options: {
                            plugins: {
                                centerText: {
                                    text: calculateSubtitle(data[i]),
                                    color: 'black', // adjust color as needed
                                    fontStyle: 'bold',
                                    sidePadding: 20, // padding around the center text
                                }
                            },
                            events: []  
                        },
                        
                    });
                   
                }
            })

           

        }else{
            setChart1Data(null)
            setChart2Data(null)
            setChart3Data(null)
            setChart4Data(null)
           
        }
    };


    const getTypesOfLeavestaken = async () => {
        try {
            let values = {
                year: selectedYear
            };
            const res = await getLeavesNumberWithType(values);

            if (res.status === 'Ok') {
                setSubtitle([]);
                setPieChartData(res.data?.leaveData);
            
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTotalLeavesTaken();
        getTypesOfLeavestaken();
    }, [selectedYear]);

    return (
        <>
            <div>
                <Grid container spacing={4}>

                    {/* Types of Leaves Taken Header */}
                    <Grid item xs={12}>
                        <span className='LeavestakengraphHeading'>Types Of Leaves Taken</span>
                    </Grid>

                    {/* Doughnut Charts */}
                    {chart1Data || chart2Data || chart3Data || chart4Data ? (
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {chart1Data && (
                                <div style={{ width: '200px', position: "relative" }}>
                                    <Doughnut data={chart1Data.data} options={chart1Data.options} />
                                    <div className='doughnutchart1data'  style={{
        position: "absolute",
        top: "55%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        justifyContent: "center",
        textAlign: "center",
      }}>{chart1Data.options.plugins.centerText.text}</div>
                                </div>
                            )}
                            
                            {chart2Data && (
                                <div style={{ width: '200px', position: "relative" }}>
                                    <Doughnut data={chart2Data.data} options={chart2Data.options} />
                                    <div className='doughnutchart2data' style={{
        position: "absolute",
        top: "55%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        justifyContent: "center",
        textAlign: "center",
      }}>{chart2Data.options.plugins.centerText.text}</div>
                                </div>
                            )}
                            
                            {chart3Data && chart3Data.options.plugins.centerText.text !=0 && (
                                <div style={{ width: '200px', position: "relative" }}>
                                    <Doughnut data={chart3Data.data} options={chart3Data.options} />
                                    <div className='doughnutchart3data'
                                     style={{
                                        position: "absolute",
                                        top: "55%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        justifyContent: "center",
                                        textAlign: "center",
                                      }}>{chart3Data.options.plugins.centerText.text !=0 ? chart3Data.options.plugins.centerText.text:null }</div>
                                </div>
                            )}
                           
                           {chart4Data && chart4Data.options.plugins.centerText.text != 0 && (
  <div style={{ width: '200px', position: "relative" }}>
    <Doughnut data={chart4Data.data} options={chart4Data.options} />
    <div
      className='doughnutchart3data'
      style={{
        position: "absolute",
        top: "55%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {chart4Data.options.plugins.centerText.text}
    </div>
  </div>
)}



                        </Grid>
                    ) : (
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div style={{ width: '200px', position: "relative", color:'grey' }} >No Data </div>
                        </Grid>

                    )}

                    {/* Total Leaves Taken This Year Header */}
                    <Grid item xs={12}>
                        <span className='TotalLeavesYearHeading'>Total Leaves Taken This Year</span>
                    </Grid>

                    {/* Bar Chart */}
                    <Grid item xs={12}>
                        <div style={{ height: '400px' }}>
                            <ResponsiveContainer height="100%">
                                <BarChart
                                    data={dataSet}
                                    layout="horizontal"
                                    margin={{ top: 20, right: 30, bottom: 20 }}
                                >
                                    <CartesianGrid strokeDasharray="0 4" />
                                    <XAxis
                                        type="category"
                                        dataKey="monthYear"
                                        tick={<CustomizedAxisTick monthNames={monthNames} />}
                                    />
                                    <YAxis type="number" />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Tooltip />
                                    <Legend
                                        verticalAlign="bottom"
                                        wrapperStyle={{
                                            paddingTop: '30px',
                                            display: "flex",
                                            gap: "15%",
                                            justifyContent: "space-around",
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            lineHeight: "16.94px",
                                            letterSpacing: "0.02em",
                                        }}
                                    />

                                    <Bar dataKey="privilege_leave" name="Privilege Leave" fill="#4cb140" barSize={10} // Adjust bar size as needed
                                        barGap={10}>
                                        <LabelList dataKey="privilege_leave" position="top" formatter={(value) => value !== 0 ? value : ''} />
                                    </Bar>
                                    <Bar dataKey="sick_leave" name="Sick Leave" fill="#009596" barSize={10} // Adjust bar size as needed
                                        barGap={10}>
                                        <LabelList dataKey="sick_leave" position="top" formatter={(value) => value !== 0 ? value : ''} />
                                    </Bar>
                                    <Bar dataKey="compensatory_leave" name="Compensatory Leave" fill="#f4c145" barSize={10} // Adjust bar size as needed
                                        barGap={10}>
                                        <LabelList dataKey="compensatory_leave" position="top" formatter={(value) => value !== 0 ? value : ''} />
                                    </Bar>
                                    <Bar dataKey="loss_of_pay" name="Loss of pay" fill="#ba8e1d" barSize={10} // Adjust bar size as needed
                                        barGap={10}>
                                        <LabelList dataKey="loss_of_pay" position="top" formatter={(value) => value !== 0 ? value : ''} />
                                    </Bar>
                                </BarChart>

                            </ResponsiveContainer>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default LeaveMainBackup;

import {React, useContext,useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import * as moment from "moment";

import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import "react-toastify/dist/ReactToastify.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Context from "context/context";
import reportAPI from 'apis/panel/reports/reports';
import Modal from '@mui/material/Modal';
import Tables from "components/table/table";

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    width: "90vw",
    
    borderRadius: "10px",
    maxHeight: "90vh",
    overflowY: "scroll",
   
  };

const RadRosterReport = ({ setDisableDropdown }) => {
    const { dispatch } = useContext(Context);
    const [radScheduleData, setRadScheduleData]= useState([]);
    const [open , setOpen] = useState(false)
    const [offset, setOffset] = useState(1);
    const [initial,setInitial]= useState(true)
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const {
        getRadRosterAPI, RadReportViewAPI
      } = reportAPI();

      const initialStartDate = moment().startOf('month').toDate();

      const initialEndDate = new Date();
  
      const [exportObj, setExportObj] = useState({
          startDate: initialStartDate,
          endDate: initialEndDate,
      });

      const handleClose = () => {
        setOpen(false);
      }

 useEffect(()=>{

  if(!initial){
    handleView()
  }
  
 },[offset,searchValue])

      const handleView = async () => {

      
        try {
          dispatch({ type: "SET_LOADING", payload: true });
         let value = {
            offset: (offset - 1) * 20,
            limit: 20,
          };
          if (searchValue) {
            value = {
                ...value,
              searchValue,
            };
          }
    
        
      
          let res = await RadReportViewAPI(value);
      
         
          setCount(res?.data?.count)
          setRadScheduleData(res?.data?.data)
          setInitial(false)
          dispatch({ type: "SET_LOADING", payload: false });
          setOpen(true)
        } catch (err) {
          dispatch({ type: "SET_LOADING", payload: false });
          setOpen(false)
        }
      };

      const handleExport = async () => {
       
        dispatch({ type: "SET_LOADING", payload: true });
        setDisableDropdown(true);
        await getRadRosterAPI();
        setDisableDropdown(false);
        dispatch({ type: "SET_LOADING", payload: false });
      };
    
  return (
    <div>
         
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>

        <Button
            className="download-btn"
            sx={{marginRight:'10px'}}
            startIcon={
              <RemoveRedEyeOutlinedIcon
                style={{ fontSize: 30, verticalAlign: "middle" ,pl:'2px'}}
              />
            }
            onClick={handleView}
          >
            View
          </Button>
          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
              />
            }
            onClick={handleExport}
          >
            Export
          </Button>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
     
        

          <Tables
        title={
          <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">RAD Roster Report View</Box>
        }
        searchBar={true}
        // filter={roleCode === "R001"}
        setSearchValue={setSearchValue}
        create={  <IconButton
          onClick={handleClose}
        >
          <CloseIcon style={{ border: "2px solid grey", borderRadius: "5px" }} />
        </IconButton>
}
        // filterValue={filterValue}
        // setFilterValue={setFilterValue}
        // filterValues={[
        //   { label: "Employee Id", value: "" },
        //   { label: "VRA Name", value: "employeeName" },
        // ]}
        heading={[
       
          "S.No",
          "First Name",
          "Last Name",
          "RAD Qgenda FullName",
          "RAD SRF Name",
          "Email",
          "Practice",
          "Timezone",
          "Deployed Date",
          "Schedule Pattern",
          "No.of VRA's Needed",
          "Status",
          "Discontinued Date",
          "Reason for Discontinuition",
          "Created On",
          "Updated On",
          "Planned Deployment Date",
        ]}
        data={radScheduleData?.map((e, index) => [
         (offset-1)*20 +index+1,
         e.first_name,
        e. last_name,
        e.rad_qgenda_fullname,
        e.rad_srf_name,
        e.email,
        e.practice,
        e.timezone,
        e.deployed_date ? moment(e.deployed_date).format("DD-MMM-YYYY") : e.deployed_date,
        e.schedule_pattern,
        e.no_of_vra_needed,
        e.status,
        e.discontinued_date? moment(e.discontinued_date).format("DD-MMM-YYYY"): e?.discontinued_date,
        e.discontinued_reason,
        e.created_on ? moment(e.created_on).format("DD-MMM-YYYY"): e?.created_on ,
        e.updated_on ? moment(e.updated_on).format("DD-MMM-YYYY"): e?.updated_on,
        e.planned_deployment_date? moment(e.planned_deployment_date).format("DD-MMM-YYYY") : e.planned_deployment_date
        
         ])}
         offset={offset}
         setOffset={setOffset}
         count={count}
         limit={20}
         placeholder="Search by ID, VRA Name"
      />
    
        </Box>
      </Modal>
    </div>
  )
}

export default RadRosterReport
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// css
import leftarrow from "assets/images/arrow-right.png";

// mui
import Tables from "components/table/table";
import getAllVraShiftAPIS from "apis/panel/vraShifts/vraShifts.js";
import getAllVraRosterAPIS from "apis/panel/vraRoster/vraRoster"
import Context from "context/context";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Margin, Padding } from "../../../../../node_modules/@mui/icons-material/index";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import check from "../../../../assets/images/check.png";
import cross from "../../../../assets/images/remove.png";
import Autocomplete from '@mui/material/Autocomplete';



// react calendar
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Calendar from 'react-calendar'
import EditVraRoster from "./EditVraRoster";

const style = {
  position: 'absolute',
  top: '60%',
  left: '60%',
  transform: 'translate(-60%, -60%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
//   maxHeight: "90vh",
  overflowY: "scroll",
};

const VraRoster= () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode] = useState(state.role.roleCode);
  const navigate = useNavigate();
  const { getAllVraRosterDetailsAPI} = getAllVraRosterAPIS();
  const [empDetails, setEmpDetails] = useState([]);
  const [shiftOptions, setShiftOptions]= useState([]);
  const [radOptions, setRadOptions]= useState([]);
  const [primaryRadiologist, setPrimaryRadiologist] = useState('');
  const [shiftValue , setShiftValue] = useState('');
  const [vraShiftDetails, setVraShiftDetails] = useState([]);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [vraShiftDetailsEditObj, setVraShiftDetailsEditObj] = useState([]);
  const [serialNumber, setSerialNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [findOneData, setFindoneData]= useState([])
  const [employeeCode, setEmployeeCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [status, setStatus] = useState("");
  const [batch, setBatch] = useState("");
  const [trainingStartDate, setTrainingStartDate] = useState("");
  const [trainingEndDate, setTrainingEndDate] = useState("");
  const [certificationDate, setCertificationDate] = useState("");
  const [isTrainingCompleted, setIsTrainingCompleted] = useState(false);
  const [editId,setEditId] = useState('')

  const [ employeeTrainingId,setEmployeeTrainingId]= useState("")
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datesArray, setDatesArray] = useState([]);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleOpen = () => setOpen(true);

  const handleNavigate = () => {
    navigate("/panel/addvra")
  }

  const handleClose= async()=>{
    setEditId('')
    setOpen(false)
  }

  const handleNavigation=()=>{
    navigate("/panel/rpHomePage")
  }
  useEffect(() => {

    handleGetAllVraTrainingDetailsApi();

  }, [offset, searchValue, filterValue, open]);
  

  const handleGetAllVraTrainingDetailsApi = async () => {
  
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        offset: (offset - 1) * 20,
        limit: 20,
      };
      if (searchValue) {
        values = {
            ...values,
          searchValue,
        };
      }
      if (filterValue) {
        values = {
            ...values,
          filterValue,
        };
      }

  
      let res = await getAllVraRosterDetailsAPI(values);
      setVraShiftDetails(res?.data?.vraRosterData || []);
      setCount(res?.data?.count);
      setSerialNumber(offset);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };
  

  
const handleEdit = async(id)=>{
    setOpen(true)
      setEditId(id)
  }

  // useEffect(() => {
  //   handleGetAllVraTrainingDetailsApi()
  // }, [offset, searchValue, filterValue, open])


  return (
    <div
      className="vraShiftsMediaQuery"
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      
      <Tables
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={handleNavigation} style={{ cursor: 'pointer', paddingRight: 8 }}>
            <img src={leftarrow} alt="Back" />
          </div>
          <Box sx={{ pt: 2, pb: 2 }} className="VraShiftsTitle">
            VRA Roster
          </Box>
        </div>
      }
        searchBar={true}
        // filter={roleCode === "R001"}
        //    role={roleCode}
           create={
            //  (roleCode === "R001") &&
             <Button className="AddEmployerButton" onClick={handleNavigate}>
                Add VRA
             </Button>
           }
        setSearchValue={setSearchValue}
        // filterValue={filterValue} 
        // setFilterValue={setFilterValue}
        // filterValues={[
        //   { label: "Employee Id" , value: "" },
        //   { label: "VRA Name", value: "employeeName" },
        //   //  { label: "Employee Email", value: "employeeEmail" },
        // ]}
        heading={[
          "S.No",
          "Employee Id",
          "First Name",
          "Last Name",
          "VRA Full Name",
          "VRA SRF Name",
          "VRA RP UserID",
          "Date of Joining",
          "Primary Shift",
          "Role",
          "Engagement Type",
          "Commitment Date",
          "Deployed Date",
          "Status",
          "Training Start Date",
          "Training End Date",
          "Certification Date",
          "Batch",
          "Action" 
        ]}
        data={vraShiftDetails?.map((e, index) => [
          (offset-1)*20+ index+1,,
              e.employeeCode,
              e.firstName,
              e.lastName,
              e.firstName +","+" ".concat(e.lastName),
              e.srfName,
              e.vraFullName,
             e.dateofJoining? moment(e.dateofJoining).format("DD-MMM-YYYY"): e.dateofJoining,
              e.primaryShift,
              e.role,
              e.engagementType,
            //   moment(e.commitmentDate).format("DD-MMM-YYYY"),
              e.commitmentDate? moment(e.commitmentDate).format("DD-MMM-YYYY"): e.commitmentDate,
            //   moment(e.trainingEndDate).format("DD-MMM-YYYY"),
            //   moment(e.certificationDate).format("DD-MMM-YYYY"),
            //   e.batch,
              e.deployedDate ? moment(e.deployedDate).format("DD-MMM-YYYY"): e.deployedDate,

              //   e.engagementType,
              e.status,
              e.trainingStartingDate? moment(e.trainingStartingDate).format("DD-MMM-YYYY"): e.trainingStartingDate,
              e.trainngEndDate? moment(e.trainngEndDate).format("DD-MMM-YYYY"): e.trainngEndDate,
              e.certificationDate? moment(e.certificationDate).format("DD-MMM-YYYY"): e.certificationDate,
              e.batch,
              
            // e.isTrainingCompleted,
                // <EditIcon style={{color:"#FE6024"}}  onClick={() => handleEdit(e.employeeId)}/>,
          <RemoveRedEyeIcon style={{color:"#FE6024"}} onClick={() => handleEdit(e.vraRosterId)}
                //    onClick={() => handlefindonee(e.vraEmployeeTrainingId)}
           />,
         ])}
         offset={offset}
         setOffset={setOffset}
         limit={20}
         count={count}
         placeholder="Search by VRA SRF Name, Employee Code, VRA Full Name"
      />


<Modal open={open}  onClose={handleClose} aria-labelledby="modal-title"
                aria-describedby="modal-description" >
<Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  height:460,
                  overflowY:'scroll',
                  transform: 'translate(-50%, -50%)',
                  width: 800, 
                  bgcolor: 'background.paper',
                  borderRadius:'10px',
                 
                  p: 4,}} >
                    <EditVraRoster id={editId} onClose={handleClose}/>
        </Box>
      </Modal>

    </div>
  )
}
export default VraRoster
import React, {useEffect, useState,useContext} from 'react';

// mui
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
// import { LocalizationProvider,DesktopDatePicker } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { Paper } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Context from "context/context";


// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { format } from 'date-fns';

import { Typography } from '@mui/material';

import { DayPicker } from "react-day-picker";
// import "react-day-picker/style.css";

import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';


// api's
import getemployees from "apis/panel/timesheet/TimeSheet";




import {
    Chart,
    PieSeries,
    Title
  } from '@devexpress/dx-react-chart-material-ui';
   
   
import "./LeaveDashboard.css";
import { useNavigate } from 'react-router-dom';
import LeaveMainBackup from './LeaveMainBackup';
import LeaveMain from './LeaveMain';
import ManagerLeaveHistory from './managerView/ManagerLeaveHistory';
import EarnCompOff from './EarnCompOff';
// import EarnCompOff from './EarnCompOff';



const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const LeaveDashboard = () => {

    const [value, setValue] = React.useState('1');
    const { state, dispatch } = useContext(Context);
    const [startDate, setStartDate] = useState(new Date());
    const [selected, setSelected] = useState();
    const [employeeId , setEmployeeId]= useState('')
    const [teamView, setTeamView] = useState(false);
    const [showTimeSheet, setShowTimeSheet] = useState(false);
    const [selectedYear, setSelectedYear] = useState(new Date());

    const handleYearChange = (newYear) => {
      setSelectedYear(newYear);
    
      
    };
    

    const { getEmployeeDetailsAPI } = getemployees();

    const toggleComponent = () => {
      setShowTimeSheet((prevState) => !prevState);
    };

    const label = { inputProps: { "aria-label": "Switch demo" } };

    const handleGetEmployees = async () => {
      try {
        dispatch({ type: "SET_LOADING", payload: true });
        let res = await getEmployeeDetailsAPI();
        setEmployeeId(res?.data?.employeeInformation?.employeeId);
        setTeamView(res?.data?.teamView);
       
        dispatch({ type: "SET_LOADING", payload: false });
      } catch (err) { }
    };

    useEffect(()=>{
handleGetEmployees()
    },[])


    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const Navigate= useNavigate()


    
  const handleDateChange = (date) => {

    // setSelectedDate(date);
    // setSelectedWeeks(moment(date).format("YYYY-MM-DD"));
    // getWeekDates(date);
    // handleGetProjects(date);

  };

  
  const size = {
    width: 400,
    height: 200,
    // padding:"160px"
  };
  
  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));
  
  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  const handleLeavereqManageent=()=>{
    Navigate("/panel/leaveApply")
  }

  const handleLeaveHistory=()=>{
    Navigate("/panel/leaveHistory")
  }

  const data = [

    { month: 'Jan', value: '2024' },
    { month: 'Feb', value: '2024' },
    { month: 'Mar', value: '2024' },
    { month: 'Apr', value: '2024' },
    { month: 'May', value: '2024' },
    { month: 'June', value: '2024' },
    { month: 'July', value: '2024' },
    { month: 'Aug', value: '2024' },
    { month: 'Sept', value: '2024' },
    { month: 'Oct', value: '2024' },
    { month: 'Nov', value: '2024' },
    { month: 'Dec', value: '2024' },
    
]

  const renderDay = (day, _, DayComponentProps) => {
    const isWeekend = day.getDay() === 0 || day.getDay() === 6;
    const dayCellStyle = {
      color: isWeekend ? 'red' : 'inherit',
      width: '2em',
      height: '2em',
      lineHeight: '2em',
      textAlign: 'center',
      borderRadius: '50%',
    };
    return <div style={dayCellStyle}>{day.getDate()}</div>;
  };

  return (
  
    <div style={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}>
  {!showTimeSheet ?(

     <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab
                                label="Leave"
                                value="1"
                                className={value === '1' ? 'Leave' : 'nonleaveTab'}
                            />
                            <Tab
                                label="Earn CompOff"
                                value="2"
                                className={value === '2' ? 'Leave' : 'nonleaveTab'}
                            />
                            <Tab
                                label="Holiday List"
                                value="3"
                                className={value === '3' ? 'Leave' : 'nonleaveTab'}
                            />
          </TabList>
        </Box>

        <TabPanel value="1">

        <Grid container spacing={2} className="leavepage-1stgrid">
      {/* Uncomment the below code if you need the Leave Management Heading */}
      <Grid item xs={12} sm={2.5} md={2.5} lg={2.5} sx={{alignSelf:"center" , marginRight:"auto" }}>
        <div className="leavemanageHeading">Leave Dashboard</div>
      </Grid>
      
      <Grid item xs={12} sm={4} md={2} lg={2} justifyContent="center">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            views={['year']}
            className="dateInputforleave"
            value={selectedYear}
            onChange={handleYearChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
            maxDate={new Date()}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} sm={4} md={2.5} lg={2.5}>
        <Button className="rlreqlevreqButton" onClick={handleLeavereqManageent} fullWidth>
          <div >Submit Request</div>
        </Button>
      </Grid>

      <Grid item xs={12} sm={4} md={3} lg={3}>
        <Button className="leavehistory" onClick={handleLeaveHistory} fullWidth>
          <div className="">Leave History</div>
        </Button>
      </Grid>

      {/* {teamView && (
        <Grid item xs={12} sm={12} md={3.5} lg={3.5} sx={{}}>
          <CustomWidthTooltip
            title={<span>Toggle to switch to Manager View</span>}
          >
            <div style={{ display: "inline-flex", width:"100%" }}>
              <div className="tsheads">
                <Switch
                  style={{ color: "gray" }}
                  checked={showTimeSheet}
                  onChange={toggleComponent}
                  {...label}
                />
              </div>
              <div
                className="tsbods"
                style={{ color: "#00B562",paddingTop: 9, whiteSpace: "nowrap" }}
              > 
                  Switch To Manager View
              </div>
            </div>
          </CustomWidthTooltip>
        </Grid>
      )} */}
    </Grid>

      <hr style={{backgroundColor:"#E9E9E9", border:" 1px solid #E9E9E9", width:"100%", maxWidth:"100%", justifyContent:"center", textAlign:"center", marginTop:"1%"}}/>

   <LeaveMainBackup selectedYear={selectedYear}/>
        </TabPanel>
        <TabPanel value="2">
   <EarnCompOff/>

        </TabPanel>
        <TabPanel value="3">
   <LeaveMain/>

        </TabPanel>
        </TabContext>
    </Box>

):
(<>
<ManagerLeaveHistory/>
</>)}
 
    </div>
            
  )
}

export default LeaveDashboard
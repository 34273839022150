import React from 'react';
import "./AddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid'; 
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import contactInfo from './ContactInfo';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";


import personalInfo from "assets/images/Group (1).png";
import ContactInfo from './ContactInfo';
import EducationInfo from './EducationInfo';
import DocumentInfo from './Documentinfo';
import NumberInfo from './NumberInfo';
import InsuranceInfo from './InsuranceInfo';
import BankInfo from './BankInfo';
import EmployementInfo from './EmployementInfo';
import ManagerInfo from './ManagerInfo';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState, useEffect } from "react";
import getAllGenderdrop from"apis/panel/AddEmployee/addEmployee";
import getAllTitlesdrop from "apis/panel/AddEmployee/addEmployee";
import getMaritalstatusAPIdrop from "apis/panel/AddEmployee/addEmployee"
import getcreateemployee from "apis/panel/AddEmployee/addEmployee"
import getallbloods from "apis/panel/AddEmployee/addEmployee"
import axios from '../../../../node_modules/axios/index';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { toast } from "react-toastify";




const AddEmployee = () => {
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fathersName, setFathersName] = useState('');
  const [mothersName, setMothersName] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [maritalStatusId, setMaritalStatusId]= useState('')
  const [spouseName, setSpouseName] = useState('');
  const [businessMail, setBusinessMail] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [personalMail, setPersonalMail] = useState('');
  const [personalPhone, setPersonalPhone] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [gender, setGender] = useState('');

  const [spouseNameDisabled, setSpouseNameDisabled] = useState(true);
  const [titleOptions, setTitleOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
  const [titleId, setTitleId] = useState('');
  const [genderId, setgenderId] = useState('');
  const [bloodgroup, setbloodgroup] = useState('');
  const[maritalid,setmaritalid]=useState('')
  const[bloodgroupid,setbloodgroupid]=useState('')
  const [bloodgroupoption, setbloodgroupoption] = useState([]);

  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleCancel = () => {
    setAccordionOpen(false); 
  };

//   const [createObj, setCreateObj] = useState({
//           titleId: "",
//           firstName: "",
//           lastName: "",
//           middleName: "",
//           genderId: "",
//           maritalStatusId: "",
//           dateOfBirth: "",
//           businessEmail: "",
//           personalEmail: "",
//           businessPhoneNumber: "",
//           personalPhoneNumber: "",
//           bloodGroupId: "1",
//           gender:"1"
// });


const [errorFields, setErrorFields] = useState({
  title: false,
  firstName: false,
  lastName: false,
  maritalStatus: false,
  fathersName:false,
  mothersName:false,
  spouseName:false,

  businessMail: false,
  businessPhone: false,
  personalMail: false,
  personalPhone: false,
  dateOfBirth: false,
  gender: false,
  bloodgroup:false,
  
});







  // api's

  const {getAllTitleAPI} = getAllTitlesdrop();
  const { getEmployeeCreateAPI } = getcreateemployee();
  // const {getAllBloodsAPI} = getAllblooddrop();
 
    const {getAllGenderAPI}= getAllGenderdrop()
    const {getMaritalStatusAPI} = getMaritalstatusAPIdrop()
    const {getAllBloodsAPI}= getallbloods()

    const handleCreateLeave = async (e) => {
        e.preventDefault();
        // Validate form fields
        const errors = {};
        if (!title) errors.title = true;
        if (!firstName) errors.firstName = true;
        if (!lastName) errors.lastName = true;
        // if (!lastName) errors.middleName = true;
        if (!fathersName) errors.fathersName = true;
        if (!mothersName) errors.mothersName = true;
        // if (!spouseName) errors.spouseName = true;
        if (!bloodgroup) errors.bloodgroup = true;
        if (!maritalStatus) errors.maritalStatus = true;
        if (!businessMail) errors.businessMail = true;
        // if (!businessPhone) errors.businessPhone = true;
        if (!personalMail) errors.personalMail = true;
        if (!personalPhone) errors.personalPhone = true;
        if (!dateOfBirth) errors.dateOfBirth = true;
        if (!gender) errors.gender = true;
        if (maritalStatus === 'Married' && !spouseName) {
          errors.spouseName = true;
      }
  
      
    
        setErrorFields(errors);
    
        if (Object.keys(errors).length === 0) {
        
     
      const values = {
        titleId: titleId,
        firstName: firstName,
        lastName: lastName,
        middleName:middleName,
        maritalStatusId: maritalid,
        fatherName:fathersName,
        motherName:mothersName,
        spouseName:spouseName,
        dateOfBirth:  moment(dateOfBirth).format("YYYY-MM-DD"),
        businessEmail: businessMail,
        personalEmail: personalMail,
        businessPhoneNumber: businessPhone,
        personalPhoneNumber: personalPhone,
        bloodGroupId: bloodgroupid,
        genderId:genderId
       
      };

      console.log(values,"values")
      
      const data = await getEmployeeCreateAPI(values);

      if(data.status === 'Ok'){
        sessionStorage.setItem('EmployeeID',data.data.employeeId)       
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
      }
      else{
        return toast.error("Error: User already exists in the system");
      }
 
    }


  };

  useEffect(()=>{
    handleGetAllTitles()
    handleGetAllMaritalStatus()
    handleGetAllGendersApi()
    handleGetAllbloodgroup()
},[])

const handleReset = () => {
  setTitle('')
  setFirstName('')
  setMiddleName('')
  setLastName('')
  setMaritalStatus('')
  setFathersName('')
  setMothersName('')
  setSpouseName('')
  setBusinessMail('')
  setBusinessPhone('')
  setPersonalMail('')
  setPersonalPhone('')
  setDateOfBirth('')
  setGender('')
 setbloodgroup('')
  
    
  
  


  

  

}


const handleGetAllTitles = async () => {
  try {
      let res = await getAllTitleAPI();
      setTitleOptions(res?.data || []);
  }
  catch (err) {}
};




const handleGetAllMaritalStatus = async () => {
  try {
      let res = await getMaritalStatusAPI();
      setMaritalStatusOptions(res?.data || []);
  }
  catch (err) {}
};

const handleGetAllGendersApi = async () => {
  try {
      let res = await getAllGenderAPI();
      setGenderOptions(res?.data || []);
  }
  catch (err) {}
};

const handleGetAllbloodgroup = async () => {
  try {
      let res = await getAllBloodsAPI();
      setbloodgroupoption(res?.data || []);
  }
  catch (err) {}
};

const handleTitleChange = (e) => {
  const selectedTitle = titleOptions.find(option => option.titleName === e.target.value);
  setTitle(selectedTitle.titleName); // Set the titleName
  setTitleId(selectedTitle.titleId); // Set the titleId
};

  const handleFirstNameChange = (event) => {
    const input = event.target.value;
    setFirstName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, firstName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, firstName: false });
    }
  };
  
  const handleLastNameChange = (event) => {

    const input = event.target.value;
  
    setLastName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, lastName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, lastName: false });
    }
   
  };
  const handleMiddleChange = (e) => {
    setMiddleName(e.target.value);
  };
  const handleFatherNameChange = (event) => {
    const input = event.target.value;
  
    setFathersName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, fathersName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, fathersName: false });
    }
  
  };
  const handleMotherNameChange = (event) => {

    const input = event.target.value;
  
    setMothersName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, mothersName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, mothersName: false });
    }
    
  };
  const handleSpouseNameChange = (event) => {
    const input = event.target.value;
  
    setSpouseName(input);

    // Check if the first character is not capitalized
    if (input.length > 0 && input.charAt(0) !== input.charAt(0).toUpperCase()) {
        // Set the error flag to true if not capitalized
        setErrorFields({ ...errorFields, spouseName: true });
    } else {
        // Reset the error flag if the input is valid
        setErrorFields({ ...errorFields, spouseName: false });
    }
   
  };
  // const handleBusinessMailChange = (e) => {
  //   setBusinessMail(e.target.value);
  // };

  const handleBusinessMailChange = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s/g, '');
    setBusinessMail(value);
    if (!value) {
      setErrorFields({ ...errorFields, businessMail: true });
    } else {
      setErrorFields({ ...errorFields, businessMail: false });
    }
  };
  const handleBusinessNumberChange = (e) => {
    setBusinessPhone(e.target.value);
  };
  // const handlePersonalMailChange = (e) => {
  //   setPersonalMail(e.target.value);
  // };


  const handlePersonalMailChange = (event) => {
    const value = event.target.value.toLowerCase().replace(/\s/g, '');
    setPersonalMail(value); 

    if (!value) {
      setErrorFields({ ...errorFields, personalMail: true });
    } else {
      setErrorFields({ ...errorFields, personalMail: false });
    }
  };


const handlePersonalNumberChange = (e) => {
    setPersonalPhone(e.target.value);
  };

const handleDobAccept = (date) => {
  setDateOfBirth(date);
};

const handleGenderChange = (e) => {
  const selectedgender = genderOptions.find(option => option.genderName === e.target.value);
  setGender(selectedgender.genderName);
  setgenderId(selectedgender.genderId)
};

const handlemaritalChange = (e) => {
  const selectedmarital = maritalStatusOptions.find(option => option.maritalStatusName === e.target.value);
  setMaritalStatus(selectedmarital.maritalStatusName);
  setmaritalid(selectedmarital.maritalStatusId);

  // Check if the selected marital status is 'Unmarried'
  if (selectedmarital.maritalStatusName === 'Single') {
      // Disable the spouse name field and change its background color to blue
      setSpouseNameDisabled(true);
  } else {
      // Enable the spouse name field and reset its background color
      setSpouseNameDisabled(false);
  }
};

const handlebloodChange = (e) => {
  const selectedblood = bloodgroupoption.find(option => option.bloodGroupName === e.target.value);
  setbloodgroup(selectedblood.bloodGroupName); // Set the titleName
  setbloodgroupid(selectedblood.bloodGroupId); // Set the titleId
};









  
  const handleMaritalStatusChange = (e) => {
    const selectedTitle = maritalStatusOptions.find(option => option.maritalStatusName === e.target.value);
    setMaritalStatus(selectedTitle.maritalStatusName)
    setMaritalStatusId(selectedTitle.maritalStatusId)
    // setMaritalStatus(value);
 
  };

  const maxDate = new Date();
  


  return (
    <div style={{backgroundColor:"white"}}>
      <Accordion expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)} sx={{ mt: 2, boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className="Accordianicon"/>}
          aria-controls="panel1-content"
          id="panel1-header"
          
          // onClick={toggleAccordion}
          style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px", height:"70px"}}
        >
          <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" /></Box>
          <Box className="acc-title" sx={{ pl: 3 }}>Personal Information</Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}  sx={{ pl: 4, pr:5 }} >
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
                <Box className="label">Title <span style={{color:"red"}}>*</span></Box>
              <FormControl fullWidth>
                <Select
                  labelId="title-label"
                  id="title" 
                  style={{border: "2px solid #B2D4F8",color:"grey"}}
                  defaultValue=""
                  name="title"
                  value={title}
                  onChange={handleTitleChange}

                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                         <MenuItem value="">
               Select Title
          </MenuItem>
        
          {titleOptions.map((option) => (
          <MenuItem key={option.id} value={option.titleName}>{option.titleName}</MenuItem>
        ))}
                </Select>
              </FormControl>
              {errorFields.title && <span className="error-msg">Title is required</span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
              <Box className="label">First Name <span style={{ color: "red" }}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="firstName" value={firstName} onChange={handleFirstNameChange} placeholder="First Name" variant="outlined" fullWidth />
              {errorFields.firstName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Middle Name </Box>
              <TextField  sx={{ border: '1px solid #B2D4F8' }} value={middleName}  name="middleName"  onChange={handleMiddleChange} id="middleName" placeholder="Middle Name" variant="outlined" fullWidth />
              {errorFields.middleName && <span className="error-msg">This is required Field</span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
              <Box className="label">Last Name <span style={{ color: "red" }}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="lastName" value={lastName} name="lastName" onChange={handleLastNameChange} placeholder="Last Name" variant="outlined" fullWidth />
              {errorFields.lastName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Father's Name <span style={{color:"red"}}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="fathersName"  value={fathersName}  name="fathersName"  onChange={handleFatherNameChange} placeholder="Father Name" variant="outlined" fullWidth />
              {errorFields.fathersName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Mother's Name <span style={{color:"red"}}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="fathersName" value={mothersName}  name="mothersName"  onChange={handleMotherNameChange} placeholder="Mother Name" variant="outlined" fullWidth />
              {errorFields.mothersName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>
        
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Marital Status <span style={{color:"red"}}>*</span></Box>
              <FormControl fullWidth>
                {/* <InputLabel id="marital-status-label">Marital Status</InputLabel> */}
                <Select
                  labelId="marital-status-label"
                  id="maritalStatus"
                  defaultValue=""
                  displayEmpty
                  name="maritalStatus"
                  value={maritalStatus}
                  // sx={{ border: '2px solid #B2D4F8' }}
                  onChange={handlemaritalChange}
                  style={{border:"2px solid #B2D4F8",color:"grey"}}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
          <MenuItem value="">
               Select Marital Status
          </MenuItem>
                  {/* <MenuItem value="Single">Single</MenuItem> */}
        {maritalStatusOptions.map((option) => (
          <MenuItem key={option.id} value={option.maritalStatusName}>{option.maritalStatusName}</MenuItem>
        ))}
                </Select>
              </FormControl>
              {errorFields.maritalStatus && <span className="error-msg">This is required Field</span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Spouse Name <span style={{color:"red"}}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8',backgroundColor: spouseNameDisabled ? '#B2D4F8' : '' }} id="spouseName" value={spouseName}  name="spouseName"  onChange={handleSpouseNameChange} placeholder="Spouse Name" variant="outlined" fullWidth  disabled={spouseNameDisabled} />
              {errorFields.spouseName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Business Email <span style={{color:"red"}}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="businessMail" value={businessMail}  name="businessMail"  onChange={handleBusinessMailChange} placeholder="Business Email" variant="outlined" fullWidth />
              {errorFields.businessMail && <span className="error-msg">This is required Field</span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Business Phone</Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="businessPhone" value={businessPhone}  name="businessPhone"  onChange={handleBusinessNumberChange} placeholder="Business Phone" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Personal Email<span style={{color:"red"}}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }}id="personalMail" value={personalMail}  name="personalMail"  onChange={handlePersonalMailChange} placeholder="Personal Email" variant="outlined" fullWidth />
              {errorFields.personalMail && <span className="error-msg">This is required Field</span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Personal Phone <span style={{color:"red"}}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="personalPhone" value={personalPhone}  name="personalPhone"  onChange={handlePersonalNumberChange} placeholder="Personal Phone" variant="outlined" fullWidth />
              {errorFields.personalPhone && <span className="error-msg">This is required Field</span>}
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Date of Birth <span style={{color:"red"}}>*</span></Box>
            <LocalizationProvider  fullWidth dateAdapter={AdapterDateFns}>   
            <DatePicker
             id="dateOfBirth"
             name="dateOfBirth"
             value={dateOfBirth}
             sx={{width:"100%",border:"2px solid #B2D4F8"}}
             onAccept={handleDobAccept}
             fullWidth
             inputFormat="dd/MM/yyyy"
             slotProps={{
            field: { clearable: true },
          }}
            maxDate={maxDate}
            renderInput={(params) => <TextField {...params} fullWidth />}
             />
             </LocalizationProvider>
                  {errorFields.dateOfBirth && <span className="error-msg">This is required Field</span>}
            </Grid>


            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Gender <span style={{color:"red"}}>*</span></Box>
            <FormControl fullWidth>
            {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
            <Select
              labelId="marital-status-label"
              id="maritalStatus"
              placeholder="Select Date"
              defaultValue=""
              displayEmpty
          
              // sx={{ border: '2px solid #B2D4F8' }}
              name="gender"
        value={gender}
        onChange={handleGenderChange}
              inputProps={{ 'aria-label': 'Without label' }}
                   style={{border:"2px solid #B2D4F8", color:"grey"}}
                >
                       <MenuItem value="">
               Select Gender
          </MenuItem>
          {genderOptions.map((option) => (
          <MenuItem key={option.id} value={option.genderName}>{option.genderName}</MenuItem>
        ))}
            </Select>
          </FormControl>
          {errorFields.gender && <span className="error-msg">This is required Field</span>}
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Blood Group <span style={{color:"red"}}>*</span></Box>
               <FormControl fullWidth>
               {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
               <Select
                 labelId="marital-status-label"
                 id="maritalStatus"
                 placeholder="Select Date"
                 defaultValue=""
                 displayEmpty
                 value={bloodgroup}
                //  value={bloodGroup}
             
                 // sx={{ border: '2px solid #B2D4F8' }}
                 name="gender"
          //  value={gender}
           onChange={handlebloodChange}
                 inputProps={{ 'aria-label': 'Without label' }}
                      style={{border:"2px solid #B2D4F8", color:"grey"}}
                   >
                          <MenuItem value="">
                  Select Blood Group
             </MenuItem>
             {bloodgroupoption.map((option) => (
             <MenuItem key={option.id} value={option.bloodGroupName}>{option.bloodGroupName}</MenuItem>
           ))}
               </Select>
             </FormControl>
             {errorFields.bloodgroup && <span className="error-msg">This is required Field</span>}
            </Grid>
        
            
          </Grid>

      
<Grid container  sx={{ pl: 4, pr:5, mt:5 , justifyContent:"center"}} >
        <Grid item  sx={{ml:1}} xs={12} sm={3} md={3} lg={3}>
        <Button className="resetChanges" onClick={handleReset}>Reset Changes</Button>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
        </Grid>

        <Grid item  sx={{mr:1}} xs={12} sm={2} md={2} lg={2}>
        <Button className="saveButton" onClick = {handleCreateLeave}>Save</Button>
        </Grid>

        </Grid>
        </AccordionDetails>
      </Accordion>



  
    
    </div>
  )
}

export default AddEmployee;

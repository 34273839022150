import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Context from "context/context";
import { toast } from "react-toastify";
import React from 'react';
import { useEffect,useContext ,useState } from 'react';
import leftarrow from "assets/images/arrow-right.png";

import imports from "../../../../assets/images/rpHomePage/4022440.jpg";
import settings from "../../../../assets/images/settings.png"
import vra from "../../../../assets/images/rpHomePage/vra.png";
import vra2 from "../../../../assets/images/rpHomePage/vra2.jpg";
import caoch from "../../../../assets/images/rpHomePage/8294.jpg";
import report from "../../../../assets/images/rpHomePage/313.jpg";
import affinity from "../../../../assets/images/rpHomePage/aff.png";
import substitution from "../../../../assets/images/rpHomePage/substitution.png";
import Delete from "../../../../assets/images/rpHomePage/saveforsetting.png";
import emailtemp from "../../../../assets/images/rpHomePage/emailtemp.png"
import vraShiftsAPI from 'apis/panel/vraShifts/vraShifts';
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";



// import "./RpHome.css";

const Settingss = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(Context);
    const {deleteTableAPI}= vraShiftsAPI();
    const [open, setOpen] = useState(false);
    const [tableName, setTableName] = useState("");
    const [cardName, setCardName] = useState("");


    const handleOpen = (name, cardName) => {
        setTableName(name);
        setCardName(cardName);
        setOpen(true);
    };
    console.log(tableName,"table")


    const handleClose=()=>{
        setOpen(false);
    }

    const handleNavigation=()=>{
        navigate("/panel/rpHomepage")
      }

    const handleDeleteAffinity = async (e) => {
        e.preventDefault()
        const values = { TableName: tableName }; 
        dispatch({ type: "SET_LOADING", payload: true });
        const res = await deleteTableAPI(values);
        if (res.status === 'Ok') {
            dispatch({ type: "SET_LOADING", payload: false });
            handleClose()
            const successMessage = `${res?.data?.rowsToDelete} Records ${res.data.message}`
            return toast.success(successMessage);
        } else {
            dispatch({ type: "SET_LOADING", payload: false });
            handleClose()
            return toast.error("Error: Deletion Failed");
        }
    };

    const handlevraRosterNavigate = () => navigate("/panel/vraRoster");
    const handleCard1Navigate = () => navigate("/panel/radRoster");
    const handleCard2Navigate = () => navigate("/panel/vraScheduling");
    const handleCard3Navigate = () => navigate("/panel/rpreports");
    const handleCard4Navigate = () => navigate("/panel/trainingsetup");
    const handleCardQgendaNavigate = () => navigate("/panel/addAffinity");
    const handleAffinityNavigate=()=> navigate("/panel/vraRadAffinity");
    const handleCardVRASchedulingNavigate=()=> navigate("/panel/vraScheduling")
    const handleVraSubTemplate=()=>navigate('/panel/vraSubstitutionEmailTemplate')

    return (
        <div style={{
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "100vh"
        }}>
           <div style={{ display: 'flex', alignItems: 'center' }}>
      <div onClick={handleNavigation} style={{ cursor: 'pointer', paddingRight: 8 }}>
        <img src={leftarrow} alt="Back" />
      </div>
      <Box sx={{ pt: 2, pb: 2 }} className="VraShiftsTitle">
      Settings
      </Box>
    </div>
            <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item xs={12} md={3}>
                    <Card onClick={handleCard2Navigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={imports}
                                alt="Import SRF Data"
                                sx={{
                                    height: '150px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                   Build VRA Schedule
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>   
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card onClick={handleCardQgendaNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column'}}>
                            <CardMedia
                                component="img"
                                image={imports}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                 Build RAD VRA Affinity
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} md={3}>
                    <Card onClick={handleVraSubTemplate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={emailtemp}
                                alt="Import SRF Data"
                                sx={{
                                    height: '150px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                   VRA substitution template
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>   
                </Grid>
           
                <Grid item xs={12} md={3}>
                    <Card onClick={() => handleOpen('vra_rad_affinity',  'RAD VRA Affinity Data')}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={Delete}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '60%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                Delete RAD VRA Affinity Data
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
    

                <Grid item xs={12} md={3}>
                    <Card onClick={() => handleOpen('rad_monthly_schedule_forecast', 'RAD Schedule Forecast Data')}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={Delete}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '60%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }} 
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini' style={{whiteSpace:"nowrap"}}>
                                 Delete RAD Schedule Forecast Data
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} md={3}>
                    <Card onClick={() => handleOpen('rad_monthly_schedule_actuals','RAD Actual Data')}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={Delete}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '60%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                  Delete RAD Actual Data
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} md={3}>
                    <Card onClick={() => handleOpen('vra_monthly_schedule_forecast','VRA Schedule Data')}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={Delete}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '60%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                Delete VRA Schedule Data
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ bgcolor: 'white', p: 4, borderRadius: 2, boxShadow: 24, width: 400, margin: 'auto', mt: '10%' }}>
                    <Typography className="addnewprojecthead" variant="h6">Confirm Deletion</Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete data for {cardName} ?
                    </Typography>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                        <Button   className="cancelButton"  onClick={handleClose}>Cancel</Button>
                        <Button   className="saveButton"  onClick={handleDeleteAffinity}>Confirm</Button>
                    </Box>
                </Box>
            </Modal>

        </div>
    );
};

export default Settingss;

import React, { useEffect, useContext, useState, useRef } from 'react';
import config from "config/config";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Box, Tooltip, TextField, Switch, FormControlLabel } from '@mui/material';
import Context from "context/context";
import ReusableTable from "components/table/table";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import "./HRview.css";
import check from "../../../../assets/images/check.png";
import cross from "../../../../assets/images/remove.png";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import AppraisalCycle from './AppraisalCycle';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const HRview = () => {
  const { state, dispatch } = useContext(Context);
  const [kpiData, setKpiData] = useState([]);
  const [kpiKraData, setKpiKraData] = useState([]);
  const [count, setCount] = useState(0);
  const [value, setValue] = useState("1");
  const [offset, setOffset] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [kpiDataduplicate, setKpiDataduplicate] = useState([]);
  const kpiDataduplicateRef = useRef(kpiDataduplicate);
  const [openAddKpiModal, setOpenAddKpiModal] = useState(false);
  const [newKpi, setNewKpi] = useState({
    kraCategory: '',
    kpiName: '',
    weightage: '',
    assignedFor: '',
  });

  const [errors, setErrors] = useState({
    kraCategory: false,
    kpiName: false,
    weightage: false,
    assignedFor: false
  });



  const limit = 10;

  const fetchKpiData = async (search = "") => {
    try {
      const query = encodeURIComponent(
        JSON.stringify({
          offset: (offset - 1) * limit,
          limit: limit,
          searchValue: search,
        })
      );
      dispatch({ type: "SET_LOADING", payload: true });
      const url = `${config.host}/panel/pms/allKpi?query=${query}`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response data:", errorData);
        throw new Error(`HTTP error! status: ${response.status}, ${errorData.message || ""}`);
      }

      const data = await response.json();

      if (data.status === "Ok" && Array.isArray(data.data.kpiKraData)) {
        const formattedData = data.data.kpiKraData.map((kpi, index) => [
          index + 1 + (offset - 1) * limit,
          kpi.kraCategory,
          kpi.kpiName,
          kpi.weightage ?? "N/A",
          kpi.role,
          kpi.isActive ? (
            <img style={{ height: "20px" }} src={check} alt="" />
          ) : (
            <img style={{ height: "20px" }} src={cross} alt="" />
          ),
          <Tooltip title="Click to view details">
            <span className="jobType">
              <RemoveRedEyeIcon
                style={{ color: '#E97000', cursor: 'pointer' }}
                onClick={() => handleViewClick(kpi.kpiKraId)}
              />
            </span>
          </Tooltip>,
        ]);
        setKpiData(formattedData);
        setCount(data.data.count);
        dispatch({ type: "SET_LOADING", payload: false });
      } else {
        console.error("Unexpected data format:", data);
        setKpiData([]);
      }
    } catch (error) {
      console.error("Error fetching KPI data:", error);
    }
  };





  useEffect(() => {
    fetchKpiData(searchValue);
  }, [offset, searchValue]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    console.log("hi")
    setSearchValue(event.target.value);
  };
  useEffect(() => {
    kpiDataduplicateRef.current = kpiDataduplicate;
  }, [kpiDataduplicate]);

  // const handleViewClick = (kpiKraId) => {
  //   const currentKpiDataduplicate = kpiDataduplicateRef.current;
  //   const kpiDetails = currentKpiDataduplicate.find(kpi => kpi.kpiKraId === kpiKraId);

  //   if (!kpiDetails) {
  //     console.warn("KPI with the specified ID not found");
  //     return;
  //   }

  //   // Proceed with your logic
  //   setSelectedKpi(kpiDetails);

  //   setIsActive(kpiDetails.isActive);
  //   setOpenModal(true);
  // };
  const handleViewClick = async (kpiKraId) => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      // Make API call
      const response = await fetch( `${config.host}/panel/pms/updateKpi?kpiKraId=${kpiKraId}`, {
        method: 'GET',
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      
      const result = await response.json();
      console.log("API response:", result);
  
      if (result.status === "Ok" && result.data.kpiKraData.length > 0) {
        const kpiDetails = result.data.kpiKraData[0];
        
        // Update the relevant states
        setSelectedKpi(kpiDetails);
        setIsActive(kpiDetails.isactive);
        kpiDataduplicateRef.current = result.data.kpiKraData;
        setOpenModal(true);
        dispatch({ type: "SET_LOADING", payload: false });
      } else {
        dispatch({ type: "SET_LOADING", payload: false });
        console.warn("KPI data not found in the response.");
      }
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.error("Failed to fetch KPI data:", error);
    }
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedKpi(null);
    setErrors({});
    setIsActive(false);
  };

  const handleToggleActiveStatus = () => {
    setIsActive(!isActive);
  };

  const handleSaveKpi = async () => {
    const newErrors = {
      kraCategory: selectedKpi.kraCategory ? "" : "KRA Category is required",
      kpiName: selectedKpi.kpiName ? "" : "KPI Name is required",
      weightage: selectedKpi.weightage ? "" : "Weightage is required",
      role: selectedKpi.role ? "" : "Role is required",
    };
  
    if (Object.values(newErrors).some(error => error)) {
      setErrors(newErrors);
      toast.error("Please fill in all required fields");
      return;
    }
    dispatch({ type: "SET_LOADING", payload: true });
    if (!selectedKpi) return;

    const updatedKpi = {
      kpiKraId: String(selectedKpi.kpiKraId),
      kpiName: selectedKpi.kpiName,
      kraCategory: selectedKpi.kraCategory,
      weightage: String(selectedKpi.weightage),
      assignedFor: selectedKpi.role,
      isActive: selectedKpi.isActive,
    };

    try {
      const response = await fetch(`${config.host}/panel/pms/updateKpi`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedKpi),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error updating KPI:", errorData);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.status === "Ok" && data.data.updated === 1) {
        const updatedKpiDataduplicate = kpiDataduplicate.map(kpi =>
          kpi.kpiKraId === updatedKpi.kpiKraId
            ? { ...kpi, ...updatedKpi, isActive }
            : kpi
        );

        setKpiDataduplicate(updatedKpiDataduplicate);


        const formattedData = updatedKpiDataduplicate.map(kpi => [
          kpi.kraCategory,
          kpi.kpiName,
          kpi.weightage ?? "N/A",
          kpi.role,
          kpi.isActive ? (
            <img style={{ height: "20px" }} src={check} alt="" />
          ) : (
            <img style={{ height: "20px" }} src={cross} alt="" />
          ),
          <Tooltip title="Click to view details">
            <span className="jobType">
              <RemoveRedEyeIcon
                style={{ color: '#E97000', cursor: 'pointer' }}
                onClick={() => handleViewClick(kpi.kpiKraId)}
              />
            </span>
          </Tooltip>
        ]);

        setKpiData(formattedData);
        fetchKpiData(searchValue);
        dispatch({ type: "SET_LOADING", payload: false });
        toast.success("KPI updated successfully");
      }

      handleCloseModal();
    } catch (error) {
      console.error("Error saving KPI:", error);
      toast.error("Failed to update KPI");
    }
  };
  const handleAddKra = () => {
    setOpenAddKpiModal(true);
  };

  const handleCloseAddKpiModal = () => {
    setOpenAddKpiModal(false);
    setNewKpi({ kraCategory: '', kpiName: '', weightage: '', assignedFor: '' });
  };

  const handleSaveNewKpi = async () => {
    const newErrors = {
      kraCategory: newKpi.kraCategory.trim() === '',
      kpiName: newKpi.kpiName.trim() === '',
      weightage: newKpi.weightage.trim() === '',
      assignedFor: newKpi.assignedFor.trim() === ''
    };
  
    setErrors(newErrors);
  
    // If any required fields are empty, show error messages and don't proceed
    if (Object.values(newErrors).includes(true)) {
      toast.error("Please fill in all required fields");
      return;
    }
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const response = await fetch(`${config.host}/panel/pms/createKpi`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newKpi),
      });

      const data = await response.json();

      if (data.status === "Error") {
        console.error("Error adding new KPI:", data.message);
        dispatch({ type: "SET_LOADING", payload: false });
        toast.error(data.message);
        return;
      }

      if (data.status === "Ok") {
        dispatch({ type: "SET_LOADING", payload: false });
        fetchKpiData(searchValue);

        toast.success("KPI added successfully");
        handleCloseAddKpiModal();
      }
    } catch (error) {
      console.error("Error saving new KPI:", error);
      dispatch({ type: "SET_LOADING", payload: false });
      toast.error("Failed to add KPI");
    }
  };








  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="HR View Tabs">
              <Tab label="Set KRA/KPI" value="1" className={value === '1' ? 'Leave' : 'nonleaveTab'} />
              <Tab label="Appraisal Cycle" value="2" className={value === '2' ? 'Leave' : 'nonleaveTab'} />
              <Tab label="Year End Rating" value="3" className={value === '3' ? 'Leave' : 'nonleaveTab'} />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '100%', overflowX: 'auto' }}>

                <ReusableTable
                  title={<div className="leavemanageHeading">KRA/KPI LIST</div>
                  }
                  create={<Button className="AddKrabutton" onClick={handleAddKra}>
                    Add KRA/KPI
                  </Button>}
                  searchBar
                  data={Array.isArray(kpiData) ? kpiData : []}
                  count={count}
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  setSearchValue={setSearchValue}
                  heading={["S.No", "KRA", "KPI Name", "Weightage", "Role", "Status", "Action"]}
                  placeholder="Search KRA..."
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <AppraisalCycle />
          </TabPanel>
          <TabPanel value="3">
            <div>Year End Rating content goes here
            </div>
          </TabPanel>
        </TabContext>
      </Box>
      <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="kpi-details-modal" aria-describedby="modal-description">
        <Box className="modalContainer">
          <h2 id="kpi-details-modal">Edit KPI Details</h2>
          {selectedKpi ? (

            <Grid container spacing={2}>
              <Grid container spacing={1}><Grid item xs={12} sm={6}>
                <CustomWidthTooltip
                  title={
                    <span>
                      Toggle to switch KPI status between Active and Inactive.
                    </span>
                  }
                >
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <Switch
                      checked={selectedKpi.isActive === true}
                      onChange={(e) =>
                        setSelectedKpi({ ...selectedKpi, isActive: e.target.checked })
                      }
                      color="primary"
                    />
                    <span style={{ color: selectedKpi.isActive ? "#00B562" : "gray", paddingTop: 9 }}>
                      {selectedKpi.isActive ? "Active" : "Inactive"}
                    </span>
                  </div>
                </CustomWidthTooltip>
              </Grid></Grid>

              <Grid item xs={12} sm={6}>
                <Box className="labelkra">
                  KRA Category <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={selectedKpi.kraCategory}
                  onChange={(e) => {
                    setSelectedKpi({ ...selectedKpi, kraCategory: e.target.value });
                    setErrors({ ...errors, kraCategory: "" });
                  }}
                  error={!!errors.kraCategory}
                  helperText={errors.kraCategory}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="labelkra">
                  KPI Name <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={selectedKpi.kpiName}
                  onChange={(e) => {
                    setSelectedKpi({ ...selectedKpi, kpiName: e.target.value });
                    setErrors({ ...errors, kpiName: "" });
                  }}
                  error={!!errors.kpiName}
                  helperText={errors.kpiName}

                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="labelkra">
                  Weightage <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="number"
                  value={selectedKpi.weightage}
                  onChange={(e) => {
                    setSelectedKpi({ ...selectedKpi, weightage: e.target.value });
                    setErrors({ ...errors, weightage: "" });
                  }}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 100,
                      step: 1,
                    },
                  }}
                  error={!!errors.weightage}
                  helperText={errors.weightage}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="labelkra">
                  Role <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={selectedKpi.role}
                  onChange={(e) => {
                    setSelectedKpi({ ...selectedKpi, role: e.target.value });
                    setErrors({ ...errors, role: "" });
                  }}
                  error={!!errors.role}
                  helperText={errors.role}

                />
              </Grid>
              <Grid item xs={12} className="buttonContainer">
                <Button variant="contained" className="saveButton" onClick={handleSaveKpi}>
                  Save
                </Button>
                <Button variant="outlined" className="cancelButton" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          ) : (
            <div>Loading...</div>
          )}
        </Box>
      </Modal>
      {/* Add Kra/Kpi modal */}
      <Modal open={openAddKpiModal}
        onClose={handleCloseAddKpiModal}
        aria-labelledby="kpi-details-modal" aria-describedby="modal-description">
        <Box className="modal-content">
          <h2 id="kpi-details-modal">Add New KPI</h2>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box className="labelkra" >
                KRA Category <span style={{ color: "red" }}>*</span>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                value={newKpi.kraCategory}

                onChange={(e) => setNewKpi({ ...newKpi, kraCategory: e.target.value })}
                error={errors.kraCategory}
            helperText={errors.kraCategory ? "KRA Category is required" : ""}

              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="labelkra">
                KPI Name <span style={{ color: "red" }}>*</span>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                value={newKpi.kpiName}
                onChange={(e) => setNewKpi({ ...newKpi, kpiName: e.target.value })}
                error={errors.kpiName}
            helperText={errors.kpiName ? "KPI Name is required" : ""}

              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="labelkra">
                Weightage <span style={{ color: "red" }}>*</span>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                value={newKpi.weightage}
                onChange={(e) => setNewKpi({ ...newKpi, weightage: e.target.value.toString() })}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 100,
                    step: 1,
                  },
                }}
                error={errors.weightage}
                helperText={errors.weightage ? "Weightage is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="labelkra">
                Role <span style={{ color: "red" }}>*</span>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                value={newKpi.assignedFor}
                onChange={(e) => {
                  const value = e.target.value;
                  setNewKpi({ ...newKpi, assignedFor: value });
                  console.log('newKpi.assignedFor:', newKpi);
                }}
                error={errors.assignedFor}
            helperText={errors.assignedFor ? "Role is required" : ""}



              />
            </Grid>
            <Grid item xs={12} className="buttonContainer">
              <Button variant="contained" className="saveButton" onClick={handleSaveNewKpi}>
                Save
              </Button>
              <Button variant="outlined" className="cancelButton" onClick={handleCloseAddKpiModal}>
                Cancel
              </Button>
            </Grid>
          </Grid>

        </Box>
      </Modal>


    </div>
  );
};

export default HRview;
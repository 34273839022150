import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

// css
import "./VraSubstitutions.css"
// mui
import Tables from "components/table/table";

import Context from "context/context";
import leftarrow from "assets/images/arrow-right.png";
import Box from '@mui/material/Box';
import { format } from 'date-fns';


import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import { toast } from "react-toastify";
import 'react-calendar/dist/Calendar.css';
import Autocomplete from "@mui/material/Autocomplete";
import Button from '@mui/material/Button';
import vraSubstitutionAPI from 'apis/panel/vraShifts/vraShifts';
import * as moment from "moment"
import swal from 'sweetalert';
// import * as format from "format"


const style = {
  position: 'absolute',
  top: '60%',
  left: '60%',
  transform: 'translate(-60%, -60%)',
  width: "98%",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  maxHeight: "90vh",
  overflowY: "scroll",
};

const VraSubstitutions = () => {
  const { state, dispatch } = useContext(Context);
  const navigate = useNavigate();

  const [vraList, setVraList] = useState([])
  const [subVraList, setSubVraList] = useState([])
  const [radList, setRadList] = useState([])
  const [isValues, setIsValues] = useState(false)
  const [isDate, setIsDate] = useState(false)
  const [actualVraId, setActualVraId] = useState('')
  const [substituteVraId, setSubstitutionVraId] = useState('')
  const [actualVraEmail, setActualVraEmail] = useState('')
  const [substituteVraEmail, setSubstituteVraEmail] = useState('')
  const [radEmail, setRadEmail] = useState('')
  const [substitutionDate, setSubstitutionDate] = useState(null)
  const [radAssociated, setRadAssociated] = useState('')
  const [staticEmailsList, setStaticEmailslist] = useState([])
  const [emailNameList, setEmailNameList] = useState([])
  const [additionalEmails, setAdditionalEmails] = useState([])
  const [allMailIdsList, setAllMailIdsList] = useState([])
  const [allMailIdsEditedList, setAllMailIdsEditedList] = useState([])
  const [emailTemplate, setEmailTemplate] = useState({})
  const [emailSubject, setEmailSubject] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [emailListString, setEmailListString] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [substitutionDates, setSubstitutionDates] = useState([]);

  const [substitutionDateRange, setSubstitutionDateRange] = useState([null, null]); 

  const [errorFields, setErrorFields] = useState({

    substitutionDate: false,
    actualVraId: false,
    substituteVraId: false,
    radAssociated: false,

  });
  const { getAssociateRadAPI, getAllVRAAPI, getAllRADAPI, getEmailTemplateAPI, createVraSubtitutionAPI } = vraSubstitutionAPI()

  useEffect(() => {
    handleGetAllVra()
  }, [])




  const handleGetAllVra = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      const res = await getAllVRAAPI()
      const rad = await getAllRADAPI()
      setRadList(rad?.data?.allRads)
      setVraList(res?.data?.allVras)

      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      console.log(error);

    }
  }

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
    calculateSubstitutionDates(newValue, endDate);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
    calculateSubstitutionDates(startDate, newValue);
  };

  const calculateSubstitutionDates = (start, end) => {
    if (start && end) {
      const dates = [];
      let currentDate = new Date(start);
      while (currentDate <= new Date(end)) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      setSubstitutionDates(dates);
    } else {
      setSubstitutionDates([]);
    }
  };

  const handleAllEmailsList = () => {
    const data = vraList?.map(e => e.business_email)
    setAllMailIdsList(data);
  };

  useEffect(() => {
    handleAllEmailsList()
  }, [vraList])


  const handleResetData = () => {
    setIsValues(false)
    setIsDate(false)
    setActualVraId('')
    setSubstitutionVraId('')
    setRadAssociated('')
    setStaticEmailslist([])
    setEmailNameList([])
    setEmailTemplate({})
    setEmailSubject('')
    setEmailBody('')
    setActualVraEmail('')
    setSubstituteVraEmail('')
    setAdditionalEmails([])
    setSubstitutionDate(null)
    setErrorFields({
      substitutionDate: false,
      actualVraId: false,
      substituteVraId: false,
      radAssociated: false,
    })
  }


  const handleChangeActualVra = async (e) => {
    setSubstitutionVraId('')
    setRadAssociated('')
    setStaticEmailslist([])
    setEmailNameList([])
    setEmailTemplate({})
    setEmailSubject('')
    setEmailBody('')
    setSubstituteVraEmail('')
    setActualVraId(e.target.value);
    setIsValues(true)
  }

  const handleChangeRadAssociated = async (e) => {
    const radId = e.target.value
    setRadAssociated(radId);
    const updatedEmailNameList = emailNameList.filter(email => email !== radEmail);
    const radEmailId = radList.find((rad) => rad.rad_roster_id == radId)?.email
    if (radEmailId) {
      updatedEmailNameList.push(radEmailId);
    }
    setEmailNameList(updatedEmailNameList);
    setRadEmail(radEmailId || '');
  }

  const handleSubtitutionDateChange = async (date) => {
    setSubstitutionVraId('')
    setRadAssociated('')
    setStaticEmailslist([])
    setEmailNameList([])
    setEmailTemplate({})
    setEmailSubject('')
    setEmailBody('')
    setIsValues(false)
    setActualVraId('')
    setSubstitutionDate(date)
    setIsDate(true)
  }

  const handleSubstituteVraId = (e) => {
    const emailId = e.target.value
    setSubstitutionVraId(emailId)
    const updatedEmailNameList = emailNameList.filter(email => email !== substituteVraEmail);
    const vraEmail = vraList.find((vra) => vra.vra_roster_id == emailId)?.business_email;
    if (vraEmail) {
      updatedEmailNameList.push(vraEmail);
    }
    setEmailNameList(updatedEmailNameList);
    setSubstituteVraEmail(vraEmail || '');
    handleAdditionalEmail()
  }


  const handleDateRangeChange = (newValue) => {
    setSubstitutionDateRange(newValue);
  };

  const handleSubstitutionHistory = () => {
    navigate("/panel/vraSubstitutionsHistory");
  }

//   useEffect(()=>{
//  handleGetEmailBody()
//   },[startDate,endDate])

  const handleGetEmailBody = async () => {
    try {
      let res = await getEmailTemplateAPI();
      const templateData = res?.data?.emailTemplateData[0];


      if (!templateData || !templateData.emailSubject || !templateData.emailBody) {
        console.error("Email template data is missing");
        return;
      }

      const actualVra = vraList.find((vra) => vra.vra_roster_id == actualVraId);
      const substituteVra = vraList.find((vra) => vra.vra_roster_id == substituteVraId);

      const formattedStartDate = startDate ? moment(startDate).format('DD/MM/yy') : '';
    const formattedEndDate = endDate ? moment(endDate).format('DD/MM/yy') : '';
    const dateRange = `${formattedStartDate} - ${formattedEndDate}`;

      const emailSubject = templateData.emailSubject
        .replace(/{actualVraName}/g, actualVra?.srf_name || '')
        .replace(/{substitutedVraName}/g, substituteVra?.srf_name || '')
        .replace(/{date}/g, dateRange) 

        console.log(emailSubject, "es")

      const emailBody = templateData.emailBody
        .replace(/{{substitutedVraName}}/g, substituteVra?.srf_name || '')
        .replace(/{date}/g, dateRange)
        .replace(/{actualVraName}/g, actualVra?.srf_name || '');

        console.log(emailBody,"eb")

      setEmailSubject(emailSubject);
      setEmailBody(emailBody);
      setEmailTemplate({
        subject: emailSubject,
        body: emailBody,
      });
    } catch (error) {
      console.error(error);
    }
  };


  const handleSendMail = async (e) => {
    e.preventDefault()
    const errors = {};

    // if (!substitutionDate) errors.substitutionDate = true;
    if (!actualVraId) errors.actualVraId = true;
    if (!substituteVraId) errors.substituteVraId = true;
    if (!radAssociated) errors.radAssociated = true;



    setErrorFields(errors);

    if (Object.keys(errors).length === 0) {
      const confirmation = await swal({
        title: "Confirm",
        text: "Are you sure the substitution data is accurate and you want to send the substitution mail?",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Reject",
            value: false,
            visible: true,
            className: "swal-button-disagree",
          },
          confirm: {
            text: "Accept",
            value: true,
            visible: true,
            className: "swal-button-agree",
          },
        },
        dangerMode: false,
      });




      if (confirmation) {
        try {

          const formattedSubstitutionDates = substitutionDates.map(date =>
            format(date, 'yyyy-MM-dd')
          );


          let values = {
            actualVraId: actualVraId,
            substituteVraId: substituteVraId,
            // radId: radAssociated[0]?.rad_roster_id,
            radId: radAssociated,
            substitutionDates: formattedSubstitutionDates,
            emails: [...emailListString.split(';').map(email => email.trim()), ...emailNameList],
            emailSubject: emailTemplate?.subject,
            emailBody: emailTemplate?.body,
          };

          dispatch({ type: "SET_LOADING", payload: true });
          const res = await createVraSubtitutionAPI(values);

          if (res?.data) {
            dispatch({ type: "SET_LOADING", payload: false });
            toast.success(res?.data?.msg, { toast: "substitution done" });
          }
        } catch (error) {
          console.log(error);
          dispatch({ type: "SET_LOADING", payload: false });
        }
      }
    }
  };


  const handleVraEmail = () => {
    const vraEmail = vraList.find((vra) => vra.vra_roster_id == actualVraId)
    const combinedEmails = [
      vraEmail?.business_email,
    ]
    setStaticEmailslist(combinedEmails)
    setEmailNameList(combinedEmails);
    const newList = vraList.filter((vra) => vra.vra_roster_id !== actualVraId)
    setSubVraList(newList)
    handleAdditionalEmail()
  }

  const handleAdditionalEmail = () => {
    const allMails = allMailIdsList.filter(mail => !emailNameList.includes(mail));
    setAllMailIdsEditedList(allMails)
    const mailList= additionalEmails.filter(mail=> !emailNameList.includes(mail))
    setAdditionalEmails(mailList)
  }
console.log(additionalEmails,'add');


  const handleCancel = () => {
    navigate('/panel/rpHomePage')
  }

  useEffect(() => {
    handleVraEmail()
  }, [actualVraId])

  useEffect(() => {
    if (actualVraId, substituteVraId) {
      handleGetEmailBody()
    }
  }, [actualVraId, substituteVraId, startDate, endDate])


  useEffect(() => {
    handleAdditionalEmail()
  }, [emailNameList])


  return (
    <div style={{ backgroundColor: "white", borderRadius: "5px", marginTop: "1%" }}>
      <Grid sx={{ pl: 1, pt: 2 }} >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "1%", padding: 10 }}>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: '16px',
              fontWeight: '600',
              letterSpacing: '0.02em',
              textAlign: 'left',
              color: '#0070E9'
            }}
          >
            VRA Substitutions
          </div>

          <Button className="substitutionHistory" onClick={handleSubstitutionHistory} >
            Substitution History
          </Button>
        </div>

      </Grid>

      <Grid container spacing={2} sx={{ pl: 4, pr: 5 }} >

        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
          <Box className="label">Substitution Date<span style={{ color: "red" }}>*</span></Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ display: 'flex', gap: '16px' }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                minDate={new Date()}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                minDate={startDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </div>
          </LocalizationProvider>
          {/* {errorFields.substitutionDate && <span className="error-msg">This is required Field</span>} */}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
          <Box className="label">Actual VRA <span style={{ color: "red" }}>*</span></Box>
          <FormControl fullWidth  sx={{ border: '1px solid #B2D4F8',fontFamily:"Inter" }}>
  <Select
    id="actualVraId"
    name="actualVraId"
    defaultValue=""
    displayEmpty
    value={actualVraId}
    // inputProps={{ 'aria-label': 'Without label' }}
    inputProps={{ style: { fontFamily: 'Inter' } }}
    style={{ border: "2px solid #B2D4F8", color: "grey", fontFamily: "Inter" }}
    onChange={handleChangeActualVra}
    // disabled={!isDate}
    disabled={!startDate || !endDate} 
  >
    <MenuItem value="" style={{ fontFamily: "Inter", color: "grey" }}>
      Actual VRA
    </MenuItem>
    {vraList.map((vra) => (
      <MenuItem key={vra.vra_roster_id} value={vra.vra_roster_id} style={{ fontFamily: "Inter" }}>
        {vra.srf_name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

          {errorFields.actualVraId && <span className="error-msg">This is required Field</span>}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
          <Box className="label">Substitute VRA<span style={{ color: "red" }}>*</span></Box>
          <FormControl fullWidth variant="outlined" sx={{ border: '1px solid #B2D4F8' }}>
            <Select
              labelId="actualVra-label"
              id="substituteVraId"
              name="substituteVraId"
              placeholder="Select substitute VRA"
              defaultValue=''
              displayEmpty
              value={substituteVraId}
              onChange={handleSubstituteVraId}
              disabled={!isValues}
            >
              <MenuItem value=''>
               Substitute VRA
              </MenuItem>
              {subVraList.map((vra) => (
                <MenuItem key={vra.vra_roster_id} value={vra.vra_roster_id}>
                  {vra.srf_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorFields.substituteVraId && <span className="error-msg">This is required Field</span>}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
          <Box className="label">RAD Associated <span style={{ color: "red" }}>*</span></Box>
          <FormControl fullWidth  sx={{ border: '1px solid #B2D4F8' }}>

            <Select
              defaultValue=''
              displayEmpty
              value={radAssociated}
              onChange={handleChangeRadAssociated}
              disabled={!isValues}
              // inputProps={{ style: { fontFamily: 'Inter', fontStyle: 'normal' } }}
              inputProps={{ 'aria-label': 'Without label' }}
              style={{border:"2px solid #B2D4F8", color:"grey"}}
            >
              <MenuItem value=''>
               RAD Associated
              </MenuItem>
              {radList.map((rad) => (
                <MenuItem key={rad.rad_roster_id} value={rad.rad_roster_id}>
                  {rad.rad_qgenda_fullname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorFields.radAssociated && <span className="error-msg">This is required Field</span>}
        </Grid>


        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
          <Box className="label">Email to be send to<span style={{ color: "red" }}>*</span></Box>
          <TextField
            id="outlined-multiline-static"
            placeholder="List of email Ids "
            multiline
            rows={3} 
            value={emailNameList.join('\n')}
            disabled={true}
            variant="outlined"
            style={{
              width: '100%'
            }}
          />


          <Box style={{ marginTop: "2%" }} className="label">Additional Email Ids <span>(Note: example@gmail.com;example2@gmail.com)</span></Box>
        

<TextField
  id="emailListString"
  placeholder="Enter email IDs separated by semi colon"
  multiline
  // rows={3}
  value={emailListString}
  onChange={(e) => setEmailListString(e.target.value)}
  variant="outlined"
  style={{ width: '100%' }}
/>


        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
          <Box className="label"> Email Format <span style={{ color: "red" }}>*</span></Box>
          <TextField
            id="outlined-multiline-static"
            placeholder="Email Template to be send"
            multiline
            disabled={true}
            rows={7}
            value={`Subject: ${emailTemplate?.subject || ''}\n\nBody:\n${emailTemplate?.body?.replace(/\\n/g, '\n') || ''}`}
            variant="outlined"
            style={{
              width: '100%'
            }}
          />

        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} >

        </Grid>
      </Grid>
      <Grid container sx={{ pl: 4, pr: 5, mt: 5, mb: 5, pb: 4, justifyContent: "center" }} >
        <Grid item sx={{ ml: 1 }} xs={12} sm={3} md={3} lg={3}>
          <Button className="resetChanges" onClick={handleResetData}>Reset Changes</Button>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Button className="cancelButton" onClick={handleCancel} >Cancel</Button>
        </Grid>

        <Grid item sx={{ mr: 1 }} xs={12} sm={2} md={2} lg={2}>
          <Button className="saveButton" onClick={handleSendMail} >Send</Button>
        </Grid>

      </Grid>
    </div>
  )
}


export default VraSubstitutions
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningheadvraAffinity{
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #cc3300;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/views/panel/vraShifts/VraScheduling/VraScheduling.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".warningheadvraAffinity{\r\n    font-family: \"Inter\";\r\n    font-size: 18px;\r\n    font-weight: 600;\r\n    line-height: 21.78px;\r\n    letter-spacing: 0.02em;\r\n    text-align: left;\r\n    color: #cc3300;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

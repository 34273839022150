import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import "./EditAddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import personalInfo from "assets/images/color (1).png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PostInsurance from 'apis/panel/Insurance/insurance'
import getEmpRel from 'apis/panel/Insurance/insurance'
import getRel from 'apis/panel/Insurance/insurance'
import getcreatefindone from "apis/panel/AddEmployee/addEmployee"
import getAllGenderdrop from "apis/panel/AddEmployee/addEmployee";
import Loader from "components/Loader";
import Context from "context/context";

const EditInsuranceInfo = () => {
  const { state } = useContext(Context);
  const [roleCode] = useState(state.role.roleCode);

  let params = useParams();
  const employeeId = params.id;
  
  const [insuranceInfo, setInsuranceInfo] = useState();
  const [insuranceArray, setInsuranceArray] = useState();

  const { getAllGenderAPI } = getAllGenderdrop()
  const { getEmployeefindone } = getcreatefindone();
  const { PostInsuranceApi } = PostInsurance();
  const { getEmployeeRelations } = getEmpRel();
  const { getRelations } = getRel();

  const [employeeCode, setemployeeCode] = useState()
  const [EmployeeId] = useState(sessionStorage.getItem('EmployeeID'))
  const [RelationsData, setRelationsData] = useState([])
  const [relName, setrelName] = useState();
  const [expanded, setExpanded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [insurancename, setinsurancename] = useState('')
  const [genderId, setgenderId] = useState('');
  const [relationshipid, setrelationshipid] = useState('')
  const [employeecode, setemployeecode] = useState('')
  const [relationshipname, setrelationshipname] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [dateOfjoining, setDateOfJoining] = useState('')
  const [gendername, setgendername] = useState('')
  const [genderOptions, setGenderOptions] = useState([]);

  const [loader2, setLoader2] = useState(false)

  useEffect(() => {
    findrelations();
    handleGetAllGendersApi();
  }, [])

  useEffect(() => {
    let employeeDatas = state.employeeData;
    if (employeeDatas) {
      let employeeInsurance = employeeDatas?.insurance;
      if (employeeInsurance?.length > 0) {
        let insuranceArray = [];
        let insuranceInfo = [];
        for (let index = 0; index < employeeInsurance.length; index++) {
          const element = employeeInsurance[index];
          insuranceArray.push({
            id: index + 1,
            employeeId: element.employeeId,
            employeeCode: employeeDatas.employeeCode,
            insuredName: element.insuredName,
            relationshipId: element.relationshipId,
            relationshipName: element.relationships.relationshipName,
            genderId: element.genderId,
            genderName: element.genders.genderName,
            dateOfBirth: element.dateOfBirth,
            dateOfJoining: element.dateOfJoining
          })
          insuranceInfo.push({
            id: index + 1
          })
        }
        setInsuranceArray(insuranceArray);
        setInsuranceInfo(insuranceInfo);
      }
      else {
        setInsuranceInfo([{ id: 1 }]);
        setInsuranceArray([{ 
          id: 1,
          employeeId: employeeDatas.employeeId,
          employeeCode: employeeDatas.employeeCode
        }]);
      }
    }
  }, [state?.employeeData])

  const handleAddSection = () => {
    const newId = insuranceArray.length > 0 ? insuranceArray[insuranceArray.length - 1].id + 1 : 1;
    setInsuranceInfo(prevState => [...prevState, { id: newId }]);
    setInsuranceArray(prev => [...prev, {
        id: newId,
        employeeId: insuranceArray.length > 0 ? insuranceArray[0].employeeId : '',
        employeeCode: insuranceArray.length > 0 ? insuranceArray[0].employeeCode : ''
    }]);
};

const handleRemoveSection = (indexToRemove) => {
  setInsuranceInfo(prevState => prevState.filter((_, index) => index !== indexToRemove));
  setInsuranceArray(prev => prev.filter((_, index) => index !== indexToRemove));
};

  const findrelations = async () => {
    const data = await getRelations()
    setRelationsData(data.data)
  }

  const handleGetAllGendersApi = async () => {
    try {
      let res = await getAllGenderAPI();
      setGenderOptions(res?.data || []);
    }
    catch (err) { }
  };

  const handleGenderChange = (e) => {
    const selectedgender = genderOptions.find(option => option.genderName === e.target.value);
    setgendername(selectedgender.genderName);
    setgenderId(selectedgender.genderId)
  };

  const [relationshipValid, setRelationshipValid] = useState(true);
  const [insuredNameValid, setInsuredNameValid] = useState(true);
  const [dateOfBirthValid, setDateOfBirthValid] = useState(true);
  const [genderIdValid, setGenderIdValid] = useState(true);
  const [dateOfJoiningValid, setDateOfJoiningValid] = useState(true);
  const [errors, setErrors] = useState({});
  const [accordionOpen, setAccordionOpen] = useState(false);

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handlerelationship = async (relationshipId, sectionId) => {
    const data = await getEmployeeRelations({
      relID: relationshipId,
      empID: employeeId
    })
    if(data.status!=='Error'){
      const updatedarr = insuranceArray.map((p) =>
        p.id == sectionId ? {
          ...p,
          insuredName: data?.data?.firstName,
          relationshipId: relationshipId,
          relationshipName: data?.data?.relationships?.relationshipName
        } : p
      )
      setInsuranceArray(updatedarr);
    }
    else{
      const updatedarr = insuranceArray.map((p) =>
        p.id == sectionId ? {
          ...p,
          insuredName: "",
          relationshipId: relationshipId,
          relationshipName: RelationsData.find(option => option.relationshipId === relationshipId).relationshipName
        } : p
      )
      setInsuranceArray(updatedarr);
    }
  };

  const handleInsuredName = (insuredName, sectionId) => {
    const updatedarr = insuranceArray.map((p) =>
      p.id == sectionId ? {
        ...p,
        insuredName: insuredName
      } : p
    )
    setInsuranceArray(updatedarr);
  };

  // const handleGender = (genderId, sectionId) => {
  //   const updatedarr = insuranceArray.map((p) =>
  //     p.id == sectionId ? {
  //       ...p,
  //       genderId: genderId,
  //       genderName: genderOptions.find(option => option.genderId === genderId).genderName
  //     } : p
  //   )
  //   setInsuranceArray(updatedarr);
  // };


  const handleGender = (genderId, sectionId) => {
    const selectedGender = genderOptions.find(option => option.genderId === genderId);
    const updatedarr = insuranceArray.map((p) =>
      p.id === sectionId
        ? {
            ...p,
            genderId: genderId,
            genderName: selectedGender ? selectedGender.genderName : '', // If undefined, set an empty string
          }
        : p
    );
    setInsuranceArray(updatedarr);
  };

  const handleDateOfBirth = async (dateOfBirth, sectionId) => {
    const updatedarr = insuranceArray.map((p) =>
      p.id == sectionId ? {
        ...p,
        dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD")
      } : p
    )
    setInsuranceArray(updatedarr);
  }

  const handleDateOfJoining = async (dateOfJoining, sectionId) => {
    const updatedarr = insuranceArray.map((p) =>
      p.id == sectionId ? {
        ...p,
        dateOfJoining: dateOfJoining
      } : p
    )
    setInsuranceArray(updatedarr);
  }
  
  const handleClick = async () => {
    setLoader2(true)
    const validationErrors = {};
    if (!insuranceArray.every(item => item.relationshipId)) {
      validationErrors.relationshipId = true;
    }
    if (!insuranceArray.every(item => item.insuredName)) {
      validationErrors.insuredName = true;
    }
    if (!insuranceArray.every(item => item.genderId)) {
      validationErrors.genderId = true;
    }
    if (!insuranceArray.every(item => item.dateOfBirth)) {
      validationErrors.dateOfBirth = true;
    }
    // if (!insuranceArray.every(item => item.dateOfJoining)) {
    //   validationErrors.dateOfJoining = true;
    // }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      let body = {
        "employeeId": employeeId,
        "insuranceData": insuranceArray
      }
      const data = await PostInsuranceApi(body)
      if (data.status === 'Ok') {
        setLoader2(false)
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
      }
      else {
        setLoader2(false)
        return toast.error("Error saving Data", { toast: "Error saving Data" });
      }
    }
    else{
      setLoader2(false)
    }
  }

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      setIsEditMode(false);
    }
  };

  const maxDate = new Date();


  return (
    <div>
      <Loader loading={loader2} />
      <Accordion sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
        >
          <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" /></Box>
          <Box className="acc-title" sx={{ pl: 3 }}>Insurance Information</Box>
          <Box sx={{ ml: 45 }}>
            {expanded && (
              <button className="editButton" onClick={toggleEditMode}>
                Edit
                <Box sx={{ flexGrow: 1 }} />
                {isEditMode}
              </button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {insuranceInfo?.map((section, index) => (
            <>
              <Grid container spacing={2} sx={{ pl: 3, pr: 5, mt: 2 }} style={{ display: "flex", justifyContent: "space-between" }} >
                <Box className="subHeadingforinfos" sx={{ pl: 3 }}>Insurance Info {index + 1}</Box>
                {isEditMode && (
                  <>
                    {index > 0 ? (
                      <Button className="removeSectionButton" onClick={() => handleRemoveSection(index)}>Remove Section</Button>
                    ) : (
                      <Button className="addSectionButton" onClick={handleAddSection}>Add Section</Button>
                    )}
                  </>
                )}
              </Grid>
              <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
                {isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label" >Relationship <span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>
                      <Select
                        labelId="marital-status-label"
                        id="maritalStatus"
                        placeholder="Select Date"
                        defaultValue=""
                        value={insuranceArray[index].relationshipId}
                        displayEmpty
                        onChange={(e) => {
                          handlerelationship(e.target.value, section.id)
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                      >
                        <MenuItem value="">
                          Select Relationship
                        </MenuItem>
                        {
                          RelationsData?.map((i) => (
                            <MenuItem value={i.relationshipId}>{i.relationshipName}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                    {errors.relationshipId && <div className='error-message'>This field is mandatory.</div>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                    <Box className="editlabel" >Relationship</Box>
                    <Box className="editvalue">{insuranceArray[index].relationshipName}</Box>
                  </Grid>
                )}
                {isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label" >Employee ID </Box>
                    <TextField id="firstName"
                      placeholder="Id Proof"
                      value={insuranceArray[index].employeeCode}
                      disabled={true}
                      variant="outlined"
                      fullWidth />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                    <Box className="editlabel" >Employee ID</Box>
                    <Box className="editvalue">{insuranceArray[index].employeeCode}</Box>
                  </Grid>
                )}
                {isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label" >Name of the Insured<span style={{ color: "red" }}>*</span></Box>
                    <TextField id="firstName"
                      value={insuranceArray[index].insuredName}
                      placeholder="Enter Name of insured"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        handleInsuredName(e.target.value, section.id);
                      }}
                    />
                    {errors.insuredName && <div className='error-message'>This field is mandatory.</div>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                    <Box className="editlabel" >Name of the Insured</Box>
                    <Box className="editvalue">{insuranceArray[index].insuredName}</Box>
                  </Grid>
                )}
                {isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label" > Date of Birth <span style={{ color: "red" }}>*</span></Box>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                      <DatePicker
                      sx={{width:"100%"}}
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={new Date(insuranceArray[index].dateOfBirth)}
                        onChange={(date) => {
                          handleDateOfBirth(date, section.id);
                        }}
                        maxDate={maxDate}
                        slotProps={{
                          field: { clearable: true },
                        }}
                        format="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                      />
                    </LocalizationProvider>
                    {errors.dateOfBirth && <div className='error-message'>This field is mandatory.</div>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "30px" }}>
                    <Box className="editlabel">Date of Birth</Box>
                    <Box className="editvalue">{insuranceArray[index].dateOfBirth ? moment(insuranceArray[index].dateOfBirth).format('DD/MM/YYYY') : ""}</Box>
                  </Grid>
                )}
                {isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label">Gender<span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>
                      <Select
                        labelId="marital-status-label"
                        id="maritalStatus"
                        placeholder="Select Date"
                        defaultValue=""
                        value={insuranceArray[index].genderId}
                        onChange={(e) => {                          
                          handleGender(e.target.value, section.id);
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                      >
                        <MenuItem value="">
                          Select Gender
                        </MenuItem>
                        {genderOptions.map((option) => (
                          <MenuItem key={option.id} value={option.genderId}>{option.genderName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.genderId && <div className='error-message'>This field is mandatory.</div>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "30px" }}>
                    <Box className="editlabel" >Gender</Box>
                    <Box className="editvalue">{insuranceArray[index].genderName}</Box>
                  </Grid>
                )}
                {/* {isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label">Date of Joining<span style={{ color: "red" }}>*</span></Box>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                      <DatePicker
                      sx={{width:"100%"}}
                        id="dateOfjoing"
                        name="dateOfjoing"
                        value={new Date(insuranceArray[index].dateOfJoining)}
                        onChange={(date) => {
                          handleDateOfJoining(date, section.id);
                        }}
                        maxDate={maxDate}
                        slotProps={{
                          field: { clearable: true },
                        }}
                        format="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                      />
                    </LocalizationProvider>
                    {errors.dateOfJoining && <div className='error-message'>This field is mandatory.</div>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "30px" }}>
                    <Box className="editlabel">Date of Joining</Box>
                    <Box className="editvalue">{insuranceArray[index].dateOfJoining ? moment(insuranceArray[index].dateOfJoining).format('DD/MM/YYYY') : ""}</Box>
                  </Grid>
                )} */}
              </Grid>
            </>
          ))}
          {isEditMode && (
            <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="cancelButton" onClick={toggleEditMode}>Cancel</Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="saveButton" onClick={handleClick}>Save</Button>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </div>

  )
}

export default EditInsuranceInfo
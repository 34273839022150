import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// css
import "./radRoster.css"
// mui
import Tables from "components/table/table";
import rpRosterAPI from "apis/panel/rpRoster/rpRadRoster";
import Context from "context/context";
import leftarrow from "assets/images/arrow-right.png";
import Box from '@mui/material/Box';


import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';

import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { Button } from "../../../../../node_modules/@mui/material/index";
import EditRadRoster from "./EditRadRoster";


const style = {
  position: 'absolute',
  top: '60%',
  left: '60%',
  transform: 'translate(-60%, -60%)',
  width: "98%",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  maxHeight: "90vh",
  overflowY: "scroll",
};

const RadRoster = () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode] = useState(state.role.roleCode);
  const navigate = useNavigate();

 const {getAllRadRosterDetailsAPI} = rpRosterAPI()
  
  const [radRosterDetails, setRadRosterDetails] = useState([])
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [radRosterEditObj, setRadRosterEditObj] = useState([]);
  const [editId,setEditId] = useState('')
  const [open, setOpen] = useState(false);
  
  const [serialNumber, setSerialNumber] = useState("");


const handleEdit = async(id)=>{
 
  setOpen(true)
    
    setEditId(id)
}

const handleNavigate = () => {
  navigate("/panel/addRadRoster")
}

const handleNavigation=()=>{
  navigate("/panel/rpHomePage")
}

const handleClose= async()=>{
  setEditId('')
  setOpen(false)
}

  const handleGetAllRadRosterDetailsApi = async () => {
   
    
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        offset: (offset - 1) * 20,
        limit: 20,
      };
      if (searchValue) {
        values = {
            ...values,
          searchValue,
        };
      }
      if (filterValue) {
        values = {
            ...values,
          filterValue,
        };
      }
      let res = await getAllRadRosterDetailsAPI(values);

      setRadRosterDetails(res?.data?.radRosters || []);
      setCount(res?.data?.count);
      setSerialNumber(offset)
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };


  useEffect(() => {
   
    handleGetAllRadRosterDetailsApi()
    
  }, [offset, searchValue, filterValue,open])

  return (
    <div
      className="vraShiftsMediaQuery"
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Tables
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={handleNavigation} style={{ cursor: 'pointer', paddingRight: 8 }}>
            <img src={leftarrow} alt="Back" />
          </div>
          <Box sx={{ pt: 2, pb: 2 }} className="VraShiftsTitle">
            RAD Roster
          </Box>
        </div>
      }
        searchBar={true}
        filter={false}
        role={roleCode}
        create={
         
           <Button className="AddEmployerButton" onClick={handleNavigate}>
              Add RAD
           </Button>
         }
        setSearchValue={setSearchValue}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterValues={[
          { label: "First Name", value: "" },
          { label: "Deployed Date", value: "deployed_date" },
        ]}
        heading={[
          "S.No",
          "First Name",
          "Last Name",
          "RAD Full Name",
          "RAD SRF Name",
          "Email",
          "Practice",
          "Time Zone",
          "Deployed Date",
          "Schedule Pattern",
          "No. of VRA's needed",
          "Status",
          "Discontinued Date",
          "Reason For Discontinuition",
          "Created On",
          "Updated On",
          "Planned Deployment Date",
          "Actions"
          
        ]}
        data={radRosterDetails?.map((e, index) => [
        
          index + 1 + (serialNumber - 1) * 20,
              
              e.firstname,
              e.lastname,
              e.radiologistusername,
              e.radiologistformattedname,
              
              e.email,
              e.practice,
              e.timezone,
              e.deployeddate? moment(e.deployeddate).format('DD-MMM-YYYY') : e.deployeddate,
              e.schedulepattern,
              e.vraneededcount,
             
              e.status,
              e.discontinueddate? moment(e.discontinueddate).format("DD-MMM-YYYY"): e.discontinueddate,
              e.discontinuedreason,
              e.craetedon? moment(e.craetedon).format("DD-MMM-YYYY"): e.craetedon,
              e.updatedon?moment(e.updatedon).format("DD-MMM-YYYY"): e.updatedon,
            e.planneddeploymentdate?  moment(e.planneddeploymentdate).format("DD-MMM-YYYY") :  e.planneddeploymentdate,
             
             
                <EditIcon style={{color:"#FE6024",cursor: 'pointer'}}  onClick={() => handleEdit(e.radrosterid)}/>,
         ])}
         offset={offset}
         setOffset={setOffset}
         limit={20}
         count={count}
         placeholder="Search by FirstName, RAD Full Name"
      />

<Modal open={open}  onClose={handleClose} aria-labelledby="modal-title"
                aria-describedby="modal-description" >
<Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  height:460,
                  overflowY:'scroll',
                  transform: 'translate(-50%, -50%)',
                  width: 800, 
                  bgcolor: 'background.paper',
                  borderRadius:'10px',
                 
                  p: 4,}} >
         <EditRadRoster id={editId} onClose={handleClose} />
        </Box>
      </Modal>
     
    </div>
  )
}


export default RadRoster
import {React, useContext,useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";


import * as moment from "moment";

import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import "react-toastify/dist/ReactToastify.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Context from "context/context";
import reportAPI from 'apis/panel/reports/reports';
import Modal from '@mui/material/Modal';
import Tables from "components/table/table";

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import moment from 'moment';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: "100%",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    width: "90vw",
    // p: 4,
    borderRadius: "10px",
    maxHeight: "90vh",
    overflowY: "scroll",
    // overflowX: "scroll"
  };

const VraRosterReport = ({ setDisableDropdown }) => {
    const { dispatch } = useContext(Context);
    const [radScheduleData, setRadScheduleData]= useState([]);
    const [open , setOpen] = useState(false)
    const [offset, setOffset] = useState(1);
    const [initial,setInitial]= useState(true)
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const {
       VraReportViewAPI,getRadRosterAPI, getVraRosterAPI
      } = reportAPI();

      const initialStartDate = moment().startOf('month').toDate();

      const initialEndDate = new Date();
  
      const [exportObj, setExportObj] = useState({
          startDate: initialStartDate,
          endDate: initialEndDate,
      });

      const handleClose = () => {
        setOpen(false);
      }

 useEffect(()=>{
  if(!initial){
    handleView()
  }
   
 },[offset,searchValue])


 const handleView = async () => {
  try {
      dispatch({ type: "SET_LOADING", payload: true });

      let value = {
          offset: (offset - 1) * 20,
          limit: 20,
      };

      if (searchValue) {
        value={
          ...value,
          searchValue
        }
      }

      console.log(value,"val")

      let res = await VraReportViewAPI(value);

      setCount(res?.data?.count);
      setRadScheduleData(res?.data?.data);
      setInitial(false);
      dispatch({ type: "SET_LOADING", payload: false });
      setOpen(true);
  } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      setOpen(false);
      console.error("Error fetching data: ", err); 
  }
};


     

      const handleExport = async () => {
       
       
        dispatch({ type: "SET_LOADING", payload: true });
        setDisableDropdown(true);
       
        await getVraRosterAPI();
        setDisableDropdown(false);
        dispatch({ type: "SET_LOADING", payload: false });
      };
    
  return (  
    <div>
         

      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>

        <Button
            className="download-btn"
            sx={{marginRight:'10px'}}
            startIcon={
              <RemoveRedEyeOutlinedIcon
                style={{ fontSize: 30, verticalAlign: "middle" ,pl:'2px'}}
              />
            }
            onClick={handleView}
           >
            View
          </Button>
          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
          />
            }
            onClick={handleExport}
          >
            Export
          </Button>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
     
      


          <Tables
        title={
          <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">VRA Roster Report</Box>
        }
        searchBar={true}
        // filter={roleCode === "R001"}
        setSearchValue={setSearchValue}
        create={    <IconButton
            onClick={handleClose}
            // sx={{  top: 3, right: 3 }}
          >
            <CloseIcon style={{ border: "2px solid grey", borderRadius: "5px" }} />
          </IconButton>}
        // filterValue={filterValue}
        // setFilterValue={setFilterValue}
        // filterValues={[
        //   { label: "Employee Id", value: "" },
        //   { label: "VRA Name", value: "employeeName" },
        // ]}
        heading={[
       
          "S.No",
          "First Name",
          "Last Name",
          "Employee Code",
         
          "Date of joining",
          "Engagement Type",
          "primary Shift",
          "SRF Name",
          "VRA FullName",
          "Deployed Date",
          "Commitment Date",
          "Role",
          "Status",
          "Training Start Date",
          "Training End Date",
          "Certification Date",
          "Batch"

        ]}
        data={radScheduleData?.map((e, index) => [
         (offset-1)*20 +index+1,
         e.first_name,
        e. last_name,
        e.employee_code,
        e.date_of_joining? moment(e.date_of_joining).format("DD-MMM-YYYY"): e.date_of_joining,
     
         e.engagement_type,
         e.primary_shift,
         e.srf_name,
         e.vra_fullname,
         e.deployed_date? moment(e.deployed_date).format("DD-MMM-YYYY"): e.deployed_date,
         e.commitment_date?  moment(e.commitment_date).format("DD-MMM-YYYY"): e.commitment_date,
         e.role,
         e.status,
         e.training_start_date? moment(e.training_start_date).format("DD-MMM-YYYY"): e.training_start_date,
        e.training_end_date?moment(e.training_end_date).format("DD-MMM-YYYY"): e.training_end_date,
        e.certification_date? moment(e.certification_date).format("DD-MMM-YYYY"): e.certification_date,
        e.batch
    
         ])}
         offset={offset}
         setOffset={setOffset}
         count={count}
         placeholder="Search by  Name"
         limit={20}
        //  placeholder="Search by ID, VRA Name"
      />
    
        </Box>
      </Modal>
    </div>
  )
}

export default VraRosterReport
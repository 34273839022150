import { React, useContext, useState, useEffect } from "react";
import * as moment from "moment";

import Context from "context/context";
import vraShiftsAPI from "apis/panel/vraShifts/vraShifts";
import reportAPI from "apis/panel/reports/reports";
import Tables from "components/table/table";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  width: "100vw",
  borderRadius: "10px",
  maxHeight: "90vh",
  overflowY: "scroll",
};

const RpAllowanceSummary = ({ setDisableDropdown }) => {
  const { dispatch } = useContext(Context);
  const { getRPAllowanceReportSummaryAPI } = vraShiftsAPI();
  const { getRPAllowanceSummaryReportViewAPI } = reportAPI();

  const initialStartDate = moment().startOf("month").toDate();
  const initialEndDate = new Date();

  const [exportObj, setExportObj] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [initial, setInitial] = useState(true);
  const [dateHeader, setDateHeader] = useState([]);
  const [dateKey, setDateKey] = useState([]);

  useEffect(() => {
    if (!initial) {
      handleView();
    }
  }, [offset, searchValue]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleView = async () => {
    if (!exportObj?.startDate || !exportObj?.endDate) {
      alert("Start Date and End Date must not be empty.");
      return;
    }

    try {
      dispatch({ type: "SET_LOADING", payload: true });

      let value = {
        offset: (offset - 1) * 20,
        limit: 20,
      };
      if (searchValue) {
        value = {
          ...value,
          searchValue,
        };
      }
      const values = {
        ...value,
        startDate: moment(exportObj.startDate).format("YYYY-MM-DD"),
        endDate: moment(exportObj.endDate).format("YYYY-MM-DD"),
      };

      let res = await getRPAllowanceSummaryReportViewAPI(values);

      setCount(res?.data?.count);

      setData(res?.data?.data);

      dispatch({ type: "SET_LOADING", payload: false });
      setOpen(true);
      setInitial(false);
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      setOpen(false);
    }
  };

  const handleExport = async () => {
    let body = {};
    if (!exportObj?.startDate) {
      return;
    }
    if (!exportObj?.endDate) {
      return;
    }
    body = {
      startDate: moment(exportObj?.startDate).format("YYYY-MM-DD"),
      endDate: moment(exportObj?.endDate).format("YYYY-MM-DD"),
    };

    dispatch({ type: "SET_LOADING", payload: true });
    setDisableDropdown(true);

    await getRPAllowanceReportSummaryAPI(body);
    setDisableDropdown(false);
    dispatch({ type: "SET_LOADING", payload: false });
  };

  function iterateDates(startDate, endDate) {
    let header = [];
    let key = [];
    let currentDate = new Date(startDate);
    let finalDate = new Date(endDate);

    while (currentDate <= finalDate) {
      header = [
        ...header,
        moment(currentDate.toISOString().split("T")[0]).format("DD-MMM-YY"),
      ];
      key = [...key, currentDate.toISOString().split("T")[0]];
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return { header, key };
  }

  useEffect(() => {
    if (exportObj?.startDate && exportObj?.endDate) {
      const { header, key } = iterateDates(
        moment(exportObj?.startDate).format("YYYY-MM-DD"),
        moment(exportObj?.endDate).format("YYYY-MM-DD")
      );
      setDateHeader(header);
      setDateKey(key);
    }
  }, [exportObj?.startDate, exportObj?.endDate]);

  return (
    <div>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            Start Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              maxDate={exportObj?.endDate}
              value={exportObj?.startDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, startDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            End Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              sx={{ width: "100%" }}
              maxDate={new Date()}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={exportObj?.startDate}
              value={exportObj?.endDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, endDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>
          <Button
            className="download-btn"
            sx={{ marginRight: "10px" }}
            startIcon={
              <RemoveRedEyeOutlinedIcon
                style={{ fontSize: 30, verticalAlign: "middle", pl: "2px" }}
              />
            }
            onClick={handleView}
          >
            View
          </Button>

          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
              />
            }
            onClick={handleExport}
          >
            Export
          </Button>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Tables
            title={
              <Box sx={{ pt: 2, pb: 2 }} className="VraShiftsTitle">
                RP Allowances Summary View
              </Box>
            }
            searchBar={true}
            create={
              <IconButton onClick={handleClose}>
                <CloseIcon
                  style={{ border: "2px solid grey", borderRadius: "5px" }}
                />
              </IconButton>
            }
            setSearchValue={setSearchValue}
            heading={[
              "S.No",
              "Employee Id",
              "First Name",
              "Last Name",
              "DOJ",
              "DOD",
              ...dateHeader,
              "Morning Shift Allowances",
              "Evening Shift Allowances",
              "Night Shift Allowances",
              "Total Shift Allowances",
              "Food Allowances",
              "Total Allowances",
            ]}
            data={data?.map((e, index) => [
              (offset - 1) * 20 + index + 1,
              e.employeeCode,
              e.firstName,
              e.lastName,
              [
                <span style={{whiteSpace:"nowrap"}}>
                  {e.dateOfJoining
                    ? moment(e.dateOfJoining).format("DD-MMM-YYYY")
                    : null}
                </span>,
              ],
              [
                <span style={{whiteSpace:"nowrap"}}>
                  {e.deployedDate
                    ? moment(e.deployedDate).format("DD-MMM-YYYY")
                    : null}
                </span>,
              ],
              ...dateKey?.map((k) => e?.[k]),
              e.morningShiftAllowances,
              e.eveningShiftAllowances,
              e.nightShiftAllowances,
              e.totalShiftAllowances,
              e.totalFoodAllowances,
              e.totalAllowances,
            ])}
            offset={offset}
            setOffset={setOffset}
            count={count}
            placeholder="Search by Employee Id"
            limit={20}
            // placeholder="Search by ID, VRA Name"
          />
        </Box>
      </Modal>
    </div>
  );
};

export default RpAllowanceSummary;

import React, { useState, useEffect, useContext } from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import "./EditEmailTemplate.css"
import Context from "context/context";
import Box from '@mui/material/Box';
import { toast } from "react-toastify";
import 'react-calendar/dist/Calendar.css';
import Button from '@mui/material/Button';
import emailTemplateAPIS from 'apis/panel/vraShifts/vraShifts';
import leftarrow from "assets/images/arrow-right.png";
import { useNavigate } from "../../../../../node_modules/react-router-dom/dist/index";


const EditEmailTemplate = () => {
    const navigate= useNavigate()
    const { state, dispatch } = useContext(Context);
    const [email, setEmail] = useState([])
    const [templateId, setTemplateId] = useState('')
    const [templateName, setTemplateName] = useState('')
    const [emailSubject, setEmailSubject] = useState('')
    const [emailBody, setEmailBody] = useState('')
    const { getEmailTemplateAPI, editEmailTemplateAPI } = emailTemplateAPIS()

    useEffect(() => {
        handleGetEditEmail()

    }, [])

    const handleGetEditEmail = async () => {
        try {
            const res = await getEmailTemplateAPI()
            setEmail(res?.data?.emailTemplateData)
        } catch (error) {
            console.log(error);

        }
    }

    const handleEmailBodyChange = (e) => {
        const formattedValue = e.target.value.replace(/\n/g, '\\n');
        setEmailBody(formattedValue);
    }


    const handleEmailSubject = (e) => {
        setEmailSubject(e.target.value)
    }

    useEffect(() => {
        setTemplateId(email[0]?.emailTemplateId)
        setTemplateName(email[0]?.templateName)
        setEmailSubject(email[0]?.emailSubject)
        setEmailBody(email[0]?.emailBody)
    }, [email])


    const handleSubmit = async () => {
        try {
            let values = {
                templateId: templateId,
                templateName: templateName,
                emailSubject: emailSubject,
                emailBody: emailBody
            }
            dispatch({ type: "SET_LOADING", payload: true });
            let res = await editEmailTemplateAPI(values)
            if (res?.data) {
                dispatch({ type: "SET_LOADING", payload: false });
                return toast.success(res?.data?.data, { toast: "email updated" });
            } else {
                dispatch({ type: "SET_LOADING", payload: false });
                return toast.success("Error updating email template", { toast: "email updated" });
            }
        } catch (error) {
            console.log(error);

        }
    }

    const handleNavigation=()=>{
        navigate("/panel/settings")
    }

    return (
        <div style={{ backgroundColor: "white", borderRadius: "5px", marginTop: "1%" }}>
            <Grid sx={{ pl: 1, pt: 2 }} >
                <div style={{ display: 'flex', alignItems: 'center' }}>
  <div onClick={handleNavigation} style={{ cursor: 'pointer', paddingRight: 8 }}>
    <img src={leftarrow} alt="Back" />
  </div>
  <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">
  VRA Substitution Email Template
  </Box>
</div>
            </Grid>

            <Grid container  sx={{ pl: 6, pr: 6 , mt:2}} >

            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <Box className="label">Template Name<span style={{ color: "red" }}>*</span></Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="templateName" name="templateName" value={templateName} placeholder="Template Name" variant="outlined" disabled={true} fullWidth />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <Box className="label">Subject<span style={{ color: "red" }}>*</span></Box>
                <TextField sx={{ border: '1px solid #B2D4F8' }} id="subject" name="subject" value={emailSubject} onChange={handleEmailSubject} placeholder="Subject" variant="outlined" fullWidth disabled={true} />
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <Box className="label"> Email Body <span style={{ color: "red" }}>*</span ><span style={{color:'grey'}}>(substitutedVraName,date,actualVraName should not be altered)</span></Box>
                <TextField
                    id="outlined-multiline-static"

                    multiline
                    placeholder="Email Body"
                    rows={8}
                    value={emailBody?.replace(/\\n/g, '\n')}
                    variant="outlined"
                    onChange={handleEmailBodyChange}
                    style={{
                        width: '100%'
                    }}
                />

            </Grid>



            </Grid>


            <Grid container sx={{ pl: 4, pr: 5, mt: 5, mb: 5, pb: 4, display:'flex',justifyContent:'flex-end' }} >



                <Grid item sx={{ mr: 1 }} xs={12} sm={2} md={2} lg={2}>
                    <Button className="saveButton" onClick={handleSubmit}>Submit</Button>
                </Grid>

            </Grid>





        </div>
    )
}


export default EditEmailTemplate
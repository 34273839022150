// npm packages
import React, { useState,useContext } from "react";

// custom pages
import TimesheetSummary from "./TimesheetSummaryReports/TimesheetSummary";

// material ui
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import ProjectSetup from "./ProjectSetup";

import Context from "context/context";
import EmployeeNonComplainceSummary from "./EmployeeNonComplainceSummary";
import EmployeeInfoReport from "./EmployeeRoster/EmployeeInfoReport";

export default function Reports() {
  const { state, dispatch } = useContext(Context);
  const [disableDropdown, setDisableDropdown] = useState(false);
  const [currentReport, setCurrentReport] = useState(1);
  const [roleCode, setRoleCode] = useState(state.role.roleCode);
 
  const reports = [
    {
      id: 1,
      reportName: "Project-Level Summary Report",
    },
    {
      id: 2,
      reportName: "Weekly Task Report",
    },

    {
      id: 4,
      reportName: "Employee Info Report",
    },
    
   
  ];

  if (roleCode === "R001") {
    reports.push({
      id: 3,
      reportName: "Non-Compliance Summary Report",
    });
  }   

  function setReports(tab) {
    switch (tab) {
      case 1:
        return <TimesheetSummary setDisableDropdown={setDisableDropdown} />;
      case 2:
          return <ProjectSetup setDisableDropdown={setDisableDropdown} />;
      case 3:
        if(roleCode=='R001'){
          return <EmployeeNonComplainceSummary setDisableDropdown={setDisableDropdown} />;
        }

        case 4:
          // if(roleCode=='R001'){
            return <EmployeeInfoReport setDisableDropdown={setDisableDropdown} />;
          // }
       return null;
      default:
        return;
    }
  }

  return (
    <Card variant="outlined" sx={{ padding: "30px" }}>
      <Grid container >
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Box className="label">Select a Report</Box>
        <FormControl fullWidth>
          <Select
            id="maritalStatus"
            placeholder="Select ID Proof"
            inputProps={{ "aria-label": "Without label" }}
            disabled={disableDropdown}
            value={currentReport}
            onChange={(e) => {
              setCurrentReport(e.target.value);
            }}
          >
            {reports.map((report, index) => (
              <MenuItem key={index} value={report.id}>
                {report.reportName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      </Grid>
      <div>{setReports(currentReport)}</div>
    </Card>
  );
}

import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
// import "./AddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/Frame (1).png";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { InputAdornment, IconButton } from '@mui/material';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import addemploymentinfo from "apis/panel/employmentInformation/employmentinfo"
import getcreatefindone from "apis/panel/AddEmployee/addEmployee"
import Context from "context/context";
import Loader from "components/Loader";
import Login from 'views/auth/login/Login';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const EditEmployementinfo = () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode, setRoleCode] = useState(state.role.roleCode);
  let params = useParams();
  const employeeId = params.id;

  const {
    getAllRolesAPI,
    getAlldesignationAPI,
    getAlldepartmentAPI,
    getAllemployementtypeAPI,
    getAllworklocationAPI,
    getAllexperiencesAPI,
    getEmployementcreateAPI
  } = addemploymentinfo();

  const [employeeCode, setemployeeCode] = useState()
  const [rolename, setrolename] = useState()
  const [roleid, setroleid] = useState()
  const [roleoption, setroleoption] = useState([])
  const [designationname, setdesignationname] = useState()
  const [designationid, setdesignationid] = useState()
  const [designationoption, setdesignationoption] = useState([])
  const [departmentname, setdepartmentname] = useState()
  const [departmentid, setdepartmentid] = useState()
  const [departmentoption, setdepartmentoption] = useState([])
  const [employementtypename, setemployementtypename] = useState()
  const [employementtypeid, setemployementtypeid] = useState()
  const [employementtypeoption, setemployementtypeoption] = useState([])
  const [worklocationname, setworklocationname] = useState()
  const [worklocationid, setworklocationid] = useState()
  const [worklocationoption, setworklocationoption] = useState([])
  const [experiencesname, setexperiencesname] = useState()
  const [experiencesid, setexperiencesid] = useState()
  const [experiencesoption, setexperiencesoption] = useState([])
  const [status, setstatus] = useState("")
  const [statusvalue, setstatusvalue] = useState('')
  const [uan, setuan] = useState('')
  const [dateOfJoining, setDateOfJoining] = useState('');;
  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loader2, setLoader2] = useState(false)

  useEffect(() => {
    let employeeDatas = state.employeeData;
    if (employeeDatas) {
      setroleid(employeeDatas?.roles?.roleId)
      setrolename(employeeDatas?.roles?.roleName)
      setdesignationid(employeeDatas?.designations?.designationId)
      setdesignationname(employeeDatas?.designations?.designationName)
      setdepartmentid(employeeDatas?.departments?.departmentId)
      setdepartmentname(employeeDatas?.departments?.departmentName)
      setstatus(employeeDatas?.status)
      if (employeeDatas?.status === null) {
        setstatusvalue("Active")
        setstatus(1)
      }
      else {
        setstatusvalue((employeeDatas?.status == 1) ? 'Active' : 'Inactive')
        setstatus(employeeDatas?.status)
      }
      setemployementtypeid(employeeDatas?.employmentTypes?.employmentTypeId)
      setemployementtypename(employeeDatas?.employmentTypes?.employmentTypeName)
      if (employeeDatas === null || employeeDatas?.dateOfJoining === null) {
        setDateOfJoining("")
      }
      else {
        setDateOfJoining(employeeDatas?.dateOfJoining)
      }
      setworklocationid(employeeDatas?.workLocations?.workLocationId)
      setworklocationname(employeeDatas?.workLocations?.workLocationName)
      setexperiencesid(employeeDatas?.experiences?.experienceId)
      setexperiencesname(employeeDatas?.experiences?.yearsOfExperience)
      setuan(employeeDatas?.uan)
      setemployeeCode(employeeDatas?.employeeCode)
    }
  }, [state?.employeeData])

  const handleGetrole = async () => {
    try {
      let res = await getAllRolesAPI();
      setroleoption(res?.data || []);
    }
    catch (err) { }
  };

  const [errorFields, setErrorFields] = useState({
    Rolename: false,
    Departmentname: false,
    designation:false,
    employementstatus: false,
    employeetype: false,
    dateofjoining:false,
    worklocation:false,
    experience:false,
    uan:false,
    employeeid:false
  
  });

  const handleGetdesignation = async () => {
    try {
      let res = await getAlldesignationAPI();
      setdesignationoption(res?.data || []);
    }
    catch (err) { }
  };

  const handleGetdepartment = async () => {
    try {
      let res = await getAlldepartmentAPI();
      setdepartmentoption(res?.data || []);
    }
    catch (err) { }
  };

  const handleGetemployementtype = async () => {
    try {
      let res = await getAllemployementtypeAPI();
      setemployementtypeoption(res?.data || []);
    }
    catch (err) { }
  };

  const handleGetworklocation = async () => {
    try {
      let res = await getAllworklocationAPI();
     
      setworklocationoption(res?.data || []);
    }
    catch (err) { }
  };

  const handleGetexperiences = async () => {
    try {
      let res = await getAllexperiencesAPI();
      setexperiencesoption(res?.data || []);
    }
    catch (err) { }
  };

  const handlestatusChange = (e) => {
    setstatus(e.target.value);
   
  };

  const handleuan = (e) => {
    setuan(e.target.value);
  };

  useEffect(() => {
    handleGetrole()
    handleGetdesignation()
    handleGetdepartment()
    handleGetemployementtype()
    handleGetworklocation()
    handleGetexperiences()
  }, [])

  const handleroleChange = (e) => {
    const selectedrole = roleoption.find(option => option.roleName === e.target.value);
    setrolename(selectedrole.roleName); // Set the titleName
    setroleid(selectedrole.roleId); // Set the titleId
  };

  const handledesignationChange = (e) => {
    const selecteddesignation = designationoption.find(option => option.designationName === e.target.value);
    setdesignationname(selecteddesignation.designationName); // Set the titleName
    setdesignationid(selecteddesignation.designationId); // Set the titleId
  };

  const handledepartmentChange = (e) => {
    const selecteddepartment = departmentoption.find(option => option.departmentName === e.target.value);
    setdepartmentname(selecteddepartment.departmentName); // Set the titleName
    setdepartmentid(selecteddepartment.departmentId); // Set the titleId
  };

  const handleemployementtypeChange = (e) => {
    const selectemployementtypement = employementtypeoption.find(option => option.employmentTypeName === e.target.value);
    setemployementtypename(selectemployementtypement.employmentTypeName); // Set the titleName
    setemployementtypeid(selectemployementtypement.employmentTypeId); // Set the titleId
  };

  const handleworklocationChange = (e) => {
   
    const selectworklocationment = worklocationoption.find(option => option.workLocationName === e.target.value);
    setworklocationname(selectworklocationment.workLocationName); // Set the titleName
    setworklocationid(selectworklocationment.workLocationId); // Set the titleId
  };

  const handleexperiencesChange = (e) => {
    const selectexperiences = experiencesoption.find(option => option.yearsOfExperience === e.target.value);
    setexperiencesname(selectexperiences.yearsOfExperience); // Set the titleName
    setexperiencesid(selectexperiences.experienceId); // Set the titleId
  };

  const handleCreateemplyement = async (e) => {
    e.preventDefault();
    // Validate form fields
    const errors = {};
    if (!roleid) errors.Rolename = true;
    if (!designationid) errors.designation = true;
    if (!departmentid) errors.Departmentname = true;
    if (!status) errors.employementstatus = true;
    if (!employementtypeid) errors.employeetype = true;
    if (!dateOfJoining) errors.dateofjoining = true;
    if (!worklocationid) errors.worklocation = true;
    if (!experiencesid) errors.experience = true;
    if (!uan) errors.uan = true;
    if (!employeeId) errors.employeeid = true;
    
   
    setErrorFields(errors);

    // If no errors, proceed with form submission
    if (Object.keys(errors).length === 0) {
    setLoader2(true)
    const values = {
      employeeId: employeeId,
      roleId: roleid,
      designationId: designationid,
      departmentId: departmentid,
      status: status,
      employmentTypeId: employementtypeid,
      dateOfJoining: moment(dateOfJoining).format('YYYY-MM-DD'),
      workLocationId: worklocationid,
      experienceId: experiencesid,
      uan: uan
    };

    const data = await getEmployementcreateAPI(values);
    if (data.status === 'Ok') {
      setLoader2(false)
      return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
    }
    else {
      setLoader2(false)
      return toast.error("Error saving Data", { toast: "Error saving Data" });
    }
  }
  };

  const handleReset = () => {
    setroleid('')
    setdesignationid('')
    setdepartmentid('')
    setstatus('')
    setemployementtypeid('')
    setDateOfJoining('')
    setworklocationid('')
    setexperiencesid('')
    setuan('')
  }

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      // Accordion is not expanded, reset edit mode
      setIsEditMode(false);
    }
  };

  const maxDate = new Date();

  return (
    <div>
      <Loader loading={loader2} />
      <Accordion sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
        >
          <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" /></Box>
          <Box className="acc-title" sx={{ pl: 3 }}>Employement Information</Box>
          <Box sx={{ ml: 40 }}>
            {expanded && (roleCode == "R001") && (
              <button className="editButton" onClick={toggleEditMode}>
                Edit
                <Box sx={{ flexGrow: 1 }} />
                {isEditMode}
              </button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" >Employee ID <span style={{ color: "red" }}>*</span></Box>
                <TextField id="firstName" placeholder="Employee Id" variant="outlined" fullWidth value={employeeCode} />
                {errorFields. employeeid&& <span className="error-msg">Employee Id is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Employee ID</Box>
                <Box className="editvalue">{employeeCode}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" > Role <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="title-label"
                    id="title"
                    style={{ border: "1px solid #B2D4F8", color: "grey" }}
                    defaultValue=""
                    name="title"
                    value={rolename}
                    onChange={handleroleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value=""> Select Role</MenuItem>
                    {roleoption.map((option) => (
                      <MenuItem key={option.id} value={option.roleName}>{option.roleName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields. rolename&& <span className="error-msg">Role is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">Role</Box>
                <Box className="editvalue">{rolename}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" > Designation <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="title-label"
                    id="title"
                    style={{ border: "1px solid #B2D4F8", color: "grey" }}
                    defaultValue=""
                    name="title"
                    value={designationname}
                    onChange={handledesignationChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value=""> Select Designation </MenuItem>
                    {designationoption.map((option) => (
                      <MenuItem key={option.id} value={option.designationName}>{option.designationName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields. designation&& <span className="error-msg">Designation is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Designation</Box>
                <Box className="editvalue">{designationname}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" > Department <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="title-label"
                    id="title"
                    style={{ border: "1px solid #B2D4F8", color: "grey" }}
                    defaultValue=""
                    name="title"
                    value={departmentname}
                    onChange={handledepartmentChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value=""> Select Department </MenuItem>
                    {departmentoption.map((option) => (
                      <MenuItem key={option.id} value={option.departmentName}>{option.departmentName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields. Departmentname&& <span className="error-msg">Department is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">Department</Box>
                <Box className="editvalue">{departmentname}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" > Employement Status <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    placeholder="Select Employement Status"
                    name="title"
                    defaultValue=""
                    value={status}
                    onChange={handlestatusChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  >
                    <MenuItem value=""> Select Status</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
                {errorFields. employementstatus&& <span className="error-msg">Employment Status is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Employement Status</Box>
                <Box className="editvalue">{status}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Employement Type<span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    placeholder="Select Employement Type"
                    defaultValue=""
                    displayEmpty
                    value={employementtypename}
                    onChange={handleemployementtypeChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  >
                    <MenuItem value=""> Select Type </MenuItem>
                    {employementtypeoption.map((option) => (
                      <MenuItem key={option.id} value={option.employmentTypeName}>{option.employmentTypeName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields. employeetype&& <span className="error-msg">Employement Type is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Employement Type</Box>
                <Box className="editvalue">{employementtypename}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 1 }}>
                <Box className="label">Date of Joining <span style={{ color: "red" }}>*</span></Box>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{width:"100%"}}
                    id="dateOfBirth"
                    name="dateOfBirth"
                    value={dateOfJoining ? new Date(dateOfJoining) : ""}
                    onChange={(date) => {
                      setDateOfJoining(date);
                    }}
                    maxDate={maxDate}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    format="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
                {errorFields. dateofjoining&& <span className="error-msg">Date of Joining is required</span>}

              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">Date of Joining</Box>
                <Box className="editvalue">{dateOfJoining ? moment(dateOfJoining).format('DD/MM/YYYY') : ""}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Work Location<span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    placeholder="Select Work Location"
                    defaultValue=""
                    value={worklocationname}
                    onChange={handleworklocationChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  >
                    <MenuItem value=""> Select Location </MenuItem>
                    {worklocationoption.map((option) => (
                      <MenuItem key={option.id} value={option.workLocationName}>{option.workLocationName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields. worklocation&& <span className="error-msg">Work Location is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Work Location</Box>
                <Box className="editvalue">{worklocationname}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Years Of Exerience<span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    defaultValue=""
                    displayEmpty
                    value={experiencesname}
                    onChange={handleexperiencesChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  >
                    <MenuItem value=""> Select </MenuItem>
                    {experiencesoption.map((option) => (
                      <MenuItem key={option.id} value={option.yearsOfExperience}>{option.yearsOfExperience}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields. experience&& <span className="error-msg">Years Of Exerience is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Years Of Exerience</Box>
                <Box className="editvalue">{experiencesname}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">UAN<span style={{ color: "red" }}>*</span></Box>
                <TextField id="firstName" placeholder="Enter UAN" variant="outlined" fullWidth onChange={handleuan} value={uan} />
                {errorFields. uan&& <span className="error-msg">uan is required</span>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >UAN</Box>
                <Box className="editvalue">{uan}</Box>
              </Grid>
            )}
          </Grid>

          {isEditMode && (
            <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="cancelButton" onClick={toggleEditMode}>Cancel</Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="saveButton" onClick={handleCreateemplyement}>
                  Save
                </Button>
              </Grid>
            </Grid>
          )}

        </AccordionDetails>
      </Accordion>
    </div>

  )
}

export default EditEmployementinfo
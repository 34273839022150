import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import leftarrow from "assets/images/arrow-right.png";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState, useEffect ,useContext} from "react";
import Context from "context/context";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { toast } from "react-toastify";
import FormControl from '@mui/material/FormControl';
import RadRosterApis from 'apis/panel/rpRoster/rpRadRoster'




const AddRadRoster = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(Context);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [deployedDate, setDeployedDate] = useState(null);

    const [plannedDeploymentDate, setPlannedDeploymentDate] = useState(null);
    const [radiologistFormattedName, setRadiologistFormattedName] = useState('');
    const [radiologistUsername, setRadiologistUsername] = useState('')
    const [timezone, setTimezone] = useState('');
    const [practice, setPractice] = useState('');
    const [status, setStatus] = useState('');
    const [email, setEmail] = useState('');
    const [schedulePattern, setSchedulePattern] = useState('');
    const [vraNeededCount, setVraNeededCount] = useState(null);
    const [discontinueddate, setDiscontinuedDate]= useState(null);
    const [reasonforDiscontinuition, setReasonforDiscontinuition]= useState('')

    const handleReasonChange = (event) => {
        const input = event.target.value;
        setReasonforDiscontinuition(input);
    };

    const handleDiscontinuedDateChange=(date)=>{
        setDiscontinuedDate(date)
    }


    const maxDate = new Date()



    const [errorFields, setErrorFields] = useState({

        firstName: false,
        lastName: false,
        deployedDate: false,
        plannedDeploymentDate: false,
        radiologistFormattedName: false,
        radiologistUsername: false,
        timezone:false,
        practice:false,
        status: false,
        vraNeededCount: false,
        email: false,
        schedulePattern: false,

    });


    // api's

    const {createRadRosterAPI } = RadRosterApis()

   

    const handleSaveRadRoster = async (e) => {
        e.preventDefault();

        const errors = {};

        if (!firstName) errors.firstName = true;
        if (!lastName) errors.lastName = true;
        if (!radiologistUsername) errors.radiologistUsername = true;
        if (!radiologistFormattedName) errors.radiologistFormattedName = true;
        if(!practice)errors.practice=true;
        if(!timezone) errors.timezone=true;
        
        if (!status) errors.status = true;
        if (!vraNeededCount) errors.vraNeededCount = true;
        if (!email) errors.email = true;
        if (!schedulePattern) errors.schedulePattern = true;

        setErrorFields(errors);

        if (Object.keys(errors).length === 0) {


            const values = {
              
                firstName: firstName,
                lastName: lastName,
                deployedDate:deployedDate ? deployedDate : null,
                vraNeededCount: parseFloat(vraNeededCount),
                plannedDeploymentDate: plannedDeploymentDate,
                radiologistUsername: radiologistFormattedName,
                radiologistFormattedName: radiologistUsername,
                status: status,
                timezone:timezone,
                practice:practice,
                email:email,
                schedulePattern:schedulePattern,
                discontinuedDate: discontinueddate,
                discontinuedReason: reasonforDiscontinuition

            };


            dispatch({ type: "SET_LOADING", payload: true });

            const res = await createRadRosterAPI(values);
           
            
            if (res.status === 'Ok') {
               handleCancel()
               dispatch({ type: "SET_LOADING", payload: false });
                return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
            }
            else {
                dispatch({ type: "SET_LOADING", payload: false });
                return toast.error("Error: User already exists in the system");
            }

        }


    };

    console.log(typeof(deployedDate),"dep");
    
    const handleCancel =()=>{
        handleReset()
        navigate("/panel/radRoster")
    }

    const handleNavigation=()=>{
        navigate("/panel/radRoster")
      }
    
    const handleReset = () => {

        setFirstName('')
        setLastName('')
        setDeployedDate('')
        setPlannedDeploymentDate('')
        setRadiologistFormattedName('')
        setRadiologistUsername('')
        setStatus('')
        setVraNeededCount('')
        setPractice('')
        setTimezone('')


    }

    const handleTimezoneChange = (event) => {
        const input = event.target.value;
        setTimezone(input);

        
    };

    const handlePracticeChange = (event) => {
        const input = event.target.value;
        setPractice(input);

        
    };



    const handleFirstNameChange = (event) => {
        const input = event.target.value;
        setFirstName(input);

        
        
    };

    const handleLastNameChange = (event) => {

        const input = event.target.value;

        setLastName(input);

        
      

    };

    const handleRadiologistUsernameChange = (event) => {
        const input = event.target.value;

        setRadiologistUsername(input);

        
        

    };

    const handleRadiologistFormattedNameChange = (event) => {

        const input = event.target.value;

        setRadiologistFormattedName(input);

        
       

    };

    const handleVraNeededCountChange = (event) => {
        const input = event.target.value;

    
        if (/^\d*$/.test(input) ) { 
            setVraNeededCount(input); 
            setErrorFields({ ...errorFields, vraNeededCount: false }); 
        } else {
            setErrorFields({ ...errorFields, vraNeededCount: true }); 
        }
        
        
    };

    const handleDeployedDateChange = (date) => {
        setDeployedDate(date);
    };

    const handlePlannedDeploymentDateChange = (date) => {
        setPlannedDeploymentDate(date);
    };

    const handleStatusChange = (event) => {

        setStatus(event.target.value)
    }
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSchedulePatternChange = (event) => {
        setSchedulePattern(event.target.value);
    };

    return (
        <div style={{ backgroundColor: "white" ,borderRadius:"5px", marginTop:"1%"}}>
            <Grid sx={{pl:1,pt:2}} >
        <div style={{ display: 'flex', alignItems: 'center',gap: "1%" }}>
          <div onClick={handleNavigation} style={{ cursor: 'pointer',alignItems:"center" }}>
            <img src={leftarrow} alt="Back" />
          </div>
          <div
  style={{
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: '600',

    letterSpacing: '0.02em',
    textAlign: 'left',
    color: '#0070E9',
    alignItems:"center"
  }}
>
  Add RAD
</div>

        </div>
        </Grid>

            <Grid container spacing={2} sx={{ pl: 4, pr: 5 }} >
               
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">First Name <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="firstName" value={firstName} onChange={handleFirstNameChange} placeholder="Enter First Name" variant="outlined" fullWidth />
                    {errorFields.firstName && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div> <div>This is required Field</div></span>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Last Name <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} value={lastName} name="middleName" onChange={handleLastNameChange} id="lastName" placeholder="Enter Last Name" variant="outlined" fullWidth />
                    {errorFields.lastName && <span className="error-msg">This is required Field</span>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label"> RAD Full Name (Qgenda Name) <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="radiologistFormattedName" value={radiologistFormattedName} name="radiologistFormattedName" onChange={handleRadiologistFormattedNameChange} placeholder="Enter RAD Full Name" variant="outlined" fullWidth />
                    {errorFields.radiologistFormattedName && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div> <div>This is required Field</div></span>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label"> RAD SRF Name <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="radiologistUsername" value={radiologistUsername} name="radiologistUsername" onChange={handleRadiologistUsernameChange} placeholder="Enter RAD SRF Name" variant="outlined" fullWidth />
                    {errorFields.radiologistUsername && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div> <div>This is required Field</div></span>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Email<span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} value={email} name="email" onChange={handleEmailChange} id="email" placeholder="Enter Email" variant="outlined" fullWidth />
                    {errorFields.email && <span className="error-msg">This is required Field</span>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Practice <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="practice" value={practice} onChange={handlePracticeChange} placeholder="Enter Practice" variant="outlined" fullWidth />
                    {errorFields.practice && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div> <div>This is required Field</div></span>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Timezone <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="timezone" value={timezone} onChange={handleTimezoneChange} placeholder="Enter Timezone " variant="outlined" fullWidth />
                    {errorFields.timezone && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div> <div>This is required Field</div></span>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Deployed Date</Box>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="deployedDate"
                            name="deployedDate"
                            value={deployedDate ? new Date(deployedDate) : null}
                            sx={{ width: "100%", border: "2px solid #B2D4F8" }}
                            onAccept={handleDeployedDateChange}
                            fullWidth
                            inputFormat="dd/mm/yyyy"
                            slotProps={{
                                field: { clearable: true },
                            }}
                            maxDate={maxDate}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                    {/* <TextField sx={{ border: '1px solid #B2D4F8' }}id="deployedDate" value={deployedDate}  name="deployedDate"  onChange={handleDeployedDateChange} placeholder="deployedDate" variant="outlined" fullWidth /> */}
                    {/* {errorFields.deployedDate && <span className="error-msg">This is required Field</span>} */}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Schedule Pattern<span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} value={schedulePattern} name="schedulePattern" onChange={handleSchedulePatternChange} id="schedulePattern" placeholder="Enter Schedule Pattern" variant="outlined" fullWidth />
                    {errorFields.schedulePattern && <span className="error-msg">This is required Field</span>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">VRA Needed<span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="vraNeededCount" value={vraNeededCount} name="vraNeededCount" onChange={handleVraNeededCountChange} placeholder="0" variant="outlined" fullWidth type="text" inputProps={{ step: "1", min: "0" }}/>
                    {errorFields.vraNeededCount && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div>  <div> A non-negative integer value is required</div></span>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Status <span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>

                        <Select
                            labelId="status"
                            id="status"

                            defaultValue=""
                            displayEmpty


                            name="status"
                            value={status}
                            onChange={handleStatusChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{ border: "2px solid #B2D4F8", color: "grey" }}
                        >
                            <MenuItem value="">
                                Select Status
                            </MenuItem>

                            <MenuItem value={'Active'}>Active</MenuItem>
                            {/* <MenuItem value={'Temporarily'}>Temporarily</MenuItem> */}
                            <MenuItem value={'Paused'}>Paused</MenuItem>

                            <MenuItem value={'Discontinued'}>Dropped</MenuItem>
                            <MenuItem value={'Planned'}>Planned</MenuItem>


                        </Select>
                    </FormControl>
                    {/* <TextField sx={{ border: '1px solid #B2D4F8' }}id="role" value={Role}  name="role"  onChange={handleRoleChange} placeholder="Role" variant="outlined" fullWidth /> */}
                    {errorFields.status && <span className="error-msg">This is required Field</span>}
                </Grid>


  <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Discontinued Date</Box>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="deployedDate"
                            name="deployedDate"
                            value={discontinueddate ? new Date(discontinueddate) : null}
                            sx={{ width: "100%", border: "2px solid #B2D4F8" }}
                            onAccept={handleDiscontinuedDateChange}
                            fullWidth
                            inputFormat="dd/MM/yyyy"
                            slotProps={{
                                field: { clearable: true },
                            }}
                            // maxDate={maxDate}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                  
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Reason For Discontinuition</Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="firstName" value={reasonforDiscontinuition} onChange={handleReasonChange} placeholder="Enter Reason For Discontinuition" variant="outlined" fullWidth />
                    {/* {errorFields.reasonforDiscontinuition && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div> <div>This is required Field</div></span>} */}
                </Grid>
            

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Planned Deployment Date </Box>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="plannedDeploymentDate"
                            name="plannedDeploymentDate"
                            value={plannedDeploymentDate ? new Date(plannedDeploymentDate) : null}
                            sx={{ width: "100%", border: "2px solid #B2D4F8" }}
                            onAccept={handlePlannedDeploymentDateChange}
                            fullWidth
                            inputFormat="dd/MM/yyyy"
                            slotProps={{
                                field: { clearable: true },
                            }}
                            
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                    {/* <TextField sx={{ border: '1px solid #B2D4F8' }} id="plannedDeploymentDate" value={plannedDeploymentDate}  name="plannedDeploymentDate"  onChange={handlePlannedDeploymentDateChange} placeholder="plannedDeploymentDate" variant="outlined" fullWidth /> */}
                    {errorFields.plannedDeploymentDate && <span className="error-msg">This is required Field</span>}
                </Grid>

          
       
     

            </Grid>


            <Grid container sx={{ pl: 4, pr: 5, mt: 5, mb:5, pb:4, justifyContent: "center" }} >
                <Grid item sx={{ ml: 1 }} xs={12} sm={3} md={3} lg={3}>
                    <Button className="resetChanges" onClick={handleReset}>Reset Changes</Button>
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                    <Button className="cancelButton" onClick={handleCancel}   >Cancel</Button>
                </Grid>

                <Grid item sx={{ mr: 1 }} xs={12} sm={2} md={2} lg={2}>
                    <Button className="saveButton" onClick={handleSaveRadRoster} >Save</Button>
                </Grid>

            </Grid>





        </div>
    )
}

export default AddRadRoster;

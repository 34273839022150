import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from 'moment'
import Button from '@mui/material/Button';
import { parseISO, format } from 'date-fns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState, useEffect,useContext } from "react";
import Context from "context/context";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { toast } from "react-toastify";
import FormControl from '@mui/material/FormControl';
import vraRadAffinityAPI from 'apis/panel/vraRadAffinity/vraRadAffinity';



const EditVraRadAffinity = ({ id, onClose }) => {
    const { state, dispatch } = useContext(Context);
    const [affinityStartDate,setAffinityStartDate]= useState('')
    const [noOfShiftsTogether,setNoOfShiftsTogether]= useState(0)
    const [affinityId,setAffinityId]= useState('')
    const [vraSrfName,setVraSrfName]= useState('')
    const [radQgendaName,setRadQgendaname]= useState('')
    const [editVraRadAffinityObj,setEditVraRadAffinityObj] = useState([])
    const [open, setOpen] = useState(false);
   
     // api's

     const {getVraRadAffinityFindOneAPI,updateVraRadAffinityAPI } = vraRadAffinityAPI()


    const handleGetEditDataObj = async () => {

        try {
            dispatch({ type: "SET_LOADING", payload: true });
            let values
            values = {
                affinityId: id
            }
            const res = await getVraRadAffinityFindOneAPI(values)
            if (res?.data) {
                setEditVraRadAffinityObj(res?.data?.vraRadAffinityDetails)
                setAffinityId(id)
                setAffinityStartDate(res?.data?.vraRadAffinityDetails?.affinitystartdate)
                setNoOfShiftsTogether(res?.data?.vraRadAffinityDetails?.numberofshiftstogether)
                setVraSrfName(res?.data?.vraRadAffinityDetails?.srfname)
                setRadQgendaname(res?.data?.vraRadAffinityDetails?.radqgendafullname)

            }
            dispatch({ type: "SET_LOADING", payload: false });
        } catch (error) {
            console.log({ error });
            dispatch({ type: "SET_LOADING", payload: false });

        }
        
    }

    const maxDate = new Date()

    const [errorFields, setErrorFields] = useState({

        vrasrfname: false,
        vrarpuserid: false,
        deployedDate: false,
        plannedDeploymentDate: false,
        role: false,
        engagementType: false,
        radrosterId: false,
        status: false,
        vraNeededCount: false,

    });


    const handleSaveVraRadAffinityEdit = async (e) => {
        e.preventDefault();
    
        const values = {
            affinityId: affinityId,
            affinityStartDate: affinityStartDate,
            numberOfShiftsTogether: parseFloat(noOfShiftsTogether),
           
        };
    
       
        dispatch({ type: "SET_LOADING", payload: true });
    
        const res = await updateVraRadAffinityAPI(values);
    
        if (res.status === 'Ok') {
            onClose();
            dispatch({ type: "SET_LOADING", payload: false });
            return toast.success("Data Saved Successfully", { toast: "Data Edited Successfully" });
        } else {
            dispatch({ type: "SET_LOADING", payload: false });
            return toast.error("Error: User already exists in the system");
        }
    };
    

    useEffect(() => {
        handleGetEditDataObj()
    }, [])

   
    const handleAffinityStartDateChange = (date) => {
        setAffinityStartDate(date);
    };



    const handleShiftsTogetherChange = (event) => {
        setNoOfShiftsTogether(event.target.value);
    };


  
    return (
        <div style={{ backgroundColor: "white" }} >

            <Grid container spacing={2} sx={{ pl: 4, pr: 5 }} >
            
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Affinity Start Date</Box>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="plannedDeploymentDate"
                            name="plannedDeploymentDate"
                            value={affinityStartDate ? new Date(affinityStartDate) : null}
                            sx={{ width: "100%", border: "2px solid #B2D4F8" }}
                            onAccept={handleAffinityStartDateChange}
                            fullWidth
                            inputFormat="dd/MM/yyyy"
                            slotProps={{
                                field: { clearable: true },
                            }}
                           
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                    {/* <TextField sx={{ border: '1px solid #B2D4F8' }} id="plannedDeploymentDate" value={plannedDeploymentDate}  name="plannedDeploymentDate"  onChange={handlePlannedDeploymentDateChange} placeholder="plannedDeploymentDate" variant="outlined" fullWidth /> */}
                    {/* {errorFields.plannedDeploymentDate && <span className="error-msg">This is required Field</span>} */}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Number of Shifts Together<span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="noOfShiftsTogether" value={noOfShiftsTogether} onChange={handleShiftsTogetherChange} placeholder="Enter Number of Shifts" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">VRA SRF Name<span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} value={vraSrfName} name="vraSrfName"  id="vraSrfName" disabled  variant="outlined" fullWidth />
                    {/* {errorFields.noOfShifts && <span className="error-msg">This is required Field</span>} */}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label"> RAD Full Name (Qgenda Name)<span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} value={radQgendaName} name="radQgendaName"  id="radQgendaName" disabled   variant="outlined" fullWidth />
                    {/* {errorFields.noOfShifts && <span className="error-msg">This is required Field</span>} */}
                </Grid>
                
            </Grid>

            <Grid container sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >

                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Button className="cancelButton" onClick={onClose}>Cancel</Button>
                </Grid>

                <Grid item sx={{ mr: 1 }} xs={12} sm={2} md={2} lg={2}>
                    <Button className="saveButton" onClick={handleSaveVraRadAffinityEdit} >Save</Button>
                </Grid>

            </Grid>

        </div>
    )
}


export default EditVraRadAffinity;

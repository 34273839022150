
import React, { useEffect, useContext, useState } from 'react';
import { toast } from "react-toastify";
import {useNavigate} from "react-router-dom"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import getAllLeavetypesAPI from "apis/panel/leaveManagemnet/leaveManagement";
import leaveManagementAPI from 'apis/panel/leaveManagemnet/leaveManagement';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Context from "context/context";
import 'react-calendar/dist/Calendar.css';
import "./EarnCompOff.jsx";

// custom pages 
import FileHandler from "apis/panel/fileHandler/fileHandler";
import ReusableFunction from "utils/reusablefunction";
import getemployees from "apis/panel/timesheet/TimeSheet";


const generateData = (year) => {
  return [
    { month: 'Jan', value: `${year}-01-01`, year: `${year} ` },
    { month: 'Feb', value: `${year}-02-01`, year: `${year} ` },
    { month: 'Mar', value: `${year}-03-01`, year: `${year} ` },
    { month: 'Apr', value: `${year}-04-01`, year: `${year} ` },
    { month: 'May', value: `${year}-05-01`, year: `${year} ` },
    { month: 'June', value: `${year}-06-01`, year: `${year} ` },
    { month: 'July', value: `${year}-07-01`, year: `${year} ` },
    { month: 'Aug', value: `${year}-08-01`, year: `${year} ` },
    { month: 'Sept', value: `${year}-09-01`, year: `${year} ` },
    { month: 'Oct', value: `${year}-10-01`, year: `${year} ` },
    { month: 'Nov', value: `${year}-11-01`, year: `${year} ` },
    { month: 'Dec', value: `${year}-12-01`, year: `${year} ` },
  ];
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  width: "30%",
  borderRadius: "20px",
};

const EarnCompOff = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [leavesTypelist, setLeavesTypeList] = useState([]);
  const [currentMonthFrom, setCurrentMonthFrom] = useState(moment().startOf('month'));
  const [currentMonthTo, setCurrentMonthTo] = useState(moment().startOf('month'));
  const [totalDays, setTotalDays] = useState('');
  const [holidayList, setHolidayList] = useState([]);
  const [description, setDescription] = useState('');
  // const [passbookFilePath, setPassbookFilePath] = useState("");
  // const [passbook, setpassbook] = useState(null);
  const [employeeId, setEmployeeId] = useState('');
  // const [leaveTypeError, setLeaveTypeError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDayTypes, setSelectedDayTypes] = useState({});
  const [limitMonth, setLimitMonth] = useState(moment().clone().subtract(1, 'month'));
  const [filteredSelectedDates, setFilteredSelectedDates] = useState([]);
  const [availableDaysForType, setAvailableDaysForType] = useState(0);
  const { state, dispatch } = useContext(Context);
  const [roleCode, setRoleCode] = useState(state?.employee?.departmentName || "");
  const Navigate = useNavigate();
  const [availableDaysList, setAvailableDaysList] = useState([]);
  const [pendingLeavesDaysList,setPendingLeavesDaysList] =useState([])
  const [allLeaveDates,setAllLeaveDates] = useState([])
  const { getAllLeaveTypesAPI, earnCompOffLeaveRequestAPI } = getAllLeavetypesAPI();
 
  const { getEmployeeDetailsAPI } = getemployees();
 


  const calculateModalTotalDays = () => {
    let totalDays = 0;
    filteredSelectedDates.forEach((date) => {
      const formattedDate = date.format('YYYY-MM-DD');
      const dayTypeInfo = halfDayLeaveDates.find(d => d.date === formattedDate);
      const dayTypeOptions = dayTypeInfo ? (() => {
        switch (dayTypeInfo.day_type) {
          case 'First Half':
            return ['0.5_second_half'];
          case 'Second Half':
            return ['0.5_first_half'];
            case 'Full':
            return [0];
          default:
            return null;
        }
      })() : ['1', '0.5_first_half', '0.5_second_half'];

      
      const selectedType = selectedDayTypes[date.format('YYYY-MM-DD')] ||  (dayTypeInfo ? dayTypeOptions : "1");
    
     
     
     
      if (selectedType == '1') {
        totalDays += 1;
       
        
      } else if(selectedType == '0.5_second_half' || selectedType == '0.5_first_half' ) {
        
        
        totalDays += 0.5;
       
      }
     
      
    });
    return totalDays;

    
  };

  useEffect(() => {
    if (fromDate) {
      const endDate = toDate || fromDate;
      const isSingleDay = !toDate;
      const filteredDates = Array.from(
        { length: endDate.diff(fromDate, 'days') + 1 },
        (_, i) => fromDate.clone().add(i, 'day')
      )

      setFilteredSelectedDates(filteredDates);

    }
  }, [fromDate, toDate]);

  const [halfDayLeaveDates, setHalfDayLeaveDates] = useState([]);

  useEffect(() => {
    const results = filteredSelectedDates.map(selectedDate => {
      const formattedSelectedDate = selectedDate.format('YYYY-MM-DD');
      
      const leaveInfo = allLeaveDates.find(leave => 
        moment(leave.date).format('YYYY-MM-DD') === formattedSelectedDate &&
        (leave.day_type === 'First Half' || leave.day_type === 'Second Half' || leave.day_type === 'Full')
      );

      return leaveInfo ? {
        date: formattedSelectedDate,
        day_type: leaveInfo.day_type
      } : null;
    }).filter(item => item !== null); 

    setHalfDayLeaveDates(results);

  }, [filteredSelectedDates, allLeaveDates]);

  const handleRaiseLeaveRequest = async () => {
    let valid = true;

    
    if (!description) {
      setDescriptionError('This field is mandatory');
      valid = false;
    } else {
      setDescriptionError('');
    }

   
    if (!fromDate) {
      toast.error('Please select valid start and end dates.', {
        toastId: "start and end date error",
      });
      valid = false;
    }

    
    if (!valid) {
      return;
    }
   
      const totalDays=calculateModalTotalDays()

  
   
    const isSingleDay = !toDate;
    const endDate = toDate || fromDate;
    const filteredDates = Array.from(
      { length: endDate.diff(fromDate, 'days') + 1 },
      (_, i) => fromDate.clone().add(i, 'day')
    )

    setFilteredSelectedDates(filteredDates);

  
    let daysArray = [];
    filteredDates.forEach((date) => {
      const formattedDate = date.format('YYYY-MM-DD');
      const dayTypeInfo = halfDayLeaveDates.find(d => d.date === formattedDate);
      const dayTypeOptions = dayTypeInfo ? (() => {
        switch (dayTypeInfo.day_type) {
          case 'First Half':
            return ['0.5_second_half'];
          case 'Second Half':
            return ['0.5_first_half'];
          default:
            return null;
        }
      })() : ['1', '0.5_first_half', '0.5_second_half'];
      const selectedType = selectedDayTypes[date.format('YYYY-MM-DD')] ||  (dayTypeInfo ? dayTypeOptions : "1");
      let dayType = "Full";
      if (selectedType === '1') {
        dayType = "Full";
      } else if (selectedType === '0.5_first_half' ) {
        dayType = "First Half";
      }else if(selectedType === '0.5_second_half'){
        dayType = "Second Half";
      }
      daysArray.push({
        date: date.format('YYYY-MM-DD'),
        dayType: dayType,
        dayLogged: selectedType === '1' ? 1 : 0.5,
      });
    });

    dispatch({ type: "SET_LOADING", payload: true });

    const value = {
      leaveTypeId: selectedLeaveType,
      startDate: fromDate.format('YYYY-MM-DD'),
      endDate: toDate ? toDate.format('YYYY-MM-DD') : fromDate.format('YYYY-MM-DD'),
      numberOfDays: calculateModalTotalDays(),
      reason: description,
     
      days: daysArray
    };

    const res = await earnCompOffLeaveRequestAPI(value);

    if (res.status === 'Ok') {
      dispatch({ type: "SET_LOADING", payload: false });
      setIsModalOpen(false);
      Navigate("/panel/leaveHistory");
      toast.success("Data Saved Successfully");
    } else {
      dispatch({ type: "SET_LOADING", payload: false });
      toast.error(res.data.error);
    }
  };

  const calculateTotalDays = (start, end) => {
    if (!start || !end) return 0;
  
    let totalDays = 0;
    const current = start.clone();
  
    while (current.isSameOrBefore(end, 'day')) {
      const isPublicHoliday = holidayList.some(holiday => moment(holiday.holidayDate).isSame(current, 'day'));
      const isWeekend = current.day() === 0 || current.day() === 6;
      
      // Check if current date has leave applied
      const isFullDay = allLeaveDates.some(leave =>
        leave.day_type === 'Full' && moment(leave.date).isSame(current, 'day')
      );
      const isHalfDay = allLeaveDates.some(leave =>
        leave.day_logged === '0.5' && moment(leave.date).isSame(current, 'day')
      );
  
      // Determine if the day should be counted
      if (roleCode === "KPO/BPO" || (!isPublicHoliday && !isWeekend)) {
        if (isFullDay) {
          // Exclude full-day leaves from totalDays
          // Do nothing, full-day leaves are excluded
        } else if (isHalfDay) {
          // Add half a day for half-day leaves
          totalDays += 0.5;
        } else {
          // Count the day if it's not a full-day or half-day leave
          totalDays++;
        }
      }
  
      current.add(1, 'day');
    }
  
    
  
    return (totalDays); 
  };
  
  const handlegetAllleaveTypes = async () => {
    const res = await getAllLeaveTypesAPI();
    setLeavesTypeList(res?.data || []);
  };

  const getAvailableDaysForSelectedLeaveType = () => {
    
    const leaveType = availableDaysList.find(
      (leave) => leave.leaveTypeId === selectedLeaveType
    );
  
    const pendingRequests = pendingLeavesDaysList.find(
      (leave) => leave.leave_type_id === selectedLeaveType
    );
  
    const balance = leaveType ? leaveType.balanceDaysPerMonth : 0;
  
    const totalAvailableDays = balance - (pendingRequests ? pendingRequests.number_of_days : 0);
  
    return totalAvailableDays;
 };

  const handleGetEmployees = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let res = await getEmployeeDetailsAPI();
      setEmployeeId(res?.data?.employeeInformation?.employeeId);

      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) { }
  };

  useEffect(() => {
    handlegetAllleaveTypes();
    handleGetEmployees()
    
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      const totalDays = calculateTotalDays(fromDate, toDate);
      setTotalDays(totalDays);
    } else if (fromDate && !toDate) {
      setTotalDays(1);
    } else {
      setTotalDays(0);
    }
  }, [fromDate, toDate, selectedLeaveType]);

  useEffect(() => {
    if (selectedLeaveType) {
      const balanceDaysOfLeave = getAvailableDaysForSelectedLeaveType();
      setAvailableDaysForType(balanceDaysOfLeave);
    }
  }, [selectedLeaveType]);

  

  const handleDateClick = (day) => {
    
    // if (roleCode !== "KPO/BPO" && isPublicHolidayOrWeekend(day)) return;

    if (!fromDate || (fromDate && toDate)) {
      setFromDate(day);
      setToDate(null);
    } else if (day.isBefore(fromDate)) {
      setFromDate(day);
      setToDate(null);
    } else {
      const totalSelectedDays = calculateTotalDays(fromDate, day);
      const leaveTypeName = leavesTypelist.find(leave => leave.leaveTypeId === selectedLeaveType)?.leaveTypeName;

      if (leaveTypeName === "Privilege Leave" || leaveTypeName === "Sick Leave") {
        if (totalSelectedDays <= availableDaysForType) {
          setToDate(day);
        }else if(totalSelectedDays - availableDaysForType == 0.5){
          const fullDays = Math.floor(availableDaysForType);
          const remainingDays = availableDaysForType - fullDays;
           setToDate(day);
        } else {
          toast.error(`You can  only select up to ${availableDaysForType} days for this leave type.`, {
            toastId: "date-range-error",
          });
        }
      } else {
        setToDate(day);
      }
    }
  };

  const isPublicHolidayOrWeekend = (day) => {
    if (roleCode === "KPO/BPO") {
      return false;
    }
    return Array.isArray(holidayList) && holidayList.some(holiday => moment(holiday.holidayDate).isSame(day, 'day')) 
  };

  useEffect(() => {

    setLimitMonth(currentMonthFrom.clone().subtract(1, 'month'));
  }, []);


  const handleMonthChangeFrom = (direction) => {
    const today = moment(); 
    
    const newMonth = currentMonthFrom.clone().add(direction, 'month');
  
    if (direction === -1) {
     
      const maxPastDate = today.clone().subtract(1, 'month').startOf('month');
      if (newMonth.isBefore(maxPastDate, 'month')) {
        toast.error('Cannot go back more than 31 days from the current Date.', { toastId: 'previousmonth' });
        return;
      }
    } else if (direction === 1) {
      
      if (newMonth.isAfter(today, 'month')) {
        toast.error('Cannot go beyond the current Date.', { toastId: 'nextMonth' });
        return;
      }
      
      
    }
  
    setCurrentMonthFrom(newMonth);
  };
  

  

  const today = moment(); 
  const limitDate = today.subtract(31, 'days');

    const renderDay = (day, handleClick) => {
      if (!day) return <div className="calendar-dayInapply12" />;
    
      const today = moment();
     
      const isBeforeLimit = day.isBefore(limitDate, 'day');
      const isAfterToday = day.isAfter(today, 'day'); 
      const isKPOBPO = roleCode === "KPO/BPO";
      const isPublicHoliday = holidayList.some(holiday => moment(holiday.holidayDate).isSame(day, 'day'));
      const isLeaveApplied = allLeaveDates.some(leave => moment(leave.date).isSame(day, 'day'));
        const isFullDay = allLeaveDates.some(leave => 
      leave.day_type === 'Full' && moment(leave.date).isSame(day, 'day')
  );
      const isWeekend = day.day() === 0 || day.day() === 6;
      const isSelected = day.isSame(fromDate, 'day') || day.isSame(toDate, 'day');
      const isInRange = fromDate && toDate && day.isBetween(fromDate, toDate, 'day', '[]');
    
      let dayClass = 'weekday';
    let isDisabled1,isDisabled2;
    
      if (isBeforeLimit || isAfterToday) {
        dayClass = 'disabled';
      } else {
        if (isSelected) dayClass = 'selected';
        if (isInRange && (isKPOBPO || (!isPublicHoliday && !isWeekend))) dayClass = 'in-range'; 
        if (isWeekend || isPublicHoliday) dayClass = isKPOBPO ? 'in-range' : 'weekend'; 
        if (isPublicHoliday) dayClass = 'public-holiday';
           if(isLeaveApplied ) dayClass = 'applied' ;
     isDisabled1 = isLeaveApplied  && isFullDay;
     isDisabled2 = isLeaveApplied ;

      }
    
      const backgroundColor = isSelected ? '#0070E9' :
                              (isInRange && dayClass === 'in-range') ? '#B3DFF7' :
                              (dayClass === 'public-holiday') ? '#008A2733' :
                              isBeforeLimit || isAfterToday ? 'lightgrey' :  isDisabled2 ? '#9cc197' : ''; 
    
      const color = dayClass === 'public-holiday' && !isSelected? '#008A27' :
                    isBeforeLimit || isAfterToday ? 'grey' :
                    isSelected ? 'white' : '';
    
      return (
        <Tooltip key={day.format('YYYY-MM-DD')} title={ isDisabled1 ? 'Full Day Comp Off Applied' : isDisabled2 ? 'Half Day Comp Off Applied':isPublicHoliday ? 'Public Holiday' : ''} arrow>
        <div
         className={`calendar-dayInapply1 ${dayClass} ${isDisabled2 ? 'disabled' : ''}`}
          style={{ backgroundColor, color }}
          onClick={() => !dayClass.includes('disabled')&& !isDisabled2  && handleClick(day) }
          
        >
          {day.date()}
        </div>
        </Tooltip>
      );
    };
    


  // const renderDay = (day, handleClick) => {
  //   if (!day) return <div className="calendar-dayInapply12" />;
  //   const isKPOBPO = roleCode === "KPO/BPO";
  //   const isPublicHoliday = holidayList.some(holiday => moment(holiday.holidayDate).isSame(day, 'day'));
  //   const isLeaveApplied = allLeaveDates.some(leave => moment(leave.date).isSame(day, 'day'));
  //   const isFullDay = allLeaveDates.some(leave => 
  //     leave.day_type === 'Full' && moment(leave.date).isSame(day, 'day')
  // );
  //   const isWeekend = day.day() === 0 || day.day() === 6;
  //   const isSelected = day.isSame(fromDate, 'day') || day.isSame(toDate, 'day');
  //   const isInRange = fromDate && toDate && day.isBetween(fromDate, toDate, 'day', '[]');

  //   let dayClass = 'weekday';
  //   if (isSelected) dayClass = 'selected';
  //   if (isInRange && (isKPOBPO || (!isPublicHoliday && !isWeekend))) dayClass = 'in-range'; 
  //   if (isWeekend || isPublicHoliday) dayClass = isKPOBPO ? 'in-range' : 'weekend'; 
  //   if (isPublicHoliday) dayClass = 'public-holiday';
  //   if(isLeaveApplied ) dayClass = 'applied' ;
  //   const isDisabled1 = isLeaveApplied  && isFullDay;
  //   const isDisabled2 = isLeaveApplied ;

  //   const backgroundColor = isSelected ? '#0070E9' :
  //                           (isInRange && dayClass === 'in-range') ? '#B3DFF7' :
  //                           (dayClass === 'public-holiday') ? '#008A2733' :
  //                           isDisabled2 ? 'lightgrey' : '';
  //   const color = dayClass === 'public-holiday' ? '#008A27' :
  //                 isDisabled2 ? 'black' : 
  //                 isSelected ? 'white' : '';


  //                 return (
  //                   <Tooltip key={day.format('YYYY-MM-DD')} title={isPublicHoliday ? 'Public Holiday' : isDisabled1 ? 'Full Day Comp Off Applied' : isDisabled2 ? 'Half Day Comp Off Applied': ''} arrow>
  //                       <div
  //                           className={`calendar-dayInapply1 ${dayClass} ${isDisabled2 ? 'disabled' : ''}`}
  //                           onClick={() => !isDisabled2  && handleClick(day)}
  //                           style={{
  //                             backgroundColor,
  //                             color,
                              
  //                           }}
  //                       >
  //                           {day.date()}
  //                       </div>
  //                   </Tooltip>
  //               );
  // };

  const generateCalendar = (month) => {
    const startOfMonth = moment(month).startOf('month');
    const endOfMonth = moment(month).endOf('month');
    const days = [];
    let date = startOfMonth.clone();
    while (date <= endOfMonth) {
      days.push(date.clone());
      date.add(1, 'days');
    }

    const weeks = [];
    let week = [];
    const startDay = startOfMonth.day() === 0 ? 6 : startOfMonth.day() - 1;
    for (let i = 0; i < startDay; i++) {
      week.push(null);
    }
    days.forEach(day => {
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
      week.push(day);
    });

    while (week.length < 7) {
      week.push(null);
    }
    weeks.push(week);
    return weeks;
  };

  const calendarWeeksFrom = generateCalendar(currentMonthFrom);
  const calendarWeeksTo = generateCalendar(currentMonthTo);
  const [value, setValue] = useState(0);
  const selectedMonth = generateData(moment().year())[value].value;
  const {  getAllPublicHolidaysYearAPI, getLeavesNumberandDates } = leaveManagementAPI();
  
  useEffect(() => {
    handleGetAllHolidaysApi();
    handleGetLeavesNumberApi()
  }, []);

  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const handleGetLeavesNumberApi = async (month) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values = { year: formattedDate };
      let res = await getLeavesNumberandDates(values);
    
    setSelectedLeaveType(res?.data?.leaveTypeId || [])
      setAllLeaveDates(res?.data?.leaveDates || [])
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

   const handleGetAllHolidaysApi = async (month) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values = { year: formattedDate }
      let res = await getAllPublicHolidaysYearAPI(values);
      setHolidayList(res?.data || []);
      
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const handleDayTypeChange = (date, event) => {
    const value = event.target.value;
    setSelectedDayTypes((prevState) => ({
      ...prevState,
      [date]: value,
    }));
  };


  const handleCancelLeaveRequest=()=>{
    setSelectedLeaveType('');
    setFromDate(null);
    setToDate(null);
    setDescription('')
  }


  const isDateFullyApplied = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    const leaveInfo = allLeaveDates.find(leave =>
      moment(leave.date).format('YYYY-MM-DD') === formattedDate &&
      leave.day_type === 'Full'
    );
    return leaveInfo !== undefined;
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      
    <Grid container sx={{ display:"flex"}}>
    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ width: "100%" }}>
   
      <Grid container sx={{ pt: 2 }} className="leavereqSelectedTotalMain">
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{pl: 3}} className="labelforselectDates">
          Select Dates
        </Grid>
      </Grid>
      <hr style={{ border:" 1px solid #E9E9E9" ,marginTop:"2%", marginBottom:"1.5%", width:"90%"}}/>
      <Grid container className='LeaveApplyCalenderContainer'>
        <Grid item sm={11} lg={11} md={11} xl={11} xs={12} className="calendarInapply">
          <div className="calendar-headerInapply1">
            <div  className="leftarrowinapply" onClick={() => handleMonthChangeFrom(-1)}><ArrowBackIosIcon/></div>
            <div className='headinginfromInapply1'> <span className='fromInapply1'>From</span> {currentMonthFrom.format('MMMM YYYY')}</div>
            <div className="leftarrowinapply" onClick={() => handleMonthChangeFrom(1)}><ArrowForwardIosIcon/></div>
          </div>
          <hr style={{ border:" 1px solid #E9E9E9" ,marginTop:"3%", marginBottom:"2%", width:"95%"}}/>
          <div className="calendar-headerInapply1">
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
              <div key={day} className="calendar-header-dayInapply1">{day}</div>
            ))}
          </div>
          <div className="calendar-bodyInapply1">
            {calendarWeeksFrom.map((week, index) => (
              <div key={index} className="calendar-weekInapply1">
                {week.map(day => renderDay(day, handleDateClick, true))}
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
     
       
      <Grid container sx={{ display:"flex",pl:2}}>
           
        <Grid item xs={12} sm={11.5} md={11.5} lg={11.5} sx={{ mt: 2, width: "90%" }}>
    <Box className="label">Description <span style={{ color: "red" }}>*</span></Box>
    <TextField
      placeholder="Enter Description Here"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      multiline
      rows={4}
      fullWidth
    />
    {descriptionError && (
      <Box style={{ color: "red" }}> 
        {descriptionError}
      </Box>
    )}
  </Grid>   
  </Grid>
        </Grid>
        <Grid item xs={12} md={5} lg={5} sm={12}
          aria-describedby=""
        >
         
         
         <Box sx={{ mt: 4 }}>
      <div id="">
        {fromDate ? (
          <div style={{ marginTop: "1%" }}>
            <div className='label' style={{ marginBottom: "2%", marginTop: "1%" }}>Your Request Includes</div>
            <div className='modalselectedDates'>
              {filteredSelectedDates.map(date => {
                const formattedDate = date.format('YYYY-MM-DD');
                const dayTypeInfo = halfDayLeaveDates.find(d => d.date === formattedDate);

                
                const dayTypeOptions = dayTypeInfo ? (() => {
                  switch (dayTypeInfo.day_type) {
                    case 'First Half':
                      return ['0.5_second_half'];
                    case 'Second Half':
                      return ['0.5_first_half'];
                    default:
                      return null;
                  }
                })() : ['1', '0.5_first_half', '0.5_second_half'];

                if (isDateFullyApplied(date)) {
                  return null; 
                }

                return (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} key={formattedDate}>
                    <div style={{ fontSize: "15px", fontWeight: "600", fontFamily: "Inter" }}>
                      {date.format('ddd, MMMM DD YYYY')}
                    </div>
                    <Select
                      value={selectedDayTypes[formattedDate] || (dayTypeInfo ? dayTypeOptions : "1")}
                      onChange={(e) => handleDayTypeChange(formattedDate, e)}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        color: "#0070E9",
                        fontSize: "16px",
                        fontWeight: "700"
                      }}
                    >
                      
                      {dayTypeOptions.includes('1') && <MenuItem value="1">Full Day</MenuItem>}
                      {dayTypeOptions.includes('0.5_first_half') && <MenuItem value="0.5_first_half">Morning Session</MenuItem>}
                      {dayTypeOptions.includes('0.5_second_half') && <MenuItem value="0.5_second_half">Evening Session</MenuItem>}
                    </Select>
                  </div>
                );
              })}
            </div>
            <p className='tsbods'><span className='label'>Total Days Requested:</span> {calculateModalTotalDays()}</p>
          </div>
        ) : (
          <div className='modalselectedDates'>
            <img style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "cover", borderRadius: "20px" }} src="https://www.soulmatedestination.com/assets/front_end_new/images/no-data.png" alt="" />
            <div style={{ color: "lightgrey" }}> No Dates Selected</div>
          </div>
        )}
      </div>
    </Box>

        </Grid>
      </Grid>
      <Grid container sx={{ width: "30%", justifyContent: "center", margin: "auto", textAlign: "center", mt: 4, }}>
        <Grid item xs={12} sm={6} lg={6} md={6} sx={{ mb: 4 }}>
          <Button className="leaveApplyModalCancel" onClick={handleCancelLeaveRequest}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} md={6} sx={{ mb: 4 }}>
          <Button className="leaveApplyModalConfirm" onClick={handleRaiseLeaveRequest}>
            Request Now
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EarnCompOff;